import { useState } from 'react';
import { HandlerProps, ReflexSplitter } from 'react-reflex';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier, closestCorners } from '@dnd-kit/core';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Button } from '../../common/buttons/Button';
import { CenteredLoadingSpinner } from '../../loading/CenteredLoadingSpinner';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { EnvironmentVariable } from './EnvironmentVariable';
import { GhostVariable } from './EnvironmentVariableComponents';
import { OpenInNewLink } from '../../common/OpenInNewLink';
import { SearchBox } from '../../common/SearchBox';
import { PreviewEditor } from '../../editor/PreviewEditor';
import { StyledReflexContainer, StyledReflexElement } from '../../reflex/ReflexComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    FullEnvironmentVariable,
    RepositionVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
    UpdateVariableEvent,
} from './types';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';

interface RepositionedVariable extends FullEnvironmentVariable {
    index: number;
    parentId?: string;
}

interface EnvironmentVariableDetailsProps {
    documentationUrl: string;
    errors?: string;
    hasUnsavedChanges?: boolean;
    isReadmeDefault?: boolean;
    loading?: boolean;
    parsedContent?: string;
    saving?: boolean;
    templateMode?: boolean;
    variables?: FullEnvironmentVariable[];
    workspaceLocked?: boolean;
    workspaceUid: string;
    onChangeVariableType(event: ChangeVariableTypeEvent): void;
    onCreateFolder(): void;
    onCreateVariable(parentId?: string): void;
    onDeleteVariable(event: DeleteVariableEvent): void;
    onRepositionVariable(event: RepositionVariableEvent): void;
    onSaveChanges(variables: FullEnvironmentVariable[]): void;
    onToggleVariableEditMode(event: ToggleVariableEditModeEvent): void;
    onToggleVariableExpand(event: ToggleVariableExpandEvent): void;
    onUpdateVariable(event: UpdateVariableEvent): void;
}

const StyledOuterContainer = styled('div')(() => ({
    height: '100%',
    overflowY: 'hidden',
    width: '100%',
}));

const StyledMainContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
}));

const StyledToolbar = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
}));

const StyledToolbarActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
}));

const StyledSaveButton = styled(IconButton)(({ theme }) => ({
    '&.showOutline': {
        outline: `1px solid ${theme.palette.primary.dark}`,
    },
}));

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.disabled,
    height: '26px !important',
    position: 'absolute',
    width: '26px !important',
}));

const StyledReadmeButton = styled(ToggleButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.dark,
    height: 40,
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
}));

const StyledFormContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(1.5),
    overflowY: 'auto',
    padding: theme.spacing(3),
}));

const StyledWelcomeScreenBox = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexDirection: 'column',
    padding: theme.spacing(3),
}));

const StyledCenteredTitle = styled(DialogTitleMain)(() => ({
    display: 'block',
    margin: 0,
    textAlign: 'center',
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 40,
        width: 40,
    },
}));

const StyledWelcomeScreenButtonContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
}));

const StyledEmptyFormContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    height: 180,
    justifyContent: 'center',
    width: '100%',
}));

export const EnvironmentVariableDetails: React.FC<EnvironmentVariableDetailsProps> = ({
    documentationUrl,
    errors,
    hasUnsavedChanges = false,
    isReadmeDefault = false,
    loading = false,
    parsedContent = '',
    saving = false,
    templateMode,
    variables = [],
    workspaceLocked,
    workspaceUid,
    onChangeVariableType,
    onCreateFolder,
    onCreateVariable,
    onDeleteVariable,
    onRepositionVariable,
    onSaveChanges,
    onToggleVariableEditMode,
    onToggleVariableExpand,
    onUpdateVariable,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const readmeFlexGrowKey = `environmentVariablesReadmeFlexGrow-${workspaceUid}`;
    const readmeDefaultFlexGrow = 0.4;
    const readmeOpenKey = `isEnvironmentVariablesReadmeOpen-${workspaceUid}`;
    const isReadmeOpenInitially = templateMode || readLocalStorage(readmeOpenKey, !isReadmeDefault);

    const [searchTerm, setSearchTerm] = useState('');
    const [draggedItem, setDraggedItem] = useState<RepositionedVariable | null>(null);
    const [readmeOpen, setReadmeOpen] = useState(isReadmeOpenInitially);
    const [readmeFlexGrow, setReadmeFlexGrow] = useState(
        templateMode ? readmeDefaultFlexGrow : readLocalStorage(readmeFlexGrowKey, readmeDefaultFlexGrow)
    );

    const handleReadmeResize = (e: HandlerProps): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            setReadmeFlexGrow(flexGrowCurrent);
            saveLocalStorage(readmeFlexGrowKey, flexGrowCurrent);
        }
    };

    const handleToggleReadme = (): void => {
        setReadmeOpen(!readmeOpen);
        if (!templateMode && !isReadmeDefault) {
            saveLocalStorage(readmeOpenKey, !readmeOpen);
        }
    };

    const canSave = !saving && !workspaceLocked && hasUnsavedChanges;

    const isDraggedVariableDuplicate =
        !!draggedItem?.keyName &&
        !!draggedItem.parentId &&
        variables.some((v) => v.keyName && v.keyName === draggedItem.keyName);

    const findVariable = (id?: UniqueIdentifier): RepositionedVariable | undefined => {
        for (const variable of variables) {
            if (variable.id === id) {
                const index = variables.findIndex((v) => v.id === id);
                return { ...variable, id, index, keyName: variable.keyName };
            }

            if (variable.childVariables) {
                const subVariable = variable.childVariables?.find((cv) => cv.id === id);
                const subIndex = variable.childVariables?.findIndex((cv) => cv.id === id);

                if (subVariable && subIndex !== -1) {
                    return {
                        ...subVariable,
                        id: subVariable.id,
                        index: subIndex,
                        keyName: subVariable.keyName,
                        parentId: variable.id,
                    };
                }
            }
        }
    };

    const getGhostVariable = (id?: UniqueIdentifier): RepositionedVariable | undefined => {
        if (id === 'root-last') {
            return { id, index: variables.length };
        }

        const parentId = id?.toString().split('-')[0] ?? '';
        const childVariables = variables.find((v) => v.id === parentId)?.childVariables;

        if (id) {
            return { id: id.toString(), index: childVariables ? childVariables.length : 0, parentId };
        }
    };

    const getVariableRepositionData = (
        activeId?: UniqueIdentifier,
        overId?: UniqueIdentifier
    ): RepositionVariableEvent | undefined => {
        const isHoveringOverGhost = overId?.toString()?.includes('-last');
        const activeItem = findVariable(activeId);
        const overItem = isHoveringOverGhost ? getGhostVariable(overId) : findVariable(overId);

        if (activeItem && overItem) {
            const isOnSameLevel = activeItem.parentId === overItem.parentId;
            const newIndex =
                !isOnSameLevel || isHoveringOverGhost || overItem.index < activeItem.index
                    ? overItem.index
                    : overItem.index - 1;

            return {
                newIndex,
                newParentId: overItem.parentId,
                originalIndex: activeItem.index,
                originalParentId: activeItem.parentId,
            };
        }
    };

    const handleDragStart = ({ active }: DragStartEvent): void => {
        const activeItem = findVariable(active.id);
        if (activeItem) {
            setDraggedItem(activeItem);
        }
    };

    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        const repositionData = getVariableRepositionData(active.id, over?.id);

        if (repositionData) {
            onRepositionVariable(repositionData);
        }

        setDraggedItem(null);
    };

    const filterVariablesBySearchTerm = (): FullEnvironmentVariable[] => {
        return searchTerm
            ? variables
                  .filter(
                      (v) =>
                          v.keyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          v.childVariables?.some((cv) => cv.keyName?.toLowerCase().includes(searchTerm.toLowerCase()))
                  )
                  .map((v) => ({
                      ...v,
                      childVariables: v.keyName?.toLowerCase().includes(searchTerm.toLowerCase())
                          ? v.childVariables
                          : v.childVariables?.filter((cv) =>
                                cv.keyName?.toLowerCase().includes(searchTerm.toLowerCase())
                            ),
                  }))
            : variables;
    };

    const filteredVariables = filterVariablesBySearchTerm();

    const variableList = filteredVariables.map((v) => (
        <EnvironmentVariable
            key={v.id}
            childVariables={v.childVariables}
            choices={v.choices}
            defaultValue={v.defaultValue}
            description={v.description}
            editMode={v.editMode}
            expanded={v.expanded}
            hasBeenEdited={v.hasBeenEdited}
            id={v.id}
            keyName={v.keyName}
            passwordValueFilled={v.passwordValueFilled}
            required={v.required}
            sameLevelKeyNames={variables.map((es) => es.keyName ?? '')}
            templateMode={templateMode}
            type={v.type}
            value={v.value}
            workspaceLocked={workspaceLocked}
            onAddVariable={(parentId) => handleCreateVariable(parentId)}
            onChangeType={onChangeVariableType}
            onDelete={onDeleteVariable}
            onToggleEditMode={onToggleVariableEditMode}
            onToggleExpand={onToggleVariableExpand}
            onUpdate={onUpdateVariable}
        />
    ));

    const handleCreateFolder = (): void => {
        setSearchTerm('');
        onCreateFolder();
    };

    const handleCreateVariable = (parentId?: string): void => {
        setSearchTerm('');
        onCreateVariable(parentId);
    };

    const toolbar = (
        <StyledToolbar>
            <StyledToolbarActions>
                {!templateMode && (
                    <Tooltip title="Save changes">
                        <>
                            <StyledSaveButton
                                className={!saving && hasUnsavedChanges ? 'showOutline' : undefined}
                                disabled={!canSave}
                                onClick={() => onSaveChanges(variables)}
                            >
                                <SaveOutlinedIcon />
                            </StyledSaveButton>
                            {saving && <StyledLoadingSpinner />}
                        </>
                    </Tooltip>
                )}
                <Tooltip title="Documentation">
                    <IconButton onClick={() => window.open(documentationUrl)}>
                        <MenuBookOutlinedIcon />
                    </IconButton>
                </Tooltip>
                <SearchBox
                    placeholder="Search by key name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Tooltip title={readmeOpen ? 'Hide README' : 'Show README'}>
                    <ToggleButtonGroup onChange={handleToggleReadme}>
                        <StyledReadmeButton selected={readmeOpen} value={readmeOpen}>
                            <TextSnippetOutlinedIcon />
                        </StyledReadmeButton>
                    </ToggleButtonGroup>
                </Tooltip>
            </StyledToolbarActions>

            {!templateMode && (
                <StyledToolbarActions>
                    <Button
                        startIcon={<AddIcon />}
                        disabled={workspaceLocked}
                        variant="outlined"
                        onClick={handleCreateFolder}
                    >
                        Folder
                    </Button>
                    <Button startIcon={<AddIcon />} disabled={workspaceLocked} onClick={() => handleCreateVariable()}>
                        Variable
                    </Button>
                </StyledToolbarActions>
            )}
        </StyledToolbar>
    );

    const showWelcomeScreen = !templateMode && !variables.length && !hasUnsavedChanges;

    return (
        <StyledOuterContainer>
            {loading ? (
                <CenteredLoadingSpinner />
            ) : (
                <>
                    {toolbar}
                    <StyledReflexContainer
                        sx={{ height: 'calc(100% - 60px)' }}
                        orientation="vertical"
                        data-test-id="environment-variables"
                    >
                        {readmeOpen && (
                            <StyledReflexElement flex={readmeFlexGrow} onStopResize={handleReadmeResize}>
                                <PreviewEditor parsedContent={parsedContent ?? ''} />
                            </StyledReflexElement>
                        )}
                        {readmeOpen && ( // Cannot move under same clause or functionality breaks
                            <ReflexSplitter>
                                <DragIndicatorIcon />
                            </ReflexSplitter>
                        )}
                        <StyledReflexElement minSize={410}>
                            <StyledMainContainer>
                                <>
                                    {showWelcomeScreen ? (
                                        <StyledFormContent>
                                            <StyledWelcomeScreenBox>
                                                <StyledIconContainer>
                                                    <SettingsOutlinedIcon />
                                                </StyledIconContainer>
                                                <StyledCenteredTitle title="Welcome to Environment Variables" />
                                                <Typography color="text.secondary" mb={3}>
                                                    Create a new key or folder to start, as there are currently no
                                                    variables.
                                                </Typography>
                                                <StyledWelcomeScreenButtonContainer>
                                                    <Button
                                                        startIcon={<AddIcon />}
                                                        disabled={workspaceLocked}
                                                        variant="outlined"
                                                        onClick={handleCreateFolder}
                                                    >
                                                        Folder
                                                    </Button>
                                                    <Button
                                                        startIcon={<AddIcon />}
                                                        disabled={workspaceLocked}
                                                        onClick={() => handleCreateVariable()}
                                                    >
                                                        Variable
                                                    </Button>
                                                </StyledWelcomeScreenButtonContainer>
                                                <OpenInNewLink url={documentationUrl}>
                                                    Learn more about environment variables in the documentation
                                                </OpenInNewLink>
                                            </StyledWelcomeScreenBox>
                                        </StyledFormContent>
                                    ) : filteredVariables.length ? (
                                        <>
                                            <StyledFormContent>
                                                {isDraggedVariableDuplicate && (
                                                    <DialogAlert
                                                        sx={{ margin: 0 }}
                                                        severity="warning"
                                                        text="Duplicate variable detected - Cannot add variable to root level."
                                                    />
                                                )}
                                                {errors && (
                                                    <DialogAlert
                                                        sx={{ margin: 0 }}
                                                        severity="error"
                                                        alertTitle="Error"
                                                        text={errors}
                                                    />
                                                )}
                                                <DndContext
                                                    collisionDetection={closestCorners}
                                                    onDragStart={handleDragStart}
                                                    onDragCancel={() => setDraggedItem(null)}
                                                    onDragEnd={handleDragEnd}
                                                >
                                                    {variableList}
                                                    {draggedItem && !isDraggedVariableDuplicate && (
                                                        <GhostVariable id="root-last" />
                                                    )}
                                                    <DragOverlay
                                                        dropAnimation={{
                                                            duration: 200,
                                                            easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                                                        }}
                                                    >
                                                        {draggedItem && (
                                                            <EnvironmentVariable
                                                                childVariables={draggedItem.childVariables}
                                                                choices={draggedItem.choices}
                                                                description={draggedItem.description}
                                                                dragOverlay={true}
                                                                id={draggedItem.id}
                                                                keyName={draggedItem.keyName}
                                                                required={draggedItem.required}
                                                                type={draggedItem.type}
                                                                value={draggedItem.value}
                                                            />
                                                        )}
                                                    </DragOverlay>
                                                </DndContext>
                                            </StyledFormContent>
                                        </>
                                    ) : (
                                        <>
                                            <StyledEmptyFormContainer>
                                                <StyledIconContainer>
                                                    <SearchIcon />
                                                </StyledIconContainer>
                                                <StyledCenteredTitle
                                                    title={searchTerm ? 'No results found' : 'No variables found'}
                                                />
                                                <Typography color="text.secondary">
                                                    {searchTerm
                                                        ? 'Try a different search term.'
                                                        : !templateMode
                                                        ? 'Create a new key or folder to start, as there are currently no variables.'
                                                        : null}
                                                </Typography>
                                            </StyledEmptyFormContainer>
                                        </>
                                    )}
                                </>
                            </StyledMainContainer>
                        </StyledReflexElement>
                    </StyledReflexContainer>
                </>
            )}
        </StyledOuterContainer>
    );
};
