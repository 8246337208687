import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const zenDeskEventListenerSetupDialogOpenAction$ = monitor(
    'zenDeskEventListenerSetupDialogOpenAction$',
    new Subject<ZenDeskEventListenerSetupBaseDetails>()
);
export const zenDeskEventListenerSetupDialogCloseAction$ = monitor(
    'zenDeskEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const zenDeskEventListenerSetupDialogLoading$ = monitor(
    'zenDeskEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const zenDeskEventListenerSetupDialogOpen$ = monitor(
    'zenDeskEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const zenDeskEventListenerSetupDialogDetails$ = monitor(
    'zenDeskEventListenerSetupDialogDetails$',
    new BehaviorSubject<ZenDeskEventListenerSetupDetails | undefined>(undefined)
);

export type ZenDeskEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
};

export type ZenDeskEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & ZenDeskEventListenerSetupBaseDetails;

zenDeskEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            zenDeskEventListenerSetupDialogOpen$.next(true);
            zenDeskEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    zenDeskEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    zenDeskEventListenerSetupDialogDetails$.next(details);
                }

                zenDeskEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                zenDeskEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

zenDeskEventListenerSetupDialogCloseAction$.subscribe(() => {
    zenDeskEventListenerSetupDialogOpen$.next(false);
    zenDeskEventListenerSetupDialogDetails$.next(undefined);
});
