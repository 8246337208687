import { useObservableState } from 'observable-hooks';
import { HelpAndSupportDialog } from '../components/help-and-support';
import { closeHelpAndSupportModalAction$, helpAndSupportModalOpen$ } from '../store/helpAndSupport';
import {
    documentationUrl,
    feedbackBoardUrl,
    mondayDocumentationUrl,
    mondayFeedbackBoardUrl,
    mondayServiceManagementUrl,
    requestScriptingHelpUrl,
    slackCommunityUrl,
    supportPortalUrl,
    // youtubeChannelUrl,
} from '../utils/documentation';
import { loggedInUserDetails$ } from '../store/user';
import { MONDAY_ORIGIN } from '../utils/constants';
import { segmentAnalyticsTrack } from '../data/segment-analytics';

export const HelpAndSupportContainer: React.FC = () => {
    const open = useObservableState(helpAndSupportModalOpen$);
    const userDetails = useObservableState(loggedInUserDetails$);

    const isMondayUser = userDetails?.userOrigin === MONDAY_ORIGIN;

    return (
        <HelpAndSupportDialog
            open={open}
            onClose={() => closeHelpAndSupportModalAction$.next()}
            content={[
                ...(!isMondayUser
                    ? [
                          {
                              title: 'Slack community',
                              description:
                                  'Stay up to date with the latest announcements, and benefit from premium support.',
                              link: slackCommunityUrl,
                              linkText: 'Join Slack',
                          },
                      ]
                    : []),
                {
                    title: 'Feedback board',
                    description:
                        'Help us identify what we should build next. Vote on existing ideas or suggest new ones!',
                    link: isMondayUser ? mondayFeedbackBoardUrl : feedbackBoardUrl,
                    linkText: isMondayUser ? 'Feedback board' : 'See Nolt',
                    onClick: () =>
                        segmentAnalyticsTrack('Feedback Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'Support portal',
                    description: "Report issues, make suggestions, and more. Whatever you need, we're here to help!",
                    link: isMondayUser ? mondayServiceManagementUrl : supportPortalUrl,
                    linkText: 'Contact us',
                    onClick: () =>
                        segmentAnalyticsTrack('Support Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'ScriptRunner Connect product documentation',
                    description: 'A growing collection of technical and how to docs to help you use the app.',
                    link: isMondayUser ? mondayDocumentationUrl : documentationUrl,
                    linkText: 'Visit documentation',
                    onClick: () =>
                        segmentAnalyticsTrack('Documentation Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'Scripting help',
                    description: 'Our team of experts are here to assist you with all your scripting needs.',
                    link: requestScriptingHelpUrl,
                    linkText: 'Contact us',
                    onClick: () =>
                        segmentAnalyticsTrack('ScriptingSupport Requested', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
            ]}
        />
    );
};
