import { LoaderFn } from 'react-location';
import { getOrganizationViewDetails } from '../../data/organization';
import { LocationGenerics } from '../../router';
import { selectedOrganizationViewDetails$, selectOrganizationAndInitPendoAction$ } from '../../store/organization';
import { loadErrorPage } from '../../store/error';

export const organizationLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const orgUid = routeMatch.params.teamUid!;

    try {
        const details = await getOrganizationViewDetails(orgUid);
        selectOrganizationAndInitPendoAction$.next({ orgUid });
        selectedOrganizationViewDetails$.next(details);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load team details.',
        });

        throw e;
    }

    return {};
};
