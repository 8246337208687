import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ProductIcon } from '../icons/ProductIcon';
import { ThreeDotsButton } from '../common/buttons/ThreeDotsButton';
import { SelectorAuthorized, selectorCardStyle, SelectorItemTitle } from '../selector/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';

interface AppCardProps {
    authorized?: boolean;
    description?: string;
    selected?: boolean;
    onDelete?(uid: string): void;
    onSelect(uid: string): void;
    title: string;
    type: string;
    uid: string;
}

const StyledCard = styled(Card)<{ selected?: boolean }>(({ theme, selected }) => ({
    ...selectorCardStyle(theme),
    display: 'flex',
    flexDirection: 'column',
    height: 170,
    justifyContent: 'space-between',
    outline: selected ? theme.constants.borderSelected : undefined,
    backgroundColor: selected ? theme.palette.background.default : undefined,
    padding: theme.spacing(3, 2),
    width: 220,
}));

const StyledCardUpper = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    left: 0,
    padding: theme.spacing(1, 1, 1, 2),
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 3,
}));

const StyledCardContent = styled(CardContent)({
    alignItems: 'flex-end',
    display: 'flex',
    padding: 0 + ' !important',
    height: 40,
    width: '100%',
});

const StyledMediaContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 2, 1),
    width: '100%',
}));

const StyledIconContainer = styled('div')({
    '& .MuiSvgIcon-root': {
        height: 48,
        width: 48,
    },
});

export const AppCard: React.FC<AppCardProps> = ({
    authorized = false,
    onDelete,
    onSelect,
    title,
    type,
    uid,
    selected = false,
}) => {
    const MediaContainer: React.FC = () => {
        return (
            <>
                <StyledMediaContainer>
                    <Tooltip title={type} describeChild>
                        <StyledIconContainer>
                            <ProductIcon name={type} />
                        </StyledIconContainer>
                    </Tooltip>
                </StyledMediaContainer>
            </>
        );
    };

    return (
        <>
            <StyledCard elevation={1} onClick={() => onSelect(uid)} selected={selected}>
                <StyledCardUpper>
                    {authorized && <SelectorAuthorized />}
                    {onDelete && (
                        <ThreeDotsButton
                            actions={[{ name: 'Delete', onSelect: onDelete, icon: <DeleteOutlineIcon /> }]}
                            uid={uid}
                        />
                    )}
                </StyledCardUpper>
                <CardMedia component={MediaContainer}> </CardMedia>
                <StyledCardContent>
                    <SelectorItemTitle title={title} block={true} />
                </StyledCardContent>
            </StyledCard>
        </>
    );
};
