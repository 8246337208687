import { useState } from 'react';
import { styled, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Button } from '../../common/buttons/Button';
import {
    PageContainer,
    PageUpperContainer,
    PageUpperGroup,
    PageTitle,
    PageViewButtonGroup,
    PageGridContainer,
    EmptyStateContainer,
    EmptyStateText,
    EmptyStateImg,
    PageSubTitle,
    StyledActionButton,
} from '../../layout';
import {
    SelectorTable,
    SelectorTitleIcon,
    SelectorViewButton,
    viewSelectedStyle,
} from '../../selector/SelectorComponents';
import {
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
    WorkspaceSelectorTableItem,
} from './WorkspaceSelectorTableItem';
import { WorkspaceSelectorCard } from './WorkspaceSelectorCard';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { NewCopiedWorkspaceDetails } from '../../../store/workspaces';

interface WorkspaceSelectorProps {
    userCanWorkWithTemplates?: boolean;
    workspaces?: {
        description?: string;
        environments: {
            name: string;
            uid: string;
            selected: boolean;
            deployment?: {
                uid: string;
                version: string;
            };
        }[];
        template: boolean;
        incoming?: string[];
        outgoing?: string[];
        orgName?: string;
        shared?: boolean;
        title: string;
        uid: string;
        users: {
            firstName?: string;
            lastName?: string;
            email: string;
            avatar?: string;
        }[];
    }[];
    onCreateBlank(): void;
    onDelete(workspaceUid: string): void;
    onDuplicate(workspaceDetails: NewCopiedWorkspaceDetails): void;
    onSelect(workspaceUid: string): void;
    onStartWizard(): void;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    paddingRight: theme.spacing(1),
    '& .MuiCheckbox-root': {
        marginLeft: theme.spacing(0),
    },
}));

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
    userCanWorkWithTemplates,
    workspaces = [],
    onCreateBlank,
    onDelete,
    onDuplicate,
    onSelect,
    onStartWizard,
}) => {
    const [view, setView] = useState<'grid' | 'list'>(readLocalStorage('workspacesView', 'grid'));
    const [displayTemplates, setDisplayTemplates] = useState(false);

    const handleViewChange = (view: 'grid' | 'list'): void => {
        setView(view);
        saveLocalStorage('workspacesView', view);
    };

    const displayedWorkspaces = workspaces
        .filter((ws) => (userCanWorkWithTemplates && displayTemplates) || !ws.template)
        .map((fws) => {
            if (view === 'grid') {
                return (
                    <WorkspaceSelectorCard
                        key={fws.uid}
                        description={fws.description}
                        environments={fws.environments}
                        template={fws.template}
                        incoming={fws.incoming}
                        onDelete={onDelete}
                        onDuplicate={(uid) =>
                            onDuplicate({ sourceUid: uid, name: fws.title, description: fws.description })
                        }
                        onSelect={onSelect}
                        outgoing={fws.outgoing}
                        title={fws.title}
                        uid={fws.uid}
                        users={fws.users}
                        orgName={fws.orgName}
                        shared={fws.shared}
                    />
                );
            } else if (view === 'list') {
                return (
                    <WorkspaceSelectorTableItem
                        key={fws.uid}
                        description={fws.description}
                        environments={fws.environments}
                        template={fws.template}
                        incoming={fws.incoming}
                        onDelete={onDelete}
                        onDuplicate={(uid) =>
                            onDuplicate({ sourceUid: uid, name: fws.title, description: fws.description })
                        }
                        onSelect={onSelect}
                        outgoing={fws.outgoing}
                        title={fws.title}
                        uid={fws.uid}
                        users={fws.users}
                        orgName={fws.orgName}
                        shared={fws.shared}
                    />
                );
            }
        });

    const tableTitles = (
        <TableRow>
            <StyledTextCell>Title</StyledTextCell>
            <StyledTextCell>Description</StyledTextCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <DownloadOutlinedIcon />
                </SelectorTitleIcon>
                Incoming
            </StyledIconCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <UploadOutlinedIcon />
                </SelectorTitleIcon>
                Outgoing
            </StyledIconCell>

            <StyledShortCell>Members</StyledShortCell>
            <StyledTextCell>Team</StyledTextCell>
            <StyledShortCell>Environments</StyledShortCell>
            <StyledShortCell>Status</StyledShortCell>
            <StyledShortCell sx={{ textAlign: 'end' }}>Actions</StyledShortCell>
        </TableRow>
    );

    const currentResultsLength = displayedWorkspaces.length ?? 0;
    const resultsLabel =
        currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';

    return (
        <PageContainer>
            {workspaces.length > 0 ? (
                <>
                    <PageUpperContainer>
                        <PageTitle title="Workspaces" label={resultsLabel} />
                        <PageUpperGroup>
                            <PageViewButtonGroup>
                                <SelectorViewButton
                                    icon="grid"
                                    onClick={() => {
                                        if (view === 'list') {
                                            handleViewChange('grid');
                                        }
                                    }}
                                    sx={(theme) => {
                                        return view === 'grid' ? viewSelectedStyle(theme) : null;
                                    }}
                                />

                                <SelectorViewButton
                                    icon="list"
                                    onClick={() => {
                                        if (view === 'grid') {
                                            handleViewChange('list');
                                        }
                                    }}
                                    sx={(theme) => {
                                        return view === 'list' ? viewSelectedStyle(theme) : null;
                                    }}
                                />
                            </PageViewButtonGroup>
                            {userCanWorkWithTemplates && (
                                <StyledFormControlLabel
                                    control={
                                        <Checkbox
                                            checked={displayTemplates}
                                            onChange={() => setDisplayTemplates(!displayTemplates)}
                                        />
                                    }
                                    label="Display templates"
                                />
                            )}
                            <Button onClick={onCreateBlank} startIcon={<AddIcon />}>
                                Create New
                            </Button>
                        </PageUpperGroup>
                    </PageUpperContainer>
                    {displayedWorkspaces.length > 0 ? (
                        view === 'grid' ? (
                            <PageGridContainer>{displayedWorkspaces}</PageGridContainer>
                        ) : (
                            <SelectorTable>
                                <TableHead>{tableTitles}</TableHead>
                                <TableBody>{displayedWorkspaces}</TableBody>
                            </SelectorTable>
                        )
                    ) : (
                        <Typography>No workspaces found</Typography>
                    )}
                </>
            ) : (
                <>
                    <PageUpperContainer>
                        <PageTitle title="Workspaces" />
                    </PageUpperContainer>
                    <EmptyStateContainer>
                        <EmptyStateImg src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Hello" />
                        <PageSubTitle variant="h6">Create your first Workspace</PageSubTitle>
                        <EmptyStateText>
                            Workspaces allow you to combine <strong>APIs</strong> of apps you wish to integrate with.
                            Use <strong>Event Listeners</strong> to react to events triggered from those apps, and write{' '}
                            <strong>Scripts</strong> for integration logic.
                        </EmptyStateText>
                        <StyledActionButton startIcon={<AddIcon />} onClick={onCreateBlank}>
                            New Workspace
                        </StyledActionButton>
                        <EmptyStateText>Not sure where to start?</EmptyStateText>
                        <Button variant="outlined" onClick={onStartWizard}>
                            Step-by-step setup
                        </Button>
                    </EmptyStateContainer>
                </>
            )}
        </PageContainer>
    );
};
