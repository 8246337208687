import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../dialog/DialogComponents';
import { Dropdown } from '../common/dropdown/Dropdown';
import { IconCircle } from '../icon-circle';
import { UserDetails } from '../../utils/types';
import { autoFocus, isFocused } from '../../utils/autoFocus';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface NewWorkspaceDialogProps {
    defaultOrganizationUid?: string;
    description?: string;
    editMode?: boolean;
    errors?: string;
    isTemplate?: boolean;
    isWorkspaceOwner?: boolean;
    loading?: boolean;
    name?: string;
    open?: boolean;
    organizations?: {
        value: string;
        name: string;
    }[];
    organizationSelectorDisabled?: boolean;
    originalOwner?: UserDetails;
    saving?: boolean;
    selectedOrganizationUid?: string;
    templateSelectorDisabled?: boolean;
    onCancel(): void;
    onSave(event: NewWorkspaceUpdateEvent): void;
}

export interface NewWorkspaceUpdateEvent {
    description?: string;
    name: string;
    selectedOrganizationUid?: string;
    template?: boolean;
}

const StyledTextFieldMultiline = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        minHeight: 82,
    },
}));

export const NewWorkspaceDialog: React.FC<NewWorkspaceDialogProps> = ({
    description,
    editMode = false,
    errors,
    isTemplate = false,
    isWorkspaceOwner = true,
    loading = false,
    name,
    open = false,
    organizations = [],
    organizationSelectorDisabled,
    saving = false,
    selectedOrganizationUid,
    templateSelectorDisabled = true,
    onCancel,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentOrganization, setCurrentOrganization] = useState(selectedOrganizationUid || organizations[0]?.value);
    const [currentOrganizations, setCurrentOrganizations] = useState(organizations);
    const [currentName, setCurrentName] = useState(name);
    const [templateChecked, setTemplateChecked] = useState(isTemplate);
    const [currentDescription, setCurrentDescription] = useState(description);

    const nameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentOrganization(selectedOrganizationUid || organizations[0]?.value);
        setCurrentOrganizations(organizations);
    }, [open, selectedOrganizationUid, organizations.length]);

    useEffect(() => {
        setCurrentName(name);
        setCurrentDescription(description);
        setTemplateChecked(isTemplate);
    }, [open]);

    useEffect(() => {
        if (open && !loading) {
            autoFocus(nameRef);
        }
    }, [loading]);

    const changingTeam = !!selectedOrganizationUid && selectedOrganizationUid !== currentOrganization;
    const ownerWarning = changingTeam && !isWorkspaceOwner;

    const handleSave = (): void => {
        onSave({
            name: currentName ?? '',
            description: currentDescription,
            template: templateChecked,
            selectedOrganizationUid: currentOrganization,
        });
    };

    const hasChanged =
        currentName !== name || currentDescription !== description || templateChecked !== isTemplate || changingTeam;

    const canSave = !!currentName && !loading && !saving && (editMode ? hasChanged && !!currentOrganization : true);

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave && !isFocused(descriptionRef),
                    enterFn: handleSave,
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleMain
                title={editMode ? 'Edit Workspace' : 'New Workspace'}
                icon={<IconCircle icon={<WorkspacesOutlinedIcon />} />}
            />
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    <DialogContent>
                        {changingTeam && (
                            <DialogAlert
                                severity="warning"
                                alertTitle="Privacy and collaboration"
                                text="You are about to move the workspace from one team to another. Doing so will result in a removal of all the attached Connectors and invalidation of webhooks for all of the Event Listeners. API Connections and Event Listeners would have to be set up again. This is done for safety reasons."
                            />
                        )}
                        {ownerWarning && (
                            <DialogAlert
                                severity="warning"
                                alertTitle="Warning"
                                text="This action makes the workspace inaccessible by the original user if they are not part of the team you are about to associate the workspace with."
                            />
                        )}
                        {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                        <TextField
                            id="name-text-field"
                            label="Workspace name"
                            inputRef={nameRef}
                            value={currentName}
                            onChange={(e) => setCurrentName(e.target.value)}
                            required
                            placeholder="Enter a name"
                        />
                        <StyledTextFieldMultiline
                            inputRef={descriptionRef}
                            value={currentDescription}
                            onChange={(e) => setCurrentDescription(e.target.value)}
                            label="Description"
                            multiline
                            maxRows={2}
                            placeholder="Enter a description"
                        />
                        {editMode && (
                            <Box paddingBottom={2}>
                                <Dropdown
                                    disabled={organizationSelectorDisabled}
                                    label="Change team"
                                    items={currentOrganizations}
                                    selectedItem={currentOrganization}
                                    required
                                    onSelect={(val) => setCurrentOrganization(val)}
                                />
                            </Box>
                        )}
                        {!templateSelectorDisabled && (
                            <FormControl sx={{ width: 300 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={templateChecked}
                                                onChange={() => setTemplateChecked(!templateChecked)}
                                            />
                                        }
                                        label="Workspace is a template"
                                    />
                                </FormGroup>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()} variant="outlined">
                            Cancel
                        </Button>

                        <Button busy={saving} disabled={!canSave} onClick={handleSave}>
                            {editMode ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
