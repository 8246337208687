import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AvatarContainer } from '../common/Avatars';
import { Button } from '../common/buttons/Button';
import { DialogAlert } from '../dialog';
import { Dropdown } from '../common/dropdown/Dropdown';
import { PageContainer, PageTitle } from '../layout';
import { StyledImageBox } from '../organization/OrganizationComponents';
import { InfoIcon } from '../icons/InfoIcon';
import { autoFocus } from '../../utils/autoFocus';
import { AppDialog, ProfileCommonContainer } from './ProfileComponents';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

export interface ProfileProps {
    avatar?: string;
    company?: string;
    customRole?: string;
    email: string;
    errors?: string;
    firstName?: string;
    lastName?: string;
    roles: {
        value: string;
        name: string;
    }[];
    roleUid?: string;
    saving?: boolean;
    showPasswordReset: boolean;
    emailNotificationsEnabled: boolean;
    mfaEnabled: boolean;
    deleteDate?: Date;
    supportPortalUrl: string;
    scriptingFamiliarities: {
        value: string;
        name: string;
    }[];
    scriptingFamiliarityUid: string;
    selectedApps: string[];
    selectedGoals: string[];
    apps: {
        uid: string;
        name: string;
    }[];
    isMondayUser: boolean;
    // onInitiateAvatarUpload(): void;
    onUpdate(event: UpdateProfileEvent): void;
    onPasswordReset(): void;
    onDiscard(): void;
    onDelete(): void;
}

export interface UpdateProfileEvent {
    firstName?: string;
    lastName?: string;
    roleUid?: string;
    company?: string;
    customRole?: string;
    enableEmailNotifications: boolean;
    enableMfa: boolean;
    scriptingFamiliarityUid?: string;
    personalization?: {
        integrations: boolean;
        migrations: boolean;
        sync: boolean;
    };
    userAppPreference: string[];
}

const StyledContainer = styled('div')(() => ({
    maxWidth: 712,
}));

const StyledResetBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginTop: theme.spacing(1),
}));

const StyledResetLink = styled(Link)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
}));

const StyledDeleteButtonContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}));

const StyledDeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const StyledChipContainer = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
}));

const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

const StyledAppsTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: '100%',
        cursor: 'pointer',
        height: '100%',
    },
    '& .MuiInputBase-input': {
        cursor: 'pointer',
    },
    '& .MuiBox-root': {
        width: '100%',
    },
}));

const StyledGoalsChip = styled(Chip)(({ theme }) => ({
    fontSize: 14,
    height: 40,
    borderRadius: 100,
    '& .MuiAvatar-root': {
        height: 32,
        width: 32,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
        color: theme.palette.common.black,
    },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        '& > :nth-of-type(2)': {
            paddingTop: 0,
        },
    },
}));

export const Profile: React.FC<ProfileProps> = ({
    avatar,
    email,
    errors,
    company,
    customRole,
    firstName,
    lastName,
    roleUid,
    roles,
    saving = false,
    showPasswordReset,
    supportPortalUrl,
    deleteDate,
    scriptingFamiliarities,
    scriptingFamiliarityUid,
    selectedApps,
    selectedGoals,
    onDiscard,
    onPasswordReset,
    onUpdate,
    onDelete,
    emailNotificationsEnabled = false,
    mfaEnabled = false,
    apps,
    isMondayUser = false,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentFirstName, setFirstName] = useState(firstName);
    const [currentLastName, setLastName] = useState(lastName);
    const [currentRole, setCurrentRole] = useState(!customRole ? roleUid : undefined);
    const [currentCompany, setCompany] = useState(company);
    const [currentCustomRole, setCurrentCustomRole] = useState(customRole);
    const [currentEmailNotificationsEnabled, setCurrentEmailNotificationsEnabled] = useState(emailNotificationsEnabled);
    const [currentMfaEnabled, setCurrentMfaEnabled] = useState(mfaEnabled);
    const [currentScriptingFamiliarity, setCurrentScriptingFamiliarity] = useState(scriptingFamiliarityUid);
    const [currentSelectedApps, setSelectedApps] = useState<string[]>(selectedApps ?? []);
    const [currentSelectedGoals, setSelectedGoals] = useState<string[]>(selectedGoals ?? []);
    const [openAppDialog, setOpenAppDialog] = useState<boolean>(false);

    const hasDifferentValues = (arr1: string[], arr2: string[]): boolean =>
        arr1.some((item) => !arr2.includes(item)) || arr2.some((item) => !arr1.includes(item));

    const isDifferentFromSelectedGoals = hasDifferentValues(currentSelectedGoals, selectedGoals);
    const isDifferentSelectedApps = hasDifferentValues(currentSelectedApps, selectedApps);

    const isChanged =
        currentFirstName !== firstName ||
        currentLastName !== lastName ||
        currentRole !== roleUid ||
        currentCompany !== company ||
        currentCustomRole !== customRole ||
        currentEmailNotificationsEnabled !== emailNotificationsEnabled ||
        currentMfaEnabled !== mfaEnabled ||
        currentScriptingFamiliarity !== scriptingFamiliarityUid ||
        isDifferentFromSelectedGoals ||
        isDifferentSelectedApps;

    const canSave = isChanged && !saving && !!(currentRole !== 'Other' || currentCustomRole);

    useEffect(() => {
        if (currentCustomRole) {
            setCurrentRole('Other');
        }
    }, [currentCustomRole]);

    useEffect(() => {
        if (currentRole && currentRole !== 'Other') {
            setCurrentCustomRole('');
        } else if (!currentCustomRole) {
            autoFocus(inputRef);
        }
    }, [currentRole]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const selectableRoles = [...roles, { name: 'Other', value: 'Other' }];

    const goals = [
        {
            icon: <PrecisionManufacturingOutlinedIcon />,
            title: 'Automation',
        },
        {
            icon: <MoveDownOutlinedIcon />,
            title: 'Migration',
        },
        {
            icon: <CloudSyncOutlinedIcon />,
            title: 'Synchronisation',
        },
    ];

    const handleGoalChange = (event: SelectChangeEvent<string[]>): void => {
        setSelectedGoals(event.target.value as string[]);
    };

    const hasChips = currentSelectedApps.length > 0;

    return (
        <PageContainer>
            <PageTitle title="Profile settings" />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <StyledContainer>
                <ProfileCommonContainer title="Avatar">
                    {avatar ? (
                        <StyledImageBox size={80}>
                            <img src={`data:image/jpeg;base64,${avatar}`} width={80} height={80} alt="${avatar}" />
                        </StyledImageBox>
                    ) : (
                        <AvatarContainer
                            credentials={{ firstName: currentFirstName ?? '', lastName: currentLastName ?? '', email }}
                            size="large"
                        />
                    )}
                    {/* <StyledImageTextBox>
                    <Link onClick={onInitiateAvatarUpload}>Upload picture</Link>
                    <PageItalicText>File type .PNG, .JPEG (max file size 2MBs)</PageItalicText>
                </StyledImageTextBox> */}
                </ProfileCommonContainer>
                <ProfileCommonContainer title="Personal information">
                    <StyledGrid container spacing={2}>
                        <Grid item className="no-top-padding" xs={12} sm={12} md={6}>
                            <StyledTextField
                                fullWidth
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                                label="First name"
                                placeholder="Enter first name"
                                value={currentFirstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <StyledTextField
                                fullWidth
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                                label="Last name"
                                placeholder="Enter last name"
                                value={currentLastName}
                            />
                        </Grid>
                    </StyledGrid>
                    <StyledTextField
                        variant="outlined"
                        label="Email"
                        placeholder="Enter an email"
                        value={email}
                        required
                        fullWidth
                        disabled
                    />
                    <Typography variant="body2" color="text.secondary" ml={1.75}>
                        If you need to update your email, please{' '}
                        <Link href={supportPortalUrl} target="_blank">
                            contact our support team
                        </Link>{' '}
                        for assistance.
                    </Typography>
                    {showPasswordReset && (
                        <StyledResetBox>
                            <StyledResetLink onClick={onPasswordReset}>Reset password</StyledResetLink>
                            <Tooltip
                                title="Reset your password if you would like to change your password or have forgotten it."
                                placement="top"
                            >
                                <SvgIcon fontSize="small" color="primary">
                                    <InfoOutlinedIcon />
                                </SvgIcon>
                            </Tooltip>
                        </StyledResetBox>
                    )}
                </ProfileCommonContainer>
                <ProfileCommonContainer title="Work information">
                    <StyledTextField
                        onChange={(e) => setCompany(e.target.value)}
                        variant="outlined"
                        label="Company Name"
                        placeholder="Enter company name"
                        value={currentCompany}
                        fullWidth
                    />
                    <Dropdown
                        fullWidth
                        label="Profession"
                        items={selectableRoles}
                        selectedItem={currentRole}
                        onSelect={(value) => setCurrentRole(value)}
                        sx={{
                            width: '100%',
                        }}
                    />
                    {currentRole === 'Other' && (
                        <StyledTextField
                            inputRef={inputRef}
                            variant="outlined"
                            label="Role Name"
                            placeholder="Enter role"
                            value={currentCustomRole}
                            fullWidth
                            onChange={(e) => setCurrentCustomRole(e.target.value)}
                            required
                        />
                    )}
                </ProfileCommonContainer>
                {!isMondayUser && (
                    <ProfileCommonContainer
                        title="Skills and preferences"
                        subtitle="Be sure to update these sections as your preferences and goals change"
                        isNew={true}
                    >
                        <Dropdown
                            label="JavaScript Proficiency"
                            items={scriptingFamiliarities}
                            selectedItem={currentScriptingFamiliarity}
                            onSelect={(value) => setCurrentScriptingFamiliarity(value)}
                            fullWidth
                            sx={{
                                width: '100%',
                            }}
                        />
                        <FormControl
                            fullWidth
                            sx={{
                                margin: 0,
                                '& .MuiInputBase-root': {
                                    minHeight: 'unset',
                                },
                            }}
                        >
                            <StyledAppsTextField
                                fullWidth
                                onClick={() => setOpenAppDialog(true)}
                                multiline={hasChips ? true : false}
                                label="Favourite Apps"
                                sx={{
                                    '& textarea': {
                                        width: 0,
                                        height: 0,
                                    },
                                }}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: hasChips ? (
                                        <StyledChipContainer
                                            sx={{
                                                gap: 1,
                                            }}
                                        >
                                            {currentSelectedApps.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={apps.find((option) => option.uid === value)?.name}
                                                />
                                            ))}
                                        </StyledChipContainer>
                                    ) : null,
                                    endAdornment: (
                                        <IconButton onClick={() => setOpenAppDialog(true)}>
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                            <AppDialog
                                apps={apps}
                                userSelectedApps={currentSelectedApps}
                                open={openAppDialog}
                                onClose={() => setOpenAppDialog(false)}
                                onUpdate={(e) => setSelectedApps(e)}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="main-goals">Main Goals</InputLabel>
                            <Select
                                labelId="main-goals"
                                id="goals"
                                multiple
                                value={currentSelectedGoals}
                                onChange={handleGoalChange}
                                input={<OutlinedInput label="Main Goals" />}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    '& .MuiSelect-icon': {
                                        marginRight: 1,
                                    },
                                }}
                                renderValue={(selected) => (
                                    <StyledChipContainer
                                        sx={{
                                            gap: 1,
                                        }}
                                    >
                                        {selected.map((value) => (
                                            <StyledGoalsChip
                                                key={value}
                                                label={value}
                                                avatar={
                                                    <Avatar>{goals.find((goal) => goal.title === value)?.icon}</Avatar>
                                                }
                                            />
                                        ))}
                                    </StyledChipContainer>
                                )}
                            >
                                {goals.map((goal) => (
                                    <MenuItem key={goal.title} value={goal.title}>
                                        <Checkbox checked={currentSelectedGoals.includes(goal.title)} />
                                        <ListItemText primary={goal.title} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ProfileCommonContainer>
                )}
                <ProfileCommonContainer
                    title="Email Notifications"
                    subtitle="Recieve updates on the status of your integrations"
                    isNew={true}
                    tooltip="This will enable email notifications keeping you up to date on the status of your integrations."
                >
                    <FormControl>
                        <Typography variant="subtitle2">Script failure</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="controlled-radio-buttons-email-notification"
                            name="controlled-radio-buttons-email-notification"
                            value={currentEmailNotificationsEnabled}
                            onChange={(e) => setCurrentEmailNotificationsEnabled(e.target.value === 'true')}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="On" sx={{ marginRight: 1 }} />
                            <FormControlLabel value="false" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                </ProfileCommonContainer>
                <ProfileCommonContainer
                    title="Multi-Factor Authentication"
                    subtitle="Multi-Factor Authentication (MFA) enhances security by requiring multiple verification methods to confirm your identity."
                    isNew={true}
                    tooltip="This will enrol you to MFA the next time you login"
                >
                    <FormControl>
                        <Typography variant="subtitle2">
                            Enable Multi-Factor Authentication and prompt me to set it up next time I log in
                        </Typography>
                        <RadioGroup
                            row
                            aria-labelledby="controlled-radio-buttons-mfa"
                            name="controlled-radio-buttons-mfa"
                            value={currentMfaEnabled}
                            onChange={(e) => setCurrentMfaEnabled(e.target.value === 'true')}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="On" sx={{ marginRight: 1 }} />
                            <FormControlLabel value="false" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                </ProfileCommonContainer>
                <DialogActions>
                    <Button onClick={onDiscard} variant="outlined" disabled={!canSave}>
                        Discard
                    </Button>
                    <Button
                        onClick={() => {
                            const personalization = {
                                integrations: currentSelectedGoals.includes('Automation'),
                                migrations: currentSelectedGoals.includes('Migration'),
                                sync: currentSelectedGoals.includes('Synchronisation'),
                            };
                            onUpdate({
                                firstName: currentFirstName,
                                lastName: currentLastName,
                                roleUid: !currentCustomRole ? currentRole : undefined,
                                company: currentCompany,
                                customRole: currentCustomRole,
                                enableEmailNotifications: currentEmailNotificationsEnabled,
                                enableMfa: currentMfaEnabled,
                                scriptingFamiliarityUid: currentScriptingFamiliarity,
                                personalization,
                                userAppPreference: currentSelectedApps,
                            });
                        }}
                        disabled={!canSave}
                        busy={saving}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
                <StyledDeleteButtonContainer>
                    <StyledDeleteButton onClick={onDelete} variant="text" busy={saving}>
                        {deleteDate ? 'Cancel Account Deletion' : 'Delete Account'}
                    </StyledDeleteButton>
                    {deleteDate && (
                        <Tooltip
                            title={`Account will be deleted at the latest of ${new Date(
                                new Date(deleteDate).getTime() + 1000 * 60 * 60 * 24 * 31
                            ).toLocaleString()}`}
                        >
                            <InfoIcon />
                        </Tooltip>
                    )}
                </StyledDeleteButtonContainer>
            </StyledContainer>
        </PageContainer>
    );
};
