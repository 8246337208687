import { getInvocationsReport as getInvocationsReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as InvocationsReportRequest,
    Response as InvocationsReportResponse,
    InvocationsReportRecord,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getInvocations';
import { repositoryInvoker } from '../../utils/repository';
export type { InvocationsReportRequest, InvocationsReportResponse, InvocationsReportRecord };
export const getInvocationsForReport = async (
    request: InvocationsReportRequest
): Promise<InvocationsReportResponse> => {
    return await getInvocationsReportRpc(repositoryInvoker, request);
};
