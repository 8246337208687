import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../common/buttons/Button';
import { BitbucketIcon } from '../../icons/BitbucketIcon';
import { OpenInNewLink } from '../../common/OpenInNewLink';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { ConnectionModalTextField, ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { BitbucketCloudWizardStage, BitbucketCloudWizardSteps, StageType } from './BitbucketCloudWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../dialog';

export interface BitbucketCloudClientInfo {
    workspaceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface BitbucketCloudConnection {
    clientInfo: BitbucketCloudClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: BitbucketCloudWizardStage;
    setStage: (stage: BitbucketCloudWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    workspaceUrl: string;
    clientId: string;
    clientSecret: string;
}

const testUrl = async (workspaceUrl: string): Promise<boolean> => {
    try {
        const url = new URL(workspaceUrl);
        await fetch(workspaceUrl, {
            method: 'GET',
            mode: 'no-cors',
        });
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
        return false;
    }
};

const StageContent: StageType = (props) => {
    const [workspaceUrl, setWorkspaceUrl] = useState(props.clientInfo.workspaceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const BITBUCKET_PATH = '/workspace/settings/api';
    const BITBUCKET_URL = 'https://bitbucket.org/';

    const generateSettingsUrl = (): string => {
        return workspaceUrl.endsWith(BITBUCKET_PATH)
            ? workspaceUrl
            : workspaceUrl.endsWith('/')
            ? workspaceUrl.substring(0, workspaceUrl.length - 1) + BITBUCKET_PATH
            : workspaceUrl + BITBUCKET_PATH;
    };

    switch (props.stage) {
        case BitbucketCloudWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>This connector requires Bitbucket Cloud workspace url.</DialogContentText>
                        <ConnectionModalTextField
                            label={`${BITBUCKET_URL}<YOUR_WORKSPACE>`}
                            value={workspaceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setWorkspaceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if (!workspaceUrl.startsWith(BITBUCKET_URL)) {
                                    props.setError(`Please enter a valid workspace URL starting with ${BITBUCKET_URL}`);
                                    setBadUrlAttempted(true);
                                } else {
                                    if (await testUrl(workspaceUrl)) {
                                        setWorkspaceUrl(workspaceUrl);
                                        props.setStage(BitbucketCloudWizardStage.CREATE);
                                        setBadUrlAttempted(false);
                                    } else {
                                        props.setError(
                                            `Please enter a valid URL, starting with ${BITBUCKET_URL} or check you have access to the workspace`
                                        );
                                        setBadUrlAttempted(true);
                                    }
                                }
                            }}
                            disabled={!workspaceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketCloudWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the <OpenInNewLink url={generateSettingsUrl()}>workspace settings</OpenInNewLink>{' '}
                                page in Bitbucket and click on <strong>Add consumer</strong>.
                            </li>
                            <li>
                                Copy the <strong>Name</strong> and <strong>Callback URL</strong> below into the form.
                            </li>
                            <li>
                                Select the required <strong>permissions</strong>.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Callback URL" value={props.callbackUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(BitbucketCloudWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(BitbucketCloudWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case BitbucketCloudWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Click on the newly created consumer (or an existing one if you skipped the preview
                                step). A <strong>Key</strong> and <strong>Secret</strong> will be revealed.
                            </li>
                            <li>
                                Copy the <strong>Key</strong> and <strong>Secret</strong> into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Key"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketCloudWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketCloudWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketCloudWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Bitbucket you need to authorize our app to be able to make requests
                            on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketCloudWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    workspaceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketCloudWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const BitbucketCloudAppConfigureDialog: React.FC<BitbucketCloudConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<BitbucketIcon />} />
            {props.errors && error}
            <BitbucketCloudWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
