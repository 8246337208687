import copy from 'copy-to-clipboard';
import React, { RefObject, useState } from 'react';
import { Button } from '../common/buttons/Button';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';

export interface CopyProps {
    /**
     * Text to copy.
     */
    readonly value?: string | RefObject<Element>;
    /**
     * Text for copy button.
     */
    readonly label?: string | JSX.Element;
    /**
     * Text for hover tooltip.
     */
    readonly tooltip?: string;
    /**
     * Text for copy confirmation tooltip.
     */
    readonly copiedTooltip?: string;
}

const StyledCopy = styled('span')(
    ({ theme }) => `
        display: inline-flex;
        margin-left: ${theme.spacing(1)};
    `
);

const StyledButton = styled(Button)(
    ({ theme }) => `
        padding: ${theme.spacing(0.5, 1)};
    `
);

export const Copy: React.FC<CopyProps> = ({
    value,
    label,
    tooltip = 'Copy to clipboard',
    copiedTooltip = 'Copied to clipboard',
}) => {
    const [copied, setCopied] = useState<boolean>(false);

    const doCopy = (): void => {
        const content = typeof value === 'string' ? value : value?.current?.textContent ?? undefined;

        if (content !== undefined) {
            copy(content);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    };

    return value !== undefined ? (
        <Tooltip title={copied ? copiedTooltip : tooltip}>
            <StyledCopy>
                <StyledButton color="primary" variant="text" onClick={doCopy}>
                    {label}
                </StyledButton>
            </StyledCopy>
        </Tooltip>
    ) : null;
};
