import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { DialogAlert } from '../dialog';
import { Button } from '../common/buttons/Button';
import Box from '@mui/material/Box';
import { Tooltip, styled, useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { IconCircle } from '../icon-circle';
import { InfoIcon } from '../icons/InfoIcon';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { FullLengthDivider, ProfileUpdateContent, ProfileUpdateTitle } from './OnboardingComponents';

export interface ProfileUpdateDialogProps {
    open: boolean;
    onSkip: () => void;
    onUpdate: () => void;
    hasSkipped: boolean;
}

const StyledBottomContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const ItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > div:first-of-type': {
        height: 40,
        width: 40,
        marginRight: theme.spacing(1.5),
    },
}));

export const ProfileUpdateDialog: React.FC<ProfileUpdateDialogProps> = ({
    open = false,
    hasSkipped = false,
    onSkip,
    onUpdate,
}) => {
    const theme = useTheme();
    const items = [
        {
            icon: <WorkOutlineOutlinedIcon />,
            title: 'What is your profession?',
            description: 'Please describe your role to help us improve the functionality of the app',
        },
        {
            icon: <FunctionsOutlinedIcon />,
            title: 'How well do you know JavaScript?',
            description: 'Assess your knowledge to determine the right support and information',
        },
        {
            icon: <AppsOutlinedIcon />,
            title: 'What apps do you use?',
            description: 'Discover which apps you use so we can suggest improvements for you',
        },
        {
            icon: <FlagOutlinedIcon />,
            title: 'What is your main goal?',
            description: 'Share your focus to get better support that matches your intentions',
        },
    ];

    return (
        <Dialog open={open}>
            <ProfileUpdateTitle
                icon={<BadgeOutlinedIcon />}
                subtitle="Please answer a few questions to help us improve our service"
                title="Tell us about yourself"
                color={theme.palette.text.primary}
            />
            <DialogContent sx={{ margin: theme.spacing(2, 0) }}>
                <DialogAlert severity="warning" alertTitle="Profile update is mandatory - you can skip it only once" />
                <ProfileUpdateContent items={items} title="What information do we collect?*" />
                <StyledBottomContainer mt={2}>
                    <Typography variant="subtitle1" mb={2}>
                        How long will it take?
                    </Typography>
                    <ItemContainer>
                        <IconCircle icon={<TimerOutlinedIcon />} color={theme.palette.text.primary} />
                        <Typography variant="subtitle2" fontWeight="normal">
                            The entire process will take approximately <b>60 seconds</b>
                        </Typography>
                    </ItemContainer>
                    <Typography variant="body2" color="text.secondary">
                        *By securely collecting user data, we gain valuable insights to improve our services, tailor
                        recommendations and provide a more personalised and seamless experience.
                    </Typography>
                </StyledBottomContainer>
            </DialogContent>
            <FullLengthDivider />
            <DialogActions>
                <Tooltip
                    title="You can only skip profile update once"
                    placement="top"
                    sx={{
                        marginRight: theme.spacing(1),
                    }}
                >
                    <InfoIcon />
                </Tooltip>
                <Button variant="outlined" onClick={onSkip} disabled={hasSkipped}>
                    Skip ({hasSkipped ? 1 : 0}/1)
                </Button>
                <Button onClick={onUpdate}>Update Profile</Button>
            </DialogActions>
        </Dialog>
    );
};
