import { useObservableState, useSubscription } from 'observable-hooks';
import { AuditLogsPage } from '../components/reporting/audit-logs';
import {
    auditLogs$,
    auditLogsPageErrors$,
    auditLogsPageLoading$,
    getMyAuditLogsAction$,
    getMyAuditLogsUrlAction$,
    auditLogsPageGeneratingCsv$,
} from '../store/auditLogs';
import { getBasePath } from '../utils/path';
import { organizationSwitchedAction$, selectedOrganizationUid$ } from '../store/organization';
import { useNavigate } from 'react-location';
import { featureFlagsTopic$ } from '../store/config';

export const AuditLogsContainer: React.FC = () => {
    const navigate = useNavigate();
    const auditLogs = useObservableState(auditLogs$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const auditLogsPageError = useObservableState(auditLogsPageErrors$);
    const loading = useObservableState(auditLogsPageLoading$);
    const generating = useObservableState(auditLogsPageGeneratingCsv$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const basePath = getBasePath();

    if (!featureFlags.showAuditLogsReportingPage) {
        return null;
    }

    const handleExportCsv = (): void => {
        if (selectedOrganizationUid) {
            getMyAuditLogsUrlAction$.next({
                organizationUid: selectedOrganizationUid,
            });
        }
    };

    useSubscription(organizationSwitchedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}auditLogs` }), 100);
    });

    return (
        <AuditLogsPage
            errors={auditLogsPageError}
            isLoading={loading}
            auditLogs={auditLogs.auditLogs}
            cursor={auditLogs.cursor}
            selectedTeamUid={selectedOrganizationUid}
            basePath={basePath}
            isGenerating={generating}
            onQueryAuditLogs={(request) => getMyAuditLogsAction$.next(request)}
            onExportCsv={handleExportCsv}
        />
    );
};
