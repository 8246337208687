import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../common/buttons/Button';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../utils/handleKeyDown';

interface AtlassianOnPremiseEventListenerSetupDialogProps {
    connectionType?: 'Jira' | 'Confluence' | 'Jira Service Management';
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const AtlassianOnPremiseEventListenerSetupDialog: React.FC<AtlassianOnPremiseEventListenerSetupDialogProps> = ({
    connectionType,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {connectionType} On-Premise
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup a webhook in your {connectionType} On-Premise instance in order to
                                listen to events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {setupBaseUrl && (
                                    <li>
                                        Visit your{' '}
                                        <Link target="_blank" href={`${setupBaseUrl}/plugins/servlet/webhooks`}>
                                            WebHooks
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!setupBaseUrl && (
                                    <li>
                                        Open https://[YOUR{connectionType ? `_${connectionType.toUpperCase()}` : ''}
                                        _INSTANCE]/plugins/servlet/webhooks URL in your browser
                                    </li>
                                )}
                                <li>
                                    Click <strong>Create a WebHook</strong>.
                                </li>
                                <li>
                                    Enter a meaningful name into the <strong>Name</strong> field.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>URL</strong> field.
                                </li>
                                {eventTypeName !== 'Issue Transitioned' ? (
                                    <>
                                        <li>
                                            Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                                        </li>
                                        <li>
                                            Then click <strong>Create</strong>.
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            Then click <strong>Create</strong>.
                                        </li>
                                        {setupBaseUrl && (
                                            <li>
                                                Visit your{' '}
                                                <Link
                                                    target="_blank"
                                                    href={`${setupBaseUrl}/secure/admin/workflows/ListWorkflows.jspa`}
                                                >
                                                    Workflows
                                                </Link>{' '}
                                                page.
                                            </li>
                                        )}
                                        {!setupBaseUrl && (
                                            <li>
                                                Open https://[YOUR
                                                {connectionType ? `_${connectionType.toUpperCase()}` : ''}
                                                _INSTANCE]/secure/admin/workflows/ListWorkflows.jspa URL in your browser
                                            </li>
                                        )}
                                        <li>
                                            Click <strong>Edit</strong> next to the relevant workflow.
                                        </li>
                                        <li>
                                            In the Workflow Designer, select the transition and click{' '}
                                            <strong>Post functions</strong> in the properties panel.
                                        </li>
                                        <li>
                                            Click <strong>Add post function</strong>, select{' '}
                                            <strong>Trigger a Webhook</strong> and click <strong>Add</strong>.
                                        </li>
                                        <li>
                                            Select the created webhook and click <strong>Add</strong>.
                                        </li>
                                        <li>
                                            Repeat steps <strong>9 through 11</strong> for each transition you want to
                                            get an event.
                                        </li>
                                        <li>
                                            Click <strong>Publish</strong> to publish the workflow.
                                        </li>
                                    </>
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
