import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ZenDeskIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="productIcon"
            aria-label="ZenDesk"
            viewBox="0 0 300 280"
        >
            <path
                fill="currentColor"
                d="M130 88.6v174.2H0zM130 32.5c0 39.9-32.8 72.3-72.3 72.3S0 72.4 0 32.5h130zm21.7 238.8c0-39.9 32.8-72.3 72.3-72.3s72.3 32.8 72.3 72.3H151.7zm0-62.4V32.5h130z"
            />
        </SvgIcon>
    );
};
