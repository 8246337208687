import Divider from '@mui/material/Divider';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Button } from '../common/buttons/Button';
import {
    DialogBadgeContainerOverhauled,
    DialogContentBoxOverhauled,
    DialogContentOverhauled,
    DialogContentTextOverhauled,
    DialogOverhauled,
    DialogTitleLargeOverhauled,
} from '../dialog/DialogOverhaul';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { useTheme } from '@mui/material';

interface MondayUserFailureDialogProps {
    open?: boolean;
    onClose: () => void;
}

export const MondayUserFailureDialog: React.FC<MondayUserFailureDialogProps> = ({ open = false, onClose }) => {
    const theme = useTheme();

    return (
        <DialogOverhauled
            className="centered"
            open={open}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
        >
            <DialogBadgeContainerOverhauled color={theme.palette.error.main} icon={<WarningOutlinedIcon />} centered />
            <DialogTitleLargeOverhauled title="Something went wrong..." />
            <DialogContentTextOverhauled sx={{ mb: 3 }}>
                An error has occured while setting up your connector
            </DialogContentTextOverhauled>
            <DialogContentOverhauled className="centered">
                <DialogContentBoxOverhauled className="centered" width={300}>
                    <DialogContentTextOverhauled
                        variant="body2"
                        fontWeight="bold"
                        sx={(theme) => ({ textTransform: 'uppercase', color: theme.palette.text.secondary })}
                    >
                        Alternative option
                    </DialogContentTextOverhauled>
                    <DialogContentTextOverhauled sx={{ mb: 2 }}>
                        Setup your connector manually
                    </DialogContentTextOverhauled>
                    <Divider sx={{ mb: 2 }} />
                    <DialogContentTextOverhauled sx={{ mb: 2 }}>
                        Proceed to the connectors screen and manually configure your connector
                    </DialogContentTextOverhauled>
                    <Button onClick={onClose}>Configure manually</Button>
                </DialogContentBoxOverhauled>
            </DialogContentOverhauled>
        </DialogOverhauled>
    );
};
