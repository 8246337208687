interface ModalKeyUpOptions {
    event: React.KeyboardEvent<HTMLElement>;
    enterFn?: () => void;
    escFn?: () => void;
    enterCondition?: boolean;
    escCondition?: boolean;
}

export const handleKeyDown = ({
    event,
    enterFn,
    escFn,
    enterCondition = true,
    escCondition = true,
}: ModalKeyUpOptions): void => {
    if (event.key === 'Enter' && enterCondition) {
        enterFn?.();
    } else if (event.key === 'Escape' && escCondition) {
        escFn?.();
    }
};
