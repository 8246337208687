import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../dialog/DialogComponents';
import { Dropdown } from '../../../common/dropdown/Dropdown';
import {
    Choice,
    EnvironmentVariableChoiceDetails,
    UpdateEnvironmentVariableChoiceEvent,
} from '../EnvironmentVariableChoiceDetails';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeChoicesContainer,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledEditModeRequiredButton,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyValueField,
    StyledReadOnlyListItem,
    StyledReadOnlyMultipleValuesField,
    StyledReadOnlyRequiredChip,
    StyledReadOnlyOptionalChip,
    StyledReadOnlyMissingInformationChip,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableChoice, EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';

interface UpdateSingleChoiceVariableEvent {
    choices?: EnvironmentVariableChoice[];
    defaultValue?: string;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: string;
}

interface EnvironmentVariableSingleChoiceVariantProps {
    choices?: EnvironmentVariableChoice[];
    defaultValue?: string;
    description?: string;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    required?: boolean;
    sameLevelKeyNames?: string[];
    templateMode?: boolean;
    value?: string;
    workspaceLocked?: boolean;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateSingleChoiceVariableEvent): void;
}

const getRemappedChoices = (choices: EnvironmentVariableChoice[], value?: string, defaultValue?: string): Choice[] => {
    return choices.map((c) => ({
        isDefault: c.value ? defaultValue === c.value : c.label ? defaultValue === c.label : false,
        label: c.label,
        selected: c.value ? value === c.value : c.label ? value === c.label : false,
        uid: c.uid,
        type: 'SINGLE_CHOICE',
        value: c.value,
    }));
};

export const EnvironmentVariableSingleChoiceVariant: React.FC<EnvironmentVariableSingleChoiceVariantProps> = ({
    choices = [],
    defaultValue = '',
    description = '',
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    required = false,
    sameLevelKeyNames = [],
    templateMode = false,
    value = '',
    workspaceLocked = false,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);
    const [currentChoices, setCurrentChoices] = useState(getRemappedChoices(choices, value, defaultValue));

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleAddChoice = (): void => {
        const updatedChoiceList = [...currentChoices, { editMode: true, type: 'SINGLE_CHOICE' as const }];
        setCurrentChoices(updatedChoiceList);
    };

    const handleDeleteChoice = (index: number): void => {
        const updatedChoiceList = currentChoices.filter((_, ix) => ix !== index);
        setCurrentChoices(updatedChoiceList);
    };

    const handleSelectChoice = (index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) =>
            ix === index ? { ...c, selected: true } : { ...c, selected: false }
        );
        setCurrentChoices(updatedChoiceList);
    };

    const handleUpdateChoice = (event: UpdateEnvironmentVariableChoiceEvent, index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) =>
            ix === index
                ? {
                      ...c,
                      editMode: false,
                      hasBeenEdited: true,
                      isDefault: event.isDefault,
                      label: event.label,
                      value: event.value,
                  }
                : { ...c, isDefault: event.isDefault ? false : c.isDefault }
        );
        setCurrentChoices(updatedChoiceList);
    };

    const handleToggleChoiceEditMode = (editMode: boolean, index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) => (ix === index ? { ...c, editMode } : c));
        setCurrentChoices(updatedChoiceList);
    };

    const handleUpdateVariable = (): void => {
        onUpdate?.({
            choices: currentChoices.map((c) => ({ label: c.label ?? '', value: c.value })),
            defaultValue:
                (currentChoices.find((c) => c.isDefault)?.value || currentChoices.find((c) => c.isDefault)?.label) ??
                '',
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'SINGLE_CHOICE',
            value:
                (currentChoices.find((c) => c.selected)?.value || currentChoices.find((c) => c.selected)?.label) ?? '',
        });
    };

    const hasChanged =
        JSON.stringify(getRemappedChoices(choices, value, defaultValue)) !== JSON.stringify(currentChoices) ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const areCurrentRequiredChoicesMissing = currentRequired && !currentChoices.length;
    const isCurrentRequiredValueSelectionMissing = currentRequired && !currentChoices.some((c) => c.selected);
    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isRequiredValueMissing = required && !value;
    const areRequiredChoicesMissing = required && !choices.length;
    const hasEmptyChoices = choices.some((c) => !c.label);
    const missingInformation = !keyName || isRequiredValueMissing || areRequiredChoicesMissing || hasEmptyChoices;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const displayedValue = templateMode ? defaultValue : value;

    const readOnlyChoicesList = choices?.length
        ? choices.map((c) => <StyledReadOnlyListItem label={c.value || c.label || emptyWarning(true)} />)
        : emptyWarning(true);

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdateVariable,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit variable' : 'New variable'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    exclusive
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                    aria-label={required ? 'Required' : 'Optional'}
                                >
                                    <StyledEditModeRequiredButton selected={!currentRequired} value={!currentRequired}>
                                        Optional
                                    </StyledEditModeRequiredButton>
                                    <StyledEditModeRequiredButton selected={currentRequired} value={currentRequired}>
                                        Required
                                    </StyledEditModeRequiredButton>
                                </ToggleButtonGroup>
                                <IconButton onClick={() => handleCancel()}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited}
                                inputRef={typeInputRef}
                                label="Type"
                                selectedItem={'SINGLE_CHOICE'}
                                required
                                size="small"
                                items={environmentVariableTypes}
                                onSelect={(v) => onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })}
                            />
                            <TextField
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <div>
                                {areCurrentRequiredChoicesMissing ? (
                                    <DialogAlert
                                        sx={{ marginBottom: 1.5 }}
                                        severity="warning"
                                        text="Please add at least one choice"
                                    />
                                ) : isCurrentRequiredValueSelectionMissing ? (
                                    <DialogAlert sx={{ margin: 0 }} severity="warning" text="Please select a choice" />
                                ) : undefined}
                                {!!currentChoices.length && (
                                    <StyledEditModeChoicesContainer>
                                        {currentChoices.map((c, i) => (
                                            <EnvironmentVariableChoiceDetails
                                                key={'choice' + i}
                                                editMode={c.editMode}
                                                existingValues={currentChoices
                                                    .filter((_, ix) => i !== ix)
                                                    .map((ev) => (ev.value || ev.label) ?? '')}
                                                isDefault={c.isDefault}
                                                label={c.label}
                                                selected={c.selected}
                                                type={'SINGLE_CHOICE'}
                                                value={c.value}
                                                onDelete={() => handleDeleteChoice(i)}
                                                onSelect={() => handleSelectChoice(i)}
                                                onToggleEditMode={(mode) => handleToggleChoiceEditMode(mode, i)}
                                                onUpdate={(event) => handleUpdateChoice(event, i)}
                                            />
                                        ))}
                                    </StyledEditModeChoicesContainer>
                                )}
                                <Button startIcon={<AddIcon />} variant="text" onClick={handleAddChoice}>
                                    Add choice
                                </Button>
                            </div>
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdateVariable}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={workspaceLocked || templateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => onDelete?.({ id, parentId })}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <StyledReadOnlyMissingInformationChip label="Missing information" />
                                )}
                                {!templateMode ? (
                                    required ? (
                                        <StyledReadOnlyRequiredChip label="Required" />
                                    ) : (
                                        <StyledReadOnlyOptionalChip label="Optional" />
                                    )
                                ) : null}
                                <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                                    <StyledReadOnlyExpandButton
                                        onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                    >
                                        <Typography>Show all</Typography>
                                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </StyledReadOnlyExpandButton>
                                </Tooltip>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {displayedValue ? (
                                    <Typography>{displayedValue}</Typography>
                                ) : (
                                    emptyWarning(!templateMode && required)
                                )}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Single choice</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Choices</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyMultipleValuesField>
                                        {readOnlyChoicesList}
                                    </StyledReadOnlyMultipleValuesField>
                                </StyledReadOnlyRow>
                                {!templateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyValueField>
                                            {defaultValue ? <Typography>{defaultValue}</Typography> : emptyWarning()}
                                        </StyledReadOnlyValueField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
