import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '../../common/buttons/Button';
import { CenteredLoadingSpinner } from '../../loading/CenteredLoadingSpinner';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { Dropdown } from '../../common/dropdown/Dropdown';
import { EventListenerSelect } from './EventListenerSelect';
import { InfoIcon } from '../../icons/InfoIcon';
import { PageContainer } from '../../layout';
import { APP } from '@avst-stitch/repository-lib/constants';
import { generate } from 'short-uuid';
import { pascalCase } from 'pascal-case';
import { WorkspaceEnvironments } from '../../../data/workspace';
import Checkbox from '@mui/material/Checkbox';

export interface SaveEventListenerEvent {
    connectionUid?: string;
    eventTypeUid?: string;
    scriptUid?: string; // When existing script option was selected
    scriptName?: string; // When new script was creation option was selected
    uid: string;
    urlId?: string;
    disabled?: boolean;
}

interface EventListenerScreenProps {
    connectionRequired: boolean;
    connections: {
        value: string;
        name: string;
        authorized?: boolean;
        icon: JSX.Element | undefined;
    }[];
    createdConnectionUid?: string;
    errors?: string;
    eventTypes: {
        uid: string;
        name: string;
        category?: string | null; // Can be empty!
    }[];
    existingEvent?: boolean;
    hasImplicitlySharedConnectionAttached?: boolean;
    hasSetupInstructions: boolean;
    loading?: boolean; // Show center spinner when loading
    remnantEnvironments: {
        environmentName: string;
        deploymentVersion: string;
    }[];
    saving?: boolean; // Show spinner in save button and block it from emitting events
    scripts: {
        uid: string;
        name: string;
    }[];
    selectedAppName: string;
    selectedConnectionUid?: string;
    selectedEventTypeUid?: string;
    selectedScriptUid?: string;
    selectedEnvironment?: WorkspaceEnvironments[number];
    uid: string; // Unique ID
    urlId?: string;
    warnings?: string[];
    wizardWarning?: boolean;
    workspaceLocked?: boolean;
    disabled?: boolean;
    onCancel(): void;
    onNewConnection(): void;
    onSave(event: SaveEventListenerEvent): void;
    onViewSetupInstructions(): void;
    onWizardWarningClose(): void;
}

const StyledContainer = styled('div')(({ theme }) => ({
    maxWidth: 340,
    paddingTop: theme.spacing(1),
}));

const StyledFlexWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const StyledFlexWrapperRow = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'row',
    justifyContent: 'start',
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

const StyledLinkWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledRadioContainer = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    padding: theme.spacing(2, 0, 1, 1.5),
    '& .MuiTypography-root': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiCheckbox-root': {
        padding: 0,
        width: 18,
        height: 18,
    },
}));

export const EventListenerDetails: React.FC<EventListenerScreenProps> = ({
    connectionRequired,
    connections,
    createdConnectionUid,
    errors,
    eventTypes,
    existingEvent,
    hasImplicitlySharedConnectionAttached,
    hasSetupInstructions,
    loading = false,
    remnantEnvironments,
    saving = false,
    scripts,
    selectedAppName,
    selectedConnectionUid,
    selectedEventTypeUid,
    selectedScriptUid,
    selectedEnvironment,
    uid,
    urlId,
    warnings = [],
    wizardWarning = false,
    workspaceLocked = false,
    disabled = false,
    onCancel,
    onNewConnection,
    onSave,
    onViewSetupInstructions,
    onWizardWarningClose,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentEventType, setCurrentEventType] = useState(selectedEventTypeUid);
    const [triggerScriptOption, setTriggerScriptOption] = useState(selectedScriptUid ? 'saved' : 'initial');
    const [currentScript, setCurrentScript] = useState(selectedScriptUid);
    const [currentConnection, setCurrentConnection] = useState(createdConnectionUid ?? selectedConnectionUid);
    const [newScriptName, setNewScriptName] = useState('');
    const [urlPath, setUrlPath] = useState(urlId || generate());
    const [showUrlPathWarning, setShowUrlPathWarning] = useState(false);
    const [currentDisabled, setCurrentDisabled] = useState(disabled);

    useEffect(() => {
        if (currentEventType) {
            const name = eventTypes.find((x) => x.uid === currentEventType)?.name;
            const modifiedAppName = selectedAppName === APP.MONDAY.NAME ? 'Monday' : selectedAppName;
            setNewScriptName(pascalCase(`on${modifiedAppName}${name}`));
        }
    }, [currentEventType, triggerScriptOption]);

    useEffect(() => {
        setCurrentConnection(createdConnectionUid);
    }, [createdConnectionUid]);

    useEffect(() => {
        setCurrentConnection(selectedConnectionUid);
    }, [selectedConnectionUid]);

    useEffect(() => {
        setShowUrlPathWarning(false);
    }, []);

    const handleSave = (): void => {
        const data: SaveEventListenerEvent = {
            uid,
        };
        if (currentEventType) {
            data.eventTypeUid = currentEventType;
        }
        if (currentScript) {
            data.scriptUid = currentScript;
        }
        if (newScriptName) {
            data.scriptName = newScriptName;
        }
        if (currentConnection) {
            data.connectionUid = currentConnection;
        }
        if (selectedAppName === APP.GENERIC.NAME && urlPath) {
            data.urlId = urlPath;
        }
        if (currentDisabled !== disabled) {
            data.disabled = currentDisabled;
        }
        onSave(data);
    };

    const resetScriptName = (): void => {
        setNewScriptName('');
    };

    const resetExistingScript = (): void => {
        setCurrentScript('');
    };

    const remnantEnvironment = remnantEnvironments
        .map(({ environmentName, deploymentVersion }) => `${environmentName} (${deploymentVersion})`)
        .join(', ');
    const requiresSetup = !!selectedScriptUid && !hasSetupInstructions;

    const foundConnection = connections.find((con) => con.value === currentConnection);
    const isConnectionUnauthorized = !!foundConnection && !foundConnection.authorized;

    const selectedEventTypeName = eventTypes.find((et) => et.uid === currentEventType)?.name;

    const selectedEventTypeCategory = eventTypes.find((et) => et.uid === currentEventType)?.category;

    const hasScript = triggerScriptOption === 'saved' ? !!currentScript : !!newScriptName;
    const hasRequiredConnection = connectionRequired ? !!currentConnection : true;
    const hasUnsavedChanges =
        currentEventType !== selectedEventTypeUid ||
        currentScript !== selectedScriptUid ||
        currentConnection !== selectedConnectionUid ||
        urlPath !== urlId ||
        !hasSetupInstructions ||
        currentDisabled !== disabled;

    const canSave =
        !!currentEventType && hasScript && hasRequiredConnection && !workspaceLocked && !saving && hasUnsavedChanges;

    const connectionTooltip = connectionRequired
        ? 'A Connector is required to listen to events originating from external services.'
        : 'To which Connector this Event Listener logically belongs to. This is not required for the Event Listener to function properly, but will enable to you to logically attach an Event Listener to a Connection.';

    const showSetupInstructions =
        hasSetupInstructions && selectedEventTypeUid && currentEventType === selectedEventTypeUid;
    const environmentDeployed = selectedEnvironment?.deployment !== undefined;
    const USES_CONNECTION_TOOLTIP = connections && connections.find((c) => c.value === currentConnection)?.name;

    return (
        <>
            {loading ? (
                <CenteredLoadingSpinner />
            ) : (
                <PageContainer
                    data-test-id="event-listener-details"
                    sx={{ minWidth: 500, backgroundColor: 'background.paper', p: 0 }}
                >
                    <DialogTitleMain title="Edit Event Listener" />
                    {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                    {warnings.map((w) => {
                        return <DialogAlert severity="warning" alertTitle="Warning" text={w} />;
                    })}
                    {isConnectionUnauthorized && connectionRequired && (
                        <DialogAlert
                            severity="warning"
                            alertTitle="Warning"
                            text="Selected Connector is not authorized."
                        />
                    )}
                    {selectedEventTypeUid &&
                        !!selectedConnectionUid &&
                        selectedConnectionUid !== currentConnection &&
                        environmentDeployed && (
                            <DialogAlert
                                severity="warning"
                                alertTitle="Warning"
                                text="Changing the Connector will take effect immediately after saving"
                            />
                        )}
                    {showUrlPathWarning && (
                        <DialogAlert
                            severity="warning"
                            alertTitle="Security Warning"
                            text="Changing the URL path to be more predicable makes it easier to be guessed. Make sure to secure your HTTP Event Listener with authentication if you don't intend it to be anonymously accessible."
                        />
                    )}
                    {selectedEventTypeUid && selectedEventTypeUid !== currentEventType && (
                        <>
                            <DialogAlert
                                severity="warning"
                                alertTitle="Warning"
                                text="Test event payloads need to be re-configured because the event type changed."
                            />
                            <DialogAlert
                                severity="warning"
                                alertTitle="Warning"
                                text="You might need to setup the webhook again because the event type changed."
                            />
                        </>
                    )}
                    {triggerScriptOption === 'saved' && !selectedScriptUid && (
                        <DialogAlert
                            alertTitle="Warning"
                            severity="warning"
                            text="Selecting existing script might result in selecting a script that is using different
                                event type that you're listening to, which you may need to fix manually."
                        />
                    )}
                    {requiresSetup && (
                        <DialogAlert
                            severity="info"
                            alertTitle="Information"
                            text="You haven't completed the Event Listener setup. Click on 'Save' to receive instructions how to
                                complete the setup."
                        />
                    )}
                    {remnantEnvironments.length > 0 && (
                        <DialogAlert
                            severity="info"
                            alertTitle="Information"
                            text={`This is a remnant Event Listener, which means that it has been deleted but is still active in the following Environments: ${remnantEnvironment}. You can continue editing the Connector mapping for this remnant Event Listener.`}
                        />
                    )}
                    {selectedAppName === APP.GENERIC.NAME && selectedEventTypeName === 'Async HTTP Event' && (
                        <DialogAlert
                            alertTitle="Information"
                            severity="info"
                            text={
                                <>
                                    Asynchronous HTTP Events gets processed in fire and forget manner, meaning that you
                                    won't be able to send back a HTTP response, but invocations can run for much longer
                                    than synchronous events. <strong>This is the recommended option.</strong>
                                </>
                            }
                        />
                    )}
                    {selectedAppName === APP.GENERIC.NAME && selectedEventTypeName === 'Sync HTTP Event' && (
                        <DialogAlert
                            alertTitle="Information"
                            severity="info"
                            text={
                                <>
                                    Synchronous HTTP Events allow you to send back a HTTP response after function
                                    invocation has finished,{' '}
                                    <strong>but the maximum invocation time will be capped to 25 seconds.</strong>{' '}
                                    Consider using Async HTTP Event if you don't need to send back a response.
                                </>
                            }
                        />
                    )}
                    {selectedAppName === APP.SLACK.NAME &&
                        selectedEventTypeCategory === 'Interactive Component' &&
                        existingEvent && (
                            <DialogAlert
                                alertTitle="Warning"
                                severity="warning"
                                closeable={true}
                                text={
                                    <>
                                        Slack only allows a single interactive event webhook to be active at any given
                                        time. An Event Listener is already setup to listen to Interactive Component
                                        events. You do not need to create a new Event Listener to listen to Interactive
                                        Component events but you can proceed if you wish to do so.
                                    </>
                                }
                            />
                        )}
                    {wizardWarning && (
                        <DialogAlert
                            severity="info"
                            onClose={() => onWizardWarningClose()}
                            alertTitle="Information"
                            text="Please configure and save your Event Listener to complete the step."
                        />
                    )}
                    {hasImplicitlySharedConnectionAttached && (
                        <DialogAlert
                            severity="info"
                            alertTitle="Information"
                            text={`This Event Listener is currently using implicitly shared connector owned by someone else, if you change it, you won't be able to change it back on your own.`}
                        />
                    )}
                    <StyledContainer>
                        {remnantEnvironments.length === 0 && (
                            <StyledFlexWrapper>
                                <FormControl sx={{ m: 0 }}>
                                    <EventListenerSelect
                                        disabled={workspaceLocked || environmentDeployed}
                                        labelName="Listener Event Type"
                                        optionList={eventTypes}
                                        selectedOption={currentEventType}
                                        handleSelect={setCurrentEventType}
                                    />
                                </FormControl>
                                {!selectedScriptUid && (
                                    <StyledRadioContainer>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={triggerScriptOption}
                                            onChange={(e) => setTriggerScriptOption(e.target.value)}
                                        >
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    sx={{ mr: 1 }}
                                                    disabled={workspaceLocked}
                                                    value="initial"
                                                    control={<Radio />}
                                                    label="Create new Script (recommended)"
                                                    onClick={resetExistingScript}
                                                />
                                                <Tooltip
                                                    title="A new Script with the correct Event Type will be generated for this
                                                    Event Listener."
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    sx={{ mr: 1 }}
                                                    disabled={workspaceLocked || !scripts.length}
                                                    value="saved"
                                                    control={<Radio />}
                                                    label="Use existing Script"
                                                    onClick={resetScriptName}
                                                />
                                                <Tooltip title=" Enable the Event Listener to trigger existing Script.">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </Box>
                                        </RadioGroup>
                                    </StyledRadioContainer>
                                )}
                                <FormControl sx={{ mb: 0 }}>
                                    {triggerScriptOption === 'initial' && !selectedScriptUid && (
                                        <TextField
                                            required
                                            disabled={workspaceLocked}
                                            variant="outlined"
                                            label="New Script name"
                                            placeholder="Enter a name"
                                            onChange={(e) => setNewScriptName(e.target.value)}
                                            value={newScriptName}
                                        />
                                    )}
                                    {triggerScriptOption === 'saved' && (
                                        <Dropdown
                                            required
                                            disabled={workspaceLocked || environmentDeployed}
                                            items={scripts.map((s) => ({ value: s.uid, name: s.name }))}
                                            label="Use existing Script"
                                            onSelect={setCurrentScript}
                                            selectedItem={currentScript}
                                        />
                                    )}
                                </FormControl>
                            </StyledFlexWrapper>
                        )}
                        <FormControl sx={{ m: 0 }}>
                            {selectedAppName !== APP.GENERIC.NAME ? (
                                <>
                                    <StyledFlexWrapperRow>
                                        <Tooltip title={USES_CONNECTION_TOOLTIP} placement="top">
                                            <FormControl sx={{ m: 0 }}>
                                                <Dropdown
                                                    required={connectionRequired}
                                                    disabled={workspaceLocked}
                                                    label="Uses connector"
                                                    items={connections}
                                                    onSelect={setCurrentConnection}
                                                    selectedItem={currentConnection}
                                                    onCreateNew={onNewConnection}
                                                />
                                            </FormControl>
                                        </Tooltip>
                                        <Tooltip title={connectionTooltip} placement="top">
                                            <StyledInfoIcon aria-label="info" />
                                        </Tooltip>
                                    </StyledFlexWrapperRow>
                                    {!connectionRequired && (
                                        <FormHelperText sx={{ ml: 0 }}>Not required, but recommended.</FormHelperText>
                                    )}
                                </>
                            ) : (
                                <StyledFlexWrapperRow>
                                    <TextField
                                        required
                                        disabled={workspaceLocked}
                                        variant="outlined"
                                        label="URL Path"
                                        placeholder="Enter a path"
                                        onChange={(e) => {
                                            setUrlPath(e.target.value);
                                            setShowUrlPathWarning(true);
                                        }}
                                        value={urlPath}
                                    />
                                    <Tooltip
                                        title={
                                            'URL Path is a unique identifier that makes up the URL for the Event Listener.'
                                        }
                                        placement="top"
                                    >
                                        <StyledInfoIcon aria-label="info" />
                                    </Tooltip>
                                </StyledFlexWrapperRow>
                            )}
                        </FormControl>
                        <StyledFormControlLabel
                            control={
                                <Checkbox
                                    checked={currentDisabled}
                                    onChange={() => setCurrentDisabled((prev) => !prev)}
                                />
                            }
                            label="Disabled"
                        />
                        <StyledLinkWrapper>
                            {showSetupInstructions && (
                                <Link onClick={onViewSetupInstructions} underline="always">
                                    View setup instructions
                                </Link>
                            )}
                        </StyledLinkWrapper>
                        <DialogActions>
                            <Button onClick={onCancel} variant="outlined" disabled={workspaceLocked}>
                                Cancel
                            </Button>
                            <Button busy={saving} disabled={!canSave} color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </DialogActions>
                    </StyledContainer>
                </PageContainer>
            )}
        </>
    );
};
