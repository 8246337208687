import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../dialog/DialogComponents';
import { Language } from '../../store/editor/types';
import { LanguageSelectorCard } from './language-selector-card';
import { OpenInNewLink } from '../common/OpenInNewLink';
import { IconCircle } from '../icon-circle';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface LanguageSelectorProps {
    errors?: string;
    loading?: boolean;
    open?: boolean;
    saving?: boolean;
    selectedLanguage: Language;
    onApply(language: Language): void;
    onCancel(): void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: theme.palette.background.default,
        margin: 0,
        minWidth: 783,
        position: 'relative',
    },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(3),
    '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'flex-end',
    },
    '& .MuiAlert-action': {
        margin: 0,
        paddingLeft: theme.spacing(0.5),
    },
}));

const StyledCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(0.25),
    '& .MuiCard-root:nth-of-type(2)': {
        margin: theme.spacing(0, 3),
    },
}));

const JS = 'js';
const TS = 'ts';
const TS_STRICT = 'ts-strict';

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
    errors,
    open = false,
    saving = false,
    selectedLanguage,
    onCancel,
    onApply,
}) => {
    const [language, setLanguage] = useState(selectedLanguage);
    const theme = useTheme();
    useEffect(() => setLanguage(selectedLanguage), [open]);

    const Link = <OpenInNewLink url="https://docs.adaptavist.com/src/scripting">Learn More</OpenInNewLink>;
    const hasChanged = language !== selectedLanguage;

    return (
        <>
            <StyledDialog
                open={open}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: hasChanged && !saving,
                        enterFn: () => onApply(language),
                        escFn: onCancel,
                    })
                }
            >
                <DialogTitleMain
                    title="Switch Scripting Language"
                    icon={<IconCircle icon={<CodeRoundedIcon />} background={theme.palette.background.paper} />}
                />
                {errors && <DialogAlert alertTitle="Error" severity="error" text={errors} />}
                <DialogContent sx={{ mb: 1 }}>
                    <StyledCardContainer>
                        <LanguageSelectorCard
                            icon={process.env.PUBLIC_URL + '/js.png'}
                            title="JavaScript"
                            value={JS}
                            checked={language === JS}
                            onSelect={() => setLanguage(JS)}
                        />
                        <LanguageSelectorCard
                            icon={process.env.PUBLIC_URL + '/ts.png'}
                            title="TypeScript"
                            value={TS}
                            checked={language === TS}
                            onSelect={() => setLanguage(TS)}
                        />
                        <LanguageSelectorCard
                            icon={process.env.PUBLIC_URL + '/ts.png'}
                            title="TypeScript - Strict"
                            value={TS_STRICT}
                            checked={language === TS_STRICT}
                            onSelect={() => setLanguage(TS_STRICT)}
                        />
                    </StyledCardContainer>
                    {language === JS && (
                        <StyledAlert
                            severity="warning"
                            text="It's strongly recommended to use TypeScript or TypeScript Strict."
                            action={Link}
                        />
                    )}
                    {language === TS && (
                        <StyledAlert
                            severity="info"
                            action={Link}
                            text="Using TypeScript Strict can lead to fewer bugs."
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} variant="outlined" data-hotspot="cancel">
                        Cancel
                    </Button>
                    <Button
                        busy={saving}
                        data-hotspot="apply"
                        onClick={() => onApply(language)}
                        disabled={saving || !hasChanged}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
};
