import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Banner } from '../common/alerts/Banner';
import { getUserDisplayName } from '../../utils/user';
import { Button } from '../common/buttons/Button';

export interface AppBannersProps {
    isConnectedToFeedback?: boolean;
    impersonating?: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    notificationBanner?: {
        message: string;
        level: 'info' | 'warning' | 'error';
    };
    showRefreshSessionWarning?: boolean;
    sessionExpired?: boolean;
    onSetLoadTokens(loadTokens: boolean): void;
    onStopImpersonation?(): void;
}

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
}));

const StyledBannerText = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize,
}));

export const AppBanners: React.FC<AppBannersProps> = ({
    isConnectedToFeedback,
    impersonating,
    notificationBanner,
    showRefreshSessionWarning,
    onStopImpersonation,
    onSetLoadTokens,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={containerRef}>
            {isConnectedToFeedback === false && (
                <Banner
                    severity="warning"
                    alertTitle="Warning"
                    text="Connection to feedback service has been interrupted, you will not receive proper feedback from the app. Please try refreshing the browser, if the issue persists please contact support."
                />
            )}
            {impersonating && (
                <Banner
                    severity="warning"
                    alertTitle="Impersonation Warning"
                    text={`You are impersonating: ${getUserDisplayName(impersonating)} (${impersonating.email})`}
                    action={
                        <StyledButton variant="contained" onClick={onStopImpersonation}>
                            Stop Impersonating
                        </StyledButton>
                    }
                />
            )}
            {notificationBanner && <Banner severity={notificationBanner.level} text={notificationBanner.message} />}
            {showRefreshSessionWarning && (
                <Banner
                    severity="warning"
                    alertTitle="Warning"
                    action={
                        <StyledButton
                            variant="contained"
                            onClick={() => {
                                onSetLoadTokens(true);
                            }}
                        >
                            REFRESH SESSION
                        </StyledButton>
                    }
                    text={
                        <StyledBannerText>
                            {'Your session has expired and cannot be refreshed.' +
                                ' Please check your network connection and ensure you are online' +
                                ' then click on REFRESH SESSION. If the issue persists, please contact support.'}
                        </StyledBannerText>
                    }
                />
            )}
        </div>
    );
};
