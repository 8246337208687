/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { SidenavOrganizationSelector } from './SideNavOrganizationSelector';
import {
    SidenavOrganizationSettingsNavigator,
    SidenavOrganizationSettingsSubRoute,
} from './SidenavOrganizationSettingsNavigator';
import { SidenavThemeModeSelector } from './SidenavThemeModeSelector';
import { SidenavUserSettingsNavigator } from './SidenavUserSettingsNavigator';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { StyledListItemButton } from './SidenavComponents';
import { UserNameDetails } from '../../utils/types';
import { ThemeMode } from '../../theme';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';
import { SidenavReportingNavigator } from './SidenavReportingNavigator';

export type SidenavPath =
    | 'Home'
    | 'Connectors'
    | 'Workspaces'
    | 'Reporting'
    | 'Templates'
    | 'Upgrade'
    | 'Organizations';

interface SidenavProps {
    helpAndSupportOpen?: boolean;
    isLogOutBusy?: boolean;
    organizations?: SidenavOrganization[];
    selectedOrganizationUid?: string;
    selectedPath: string;
    showUpgradePlan?: boolean;
    showAuditLogsReportingPage?: boolean;
    themeMode: ThemeMode;
    userCredentials: UserNameDetails;
    onChangeThemeMode(mode: ThemeMode): void;
    onCreateNewOrganization(): void;
    onLogOut(): void;
    onManageAllOrganizations(): void;
    onNavigateHome(refresh?: boolean): void;
    onNavigateToBillingDetails(): void;
    onNavigateToConnectors(): void;
    onNavigateToOrganizationSettings(subRoute: SidenavOrganizationSettingsSubRoute): void;
    onNavigateToProfileSettings(): void;
    onNavigateToScriptInvocationLogs(): void;
    onNavigateToAuditLogs(): void;
    onNavigateToTemplates(): void;
    onNavigateToUpgradePlan(): void;
    onNavigateToWorkspaces(): void;
    onOpenHelpAndSupport(): void; // Design indicates navigation to a page, but existing solution is a modal- further changes have to be coordinated with the Monday team
    onSelectOrganization(uid: string): void;
}

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{ collapsed: boolean }>(
    ({ collapsed, theme }) => ({
        '& .MuiPaper-root': {
            position: 'relative',
            transition: theme.transitions.create(['width', 'justify-content'], {
                easing: theme.transitions.easing.sharp,
                duration: 200,
            }),
            width: collapsed ? 57 : 211,
        },
    })
);

const StyledDrawerHeader = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
}));

const StyledDrawerHeaderTop = styled('div', { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed, theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 42,
    justifyContent: collapsed ? 'center' : 'space-between',
    marginBottom: theme.spacing(2),
    '&>div': collapsed
        ? {
              display: 'none',
          }
        : undefined,
}));

const StyledAppName = styled(ButtonBase)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    textAlign: 'start',
    '& .MuiTypography-root': {
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '19.2px', // In order to get the text to the app icon size
    },
    '& .MuiSvgIcon-root': {
        height: 42,
        width: 42,
    },
}));

const StyledCollapseButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed, theme }) => ({
    '&.MuiButtonBase-root': {
        // Remove once icon button size is changed in the theme
        height: 36,
        width: 36,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 20,
        transform: collapsed ? undefined : 'rotate(180deg)',
        width: 20,
    },
}));

const StyledDrawerMain = styled('div')(() => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
}));

const StyledList = styled(List)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    height: 28,
    lineHeight: 1,
    padding: theme.spacing(1),
    textTransform: 'uppercase',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(0),
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(0.5),
    },
}));

export const Sidenav: React.FC<SidenavProps> = ({
    helpAndSupportOpen = false,
    isLogOutBusy,
    organizations,
    selectedOrganizationUid,
    selectedPath,
    showUpgradePlan,
    showAuditLogsReportingPage,
    themeMode,
    userCredentials,
    onChangeThemeMode,
    onCreateNewOrganization,
    onLogOut,
    onManageAllOrganizations,
    onNavigateHome,
    onNavigateToBillingDetails,
    onNavigateToConnectors,
    onNavigateToProfileSettings,
    onNavigateToScriptInvocationLogs,
    onNavigateToAuditLogs,
    onNavigateToOrganizationSettings,
    onNavigateToTemplates,
    onNavigateToUpgradePlan,
    onNavigateToWorkspaces,
    onOpenHelpAndSupport,
    onSelectOrganization,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const selectedOrganization = organizations?.find((org) => org.uid === selectedOrganizationUid);

    return (
        <StyledDrawer collapsed={collapsed} variant="permanent">
            <StyledDrawerHeader>
                <StyledDrawerHeaderTop collapsed={collapsed}>
                    {!collapsed && (
                        <StyledAppName onClick={() => onNavigateHome(true)}>
                            <SRConnectIcon />
                            <Typography>
                                ScriptRunner
                                <br />
                                Connect
                            </Typography>
                        </StyledAppName>
                    )}
                    <Tooltip title={collapsed ? 'Expand' : 'Collapse'}>
                        <StyledCollapseButton
                            aria-label={collapsed ? 'Expand side navigation' : 'Collapse side navigation'}
                            onClick={() => setCollapsed(!collapsed)}
                            collapsed={collapsed}
                        >
                            <KeyboardTabOutlinedIcon />
                        </StyledCollapseButton>
                    </Tooltip>
                </StyledDrawerHeaderTop>
                <SidenavOrganizationSelector
                    collapsed={collapsed}
                    organizations={organizations}
                    selectedOrganizationUid={selectedOrganizationUid}
                    onCreateNewOrganization={onCreateNewOrganization}
                    onManageAllOrganizations={onManageAllOrganizations}
                    onSelectOrganization={onSelectOrganization}
                />
            </StyledDrawerHeader>
            <StyledDrawerMain>
                <StyledList>
                    <StyledListItem>
                        <StyledListItemButton
                            onClick={() => onNavigateHome()}
                            collapsed={collapsed}
                            selected={selectedPath === 'dashboard'}
                        >
                            <Tooltip title="Home">
                                <CottageOutlinedIcon />
                            </Tooltip>
                            <Typography>Home</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListSubheader>{collapsed ? '' : 'Integrate'}</StyledListSubheader>
                    <StyledListItem>
                        <StyledListItemButton
                            onClick={onNavigateToConnectors}
                            collapsed={collapsed}
                            selected={selectedPath === 'connectors'}
                        >
                            <Tooltip title="Connectors">
                                <PowerOutlinedIcon />
                            </Tooltip>
                            <Typography>Connectors</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListItemButton
                            onClick={onNavigateToWorkspaces}
                            collapsed={collapsed}
                            selected={selectedPath === 'workspaces'}
                        >
                            <Tooltip title="Workspaces">
                                <Inventory2OutlinedIcon />
                            </Tooltip>
                            <Typography>Workspaces</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    {showAuditLogsReportingPage ? (
                        <StyledListItem>
                            <SidenavReportingNavigator
                                collapsed={collapsed}
                                isSelected={selectedPath === 'reporting' || selectedPath === 'auditLogs'}
                                onNavigateToScriptInvocationLogs={onNavigateToScriptInvocationLogs}
                                onNavigateToAuditLogs={onNavigateToAuditLogs}
                            />
                        </StyledListItem>
                    ) : (
                        <StyledListItem>
                            <StyledListItemButton
                                onClick={onNavigateToScriptInvocationLogs}
                                collapsed={collapsed}
                                selected={selectedPath === 'reporting'}
                            >
                                <Tooltip title="Reporting">
                                    <TroubleshootOutlinedIcon />
                                </Tooltip>
                                <Typography>Reporting</Typography>
                            </StyledListItemButton>
                        </StyledListItem>
                    )}
                    <StyledListSubheader>{collapsed ? '' : 'Explore'}</StyledListSubheader>
                    <StyledListItem>
                        <StyledListItemButton
                            onClick={onNavigateToTemplates}
                            collapsed={collapsed}
                            selected={selectedPath === 'templates'}
                        >
                            <Tooltip title="Templates">
                                <LocalLibraryOutlinedIcon />
                            </Tooltip>
                            <Typography>Templates</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                </StyledList>
                <StyledList>
                    <StyledListItem>
                        <StyledListItemButton
                            onClick={onOpenHelpAndSupport}
                            collapsed={collapsed}
                            selected={helpAndSupportOpen}
                        >
                            <Tooltip title="Help & support">
                                <HelpOutlineOutlinedIcon />
                            </Tooltip>
                            <Typography>Help & support</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListSubheader>{collapsed ? '' : 'Settings'}</StyledListSubheader>
                    {showUpgradePlan && (
                        <StyledListItem>
                            <StyledListItemButton
                                onClick={onNavigateToUpgradePlan}
                                collapsed={collapsed}
                                selected={false}
                            >
                                <Tooltip title="Upgrade plan">
                                    <ArrowCircleUpOutlinedIcon />
                                </Tooltip>
                                <Typography>Upgrade plan</Typography>
                            </StyledListItemButton>
                        </StyledListItem>
                    )}
                    {selectedOrganization && (
                        <StyledListItem>
                            <SidenavOrganizationSettingsNavigator
                                collapsed={collapsed}
                                isSelected={selectedPath === 'team'}
                                organizationName={selectedOrganization.name}
                                ownedDefaultOrganization={selectedOrganization.ownedDefaultOrganization}
                                onNavigateToOrganizationSettings={(subRoute) =>
                                    onNavigateToOrganizationSettings(subRoute)
                                }
                            />
                        </StyledListItem>
                    )}
                    <StyledListItem>
                        <SidenavThemeModeSelector
                            collapsed={collapsed}
                            selectedThemeMode={themeMode}
                            onSelectThemeMode={onChangeThemeMode}
                        />
                    </StyledListItem>
                </StyledList>
            </StyledDrawerMain>
            <SidenavUserSettingsNavigator
                collapsed={collapsed}
                isLogOutBusy={isLogOutBusy}
                userCredentials={userCredentials}
                onLogOut={onLogOut}
                onNavigateToBillingDetails={onNavigateToBillingDetails}
                onNavigateToProfileSettings={onNavigateToProfileSettings}
            />
        </StyledDrawer>
    );
};
