import { forwardRef } from 'react';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

export interface ButtonProps extends MuiButtonProps {
    /**
     * Show the Busy state.
     */
    readonly busy?: boolean;
    /**
     * Text for hover tooltip.
     */
    readonly tooltip?: string;
}

const StyledBusy = styled(CircularProgress)({
    position: 'absolute',
});

const Button_: React.ForwardRefRenderFunction<never, ButtonProps> = (
    {
        busy,
        disabled,
        variant = 'contained', // We'll default to 'contained'
        tooltip,
        children,
        ...props
    },
    ref
) => {
    const button = (
        <MuiButton {...props} ref={ref} variant={variant} disabled={busy || disabled}>
            {children}
            {busy && <StyledBusy size={24} role="progressbar" id="busy" title="Busy" />}
        </MuiButton>
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{button}</Tooltip>;
    } else {
        return button;
    }
};

export const Button = forwardRef(Button_);
