import {
    getMyDetails as getMyDetailsRpc,
    updateMyDetails as updateMyDetailsRpc,
    getIndustryRoles as getIndustryRolesRpc,
    acceptOrganizationMemberInvite as acceptOrganizationMemberInviteRpc,
    startImpersonation as startImpersonationRpc,
    stopImpersonation as stopImpersonationRpc,
    addUserDeleteDate as addUserDeleteDateRpc,
    logout,
} from '@avst-stitch/repository-lib/lib';
import { cancelUserDelete as cancelUserDeleteRpc } from '@avst-stitch/repository-lib/lib/rpcs/cancelUserDelete';
import { Response as OrganizationDetails } from '@avst-stitch/repository-lib/lib/rpcs/acceptOrganizationMemberInvite';
import { Role } from '../store/roles';
import { loggedInUserDetails$, UserUpdatePayload } from '../store/user';
import { PermissionError, repositoryInvoker, InformativeError } from '../utils/repository';
import { sleep } from '../utils/sleep';
import { segmentAnalyticsIdentify } from './segment-analytics';
import { getAuth0UserDetails } from '../utils/auth0';
import { UserDetails } from '../hooks/auth';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';
import { selectOrganizationAndInitPendoAction$ } from '../store/organization';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';

export const selectedOrganizationUidKey = 'stitchSelectedOrganizationUid';

const selectOrganization = (userUid: string, organizations: SidenavOrganization[]): void => {
    let selectedOrganizationUid = readLocalStorage<string | undefined>(selectedOrganizationUidKey, '');

    const selectedOrganization = organizations.find((org) => org.uid === selectedOrganizationUid);

    if (!selectedOrganizationUid || !selectedOrganization) {
        const defaultOrganization = organizations.find((org) => org.ownedDefaultOrganization);
        selectedOrganizationUid = defaultOrganization?.uid;
        saveLocalStorage(selectedOrganizationUidKey, defaultOrganization?.uid);
    }

    selectOrganizationAndInitPendoAction$.next({
        userUid,
        orgUid: selectedOrganizationUid,
        orgDetails: selectedOrganization
            ? {
                  teamName: selectedOrganization?.name,
                  planUid: selectedOrganization?.planUid,
                  planName: selectedOrganization?.planName,
                  planPeriod: selectedOrganization?.planPeriod,
                  planStatus: selectedOrganization?.planStatus,
              }
            : undefined,
    });
};

export const getLoggedInUserDetailsAndInitialize = async (): Promise<UserDetails> => {
    // Polling for 10 seconds, because user creation is async and can take few seconds to trigger user creation in our side from Auth0
    for (let i = 0; i < 20; i++) {
        try {
            const details = await getMyDetailsRpc(repositoryInvoker);
            const auth0Details = await getAuth0UserDetails();
            segmentAnalyticsIdentify(details.uid, {
                stitchTeamMember: details.stitchTeamMember,
                userOrigin: details.userOrigin,
                idp: details.idp ?? [],
            });
            selectOrganization(details.uid, details.organizations);

            return {
                ...details,
                ...auth0Details,
            };
        } catch (e) {
            if (e instanceof PermissionError) {
                await sleep(500);
            } else {
                console.error('Error whilst fetching details ', e);
                throw e;
            }
        }
    }

    throw Error('Fetching user details timed out');
};

export const updateUserDetails = async (updateData: UserUpdatePayload): Promise<string | void> => {
    try {
        await updateMyDetailsRpc(repositoryInvoker, updateData);
    } catch (err) {
        if (err instanceof InformativeError) {
            return err.message;
        }
        throw err;
    }
};

export const getIndustryRoles = async (): Promise<Role[]> => {
    return await getIndustryRolesRpc(repositoryInvoker);
};

export const acceptOrganizationMemberInvite = async (inviteId: string): Promise<OrganizationDetails> => {
    return await acceptOrganizationMemberInviteRpc(repositoryInvoker, {
        inviteId,
    });
};

export const startImpersonation = async (impersonateeUserUid: string, password: string): Promise<void> => {
    return await startImpersonationRpc(repositoryInvoker, {
        impersonateeUserUid,
        password,
    });
};

export const stopImpersonation = async (): Promise<void> => {
    return await stopImpersonationRpc(repositoryInvoker);
};

export const logoutFromApp = async (): Promise<void> => {
    return await logout(repositoryInvoker);
};

export const addUserDeleteDate = async (): Promise<void> => {
    return await addUserDeleteDateRpc(repositoryInvoker, {});
};

export const cancelUserDelete = async (): Promise<void> => {
    return await cancelUserDeleteRpc(repositoryInvoker);
};

export const loadLoggedInUserDetails = async (): Promise<void> => {
    const previousDetails = loggedInUserDetails$.value;
    const details = await getMyDetailsRpc(repositoryInvoker);

    loggedInUserDetails$.next({ ...previousDetails, ...details });
    selectOrganization(details.uid, details.organizations);
};
