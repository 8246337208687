import { BehaviorSubject, debounceTime, Subject } from 'rxjs';
import { monitor } from '../monitor';
import { ColumnDropdownProps, LogLevel } from '../../components/workspace/console/ConsoleHeader';

export const consoleFullScreen$ = monitor('consoleFullScreen$', new BehaviorSubject(false));
export const searchLogQuery$ = monitor('searchLogQuery$', new BehaviorSubject<string | undefined>(undefined));

export const searchLogAction$ = monitor('searchLogAction$', new Subject<string | undefined>());
export const changeConsoleFullScreenAction$ = monitor('changeConsoleFullScreenAction', new Subject<boolean>());

export const logLevels$ = monitor(
    'logLevels$',
    new BehaviorSubject<LogLevel[]>([
        { name: 'Debug', checked: false, level: 'Debug' },
        { name: 'Information', checked: false, level: 'Info' },
        { name: 'Warning', checked: false, level: 'Warn' },
        { name: 'Error', checked: false, level: 'Error' },
        { name: 'Success', checked: false, level: 'Success' },
    ])
);

export const columns$ = monitor(
    'columns$',
    new BehaviorSubject<ColumnDropdownProps[]>([
        { name: 'Date', checked: true },
        { name: 'Time', checked: true },
        { name: 'Source', checked: true },
        { name: 'Environment Name', checked: true },
        { name: 'Invocation ID', checked: true },
    ])
);

changeConsoleFullScreenAction$.subscribe((isFullScreen) => consoleFullScreen$.next(isFullScreen));

searchLogAction$.pipe(debounceTime(500)).subscribe((query) => searchLogQuery$.next(query));
