import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../common/buttons/Button';
import { SalesforceIcon } from '../../icons/SalesforceIcon';
import { ConnectionModalTextField, ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { SalesforceWizardStage, SalesforceWizardSteps, StageType } from './SalesforceWizardSteps';
import Link from '@mui/material/Link';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { testUrl } from '../../../utils/connection';
import { OpenInNewLink } from '../../common/OpenInNewLink';

export interface SalesforceClientInfo {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface SalesforceConnection {
    clientInfo: SalesforceClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: SalesforceWizardStage;
    setStage: (stage: SalesforceWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [testingUrl, setTestingUrl] = useState<boolean>(false);

    const SALESFORCE_CONNECTED_APP = '/lightning/setup/NavigationMenus/home';

    const generateSettingsUrl = (): string => {
        const lightningUrl = instanceUrl.replace('.my.salesforce.com', '.lightning.force.com');
        return lightningUrl.endsWith(SALESFORCE_CONNECTED_APP)
            ? lightningUrl
            : lightningUrl.endsWith('/')
            ? lightningUrl.substring(0, lightningUrl.length - 1) + SALESFORCE_CONNECTED_APP
            : lightningUrl + SALESFORCE_CONNECTED_APP;
    };

    switch (props.stage) {
        case SalesforceWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            The Salesforce instance url must match the following format:
                            <br /> {'https://<Your-instance-name>.my.salesforce.com'}
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Enter the Salesforce URL"
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                setTestingUrl(true);
                                if (await testUrl(instanceUrl)) {
                                    if (!instanceUrl.match(/http(s)?:\/\/.+\.my\.salesforce\.com/)) {
                                        props.setError(
                                            'Please enter a valid URL that match the following format: https://<Your-instance-name>.my.salesforce.com'
                                        );
                                        setBadUrlAttempted(true);
                                    } else {
                                        setInstanceUrl(instanceUrl);
                                        props.setStage(SalesforceWizardStage.CREATE);
                                        setBadUrlAttempted(false);
                                    }
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                                setTestingUrl(false);
                            }}
                            busy={testingUrl}
                            disabled={!instanceUrl || badUrlAttempted}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case SalesforceWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have a connected app, skip the steps below and click next."
                    />
                    <DialogAlert
                        severity="warning"
                        text="Creation of the app might take up to 10 minutes, please make sure to wait before proceeding."
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl()}>Salesforce App Manager</OpenInNewLink> page.
                                Lightning view requires third party cookies to be enabled.
                            </li>
                            <li>
                                Click <strong>New Connected App</strong> button in the top right corner of the page.
                            </li>
                            <li>
                                Copy the name below into <strong>Connected App Name</strong> and leave API name as
                                suggested.
                            </li>
                            <li>
                                In <strong>API (Enable OAuth Settings)</strong> section check{' '}
                                <strong>Enable OAuth Settings</strong>.
                            </li>
                            <li>Copy the callback url below into the form.</li>
                            <li>
                                Add the following scopes in <strong>Selected OAuth Scopes</strong> section:{' '}
                                <strong>Full access(full)</strong>, <strong>Manage user data via APIs (api)</strong>,{' '}
                                <strong>Perform requests at any time (refresh_token, offline_access)</strong>.
                            </li>
                            <li>
                                Ensure that <strong>only</strong> the following options are selected:{' '}
                                <strong>Require Secret for Web Server Flow</strong>,{' '}
                                <strong>Require Secret for Refresh Token Flow</strong>,{' '}
                                <strong>Enable Client Credentials Flow</strong>.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Callback URL" value={props.callbackUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(SalesforceWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(SalesforceWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case SalesforceWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                In <strong>API (Enable OAuth Settings)</strong> section click{' '}
                                <strong>Manage Consumer Details</strong>.
                            </li>
                            <li>
                                You're sometimes prompted to verify your identity before you can view the consumer key.
                            </li>
                            <li>
                                Copy the <strong>Consumer key</strong> into the form below.
                            </li>
                            <li>
                                Copy the <strong>Consumer secret</strong> into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Consumer Key"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Consumer Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(SalesforceWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(SalesforceWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case SalesforceWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Salesforce you need to authorize our app to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(SalesforceWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case SalesforceWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const SalesforceConnectionAppConfigureDialog: React.FC<SalesforceConnection> = (props) => {
    const alertError = props.errors?.includes('firewall') ? (
        <>
            {props.errors}{' '}
            <Link
                target="_blank"
                href={'https://docs.adaptavist.com/src/get-started/connect-to-services-behind-the-firewall'}
            >
                Read more about connecting to services behind the firewall.
            </Link>{' '}
        </>
    ) : (
        props.errors
    );
    const error = <DialogAlert severity="error" alertTitle="Error" text={alertError} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<SalesforceIcon />} />
            {props.errors && error}

            <SalesforceWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
