import { styled } from '@mui/material';
import { Outlet } from 'react-location';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { OrgAvatarContainer } from '../../common/Avatars';
import { OrganizationTab } from '../../../containers/organization/OrganizationContainer';
import { PageContainer, PageTitle } from '../../layout';
import { StyledImageBox, StyledImageTextBox, StyledOrgDetailsContainer } from '../OrganizationComponents';

interface OrganizationDetailsProps {
    avatar?: string;
    canManagePlans: boolean; // If false, hide Plans tab
    canManageBillingDetails: boolean; // If false, hide Billing Details tab
    name: string; // Org name that you need to display above the tabs
    onSelectTab: (tab: OrganizationTab) => void;
    selectedTab: OrganizationTab;
    useRouter?: boolean;
}

const StyledTabList = styled(TabList)(({ theme }) => ({
    height: 26,
    minHeight: 26,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: 24,
    minHeight: 'unset',
    padding: 0,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTabPanel = styled('div')(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(4.25)})`,
    padding: theme.spacing(3, 0, 0, 0.5),
}));

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
    avatar,
    canManageBillingDetails,
    canManagePlans,
    name,
    onSelectTab,
    selectedTab = 'usage',
    useRouter = true,
}) => {
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <PageTitle title="Team" />
            <StyledOrgDetailsContainer>
                {avatar ? (
                    <StyledImageBox size={52}>
                        <img src={`data:image/jpeg;base64,${avatar}`} width={52} height={52} alt="" />
                    </StyledImageBox>
                ) : (
                    <OrgAvatarContainer name={name} style="square" />
                )}
                <StyledImageTextBox>
                    <Typography variant="subtitle1">{name}</Typography>
                </StyledImageTextBox>
            </StyledOrgDetailsContainer>
            <TabContext value={selectedTab}>
                <Box>
                    <StyledTabList onChange={(_event, value) => onSelectTab(value as OrganizationTab)}>
                        <StyledTab label="Plan Usage" value="usage" />
                        <StyledTab label="Team Details" value="details" />
                        <StyledTab label="Member Management" value="members" />
                        {canManagePlans && <StyledTab label="Plan Management" value="plan" />}
                        {canManageBillingDetails && <StyledTab label="Billing Details" value="billing" />}
                    </StyledTabList>
                    <StyledTabPanel>{useRouter && <Outlet />}</StyledTabPanel>
                </Box>
            </TabContext>
        </PageContainer>
    );
};
