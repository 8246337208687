import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { Dropdown } from '../../../common/dropdown/Dropdown';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyValueField,
    StyledReadOnlyMissingInformationChip,
    StyledEditModeDivider,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';

interface UpdateBooleanVariableEvent {
    defaultValue: boolean;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    type: EnvironmentVariableType;
    value: boolean;
}

interface EnvironmentVariableBooleanVariantProps {
    defaultValue: boolean;
    description?: string;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    sameLevelKeyNames?: string[];
    templateMode?: boolean;
    value: boolean;
    workspaceLocked?: boolean;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateBooleanVariableEvent): void;
}

const StyledSwitchContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'space-between',
    minHeight: 40,
    paddingLeft: theme.spacing(1.5),
    width: '100%',
    '& .MuiButtonBase-root': {
        width: 'unset',
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
    },
}));

export const EnvironmentVariableBooleanVariant: React.FC<EnvironmentVariableBooleanVariantProps> = ({
    defaultValue = false,
    description = '',
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    sameLevelKeyNames = [],
    templateMode = false,
    value = false,
    workspaceLocked = false,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
    const [currentDescription, setCurrentDescription] = useState(description);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentValue(value);
        setCurrentDefaultValue(defaultValue);
        setCurrentDescription(description);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: currentDefaultValue,
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            type: 'BOOLEAN',
            value: currentValue,
        });
    };

    const hasChanged =
        currentValue !== value ||
        currentDefaultValue !== defaultValue ||
        currentDescription !== description ||
        currentKeyName !== keyName;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit variable' : 'New variable'}</Typography>
                            <StyledHeaderActions>
                                <IconButton onClick={() => handleCancel()}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                inputRef={typeInputRef}
                                disabled={hasBeenEdited}
                                label="Type"
                                selectedItem={'BOOLEAN'}
                                required
                                size="small"
                                items={environmentVariableTypes}
                                onSelect={(v) => onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })}
                            />
                            <TextField
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <StyledSwitchContainer>
                                <Typography>Value</Typography>
                                <FormControlLabel
                                    control={<Switch checked={currentValue as boolean} />}
                                    label={currentValue ? 'True' : 'False'}
                                    onChange={() => setCurrentValue(!currentValue)}
                                />
                            </StyledSwitchContainer>
                            <TextField
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <StyledEditModeDivider>
                                <Typography>Developer Options</Typography>
                            </StyledEditModeDivider>
                            <StyledSwitchContainer>
                                <Typography>Default value</Typography>
                                <FormControlLabel
                                    control={<Switch checked={currentDefaultValue as boolean} />}
                                    label={currentDefaultValue ? 'True' : 'False'}
                                    onChange={() => setCurrentDefaultValue(!currentDefaultValue)}
                                />
                            </StyledSwitchContainer>
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={workspaceLocked || templateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    disabled={workspaceLocked || templateMode}
                                    onClick={() => onDelete?.({ id, parentId })}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {!keyName && <StyledReadOnlyMissingInformationChip label="Missing information" />}
                                <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                                    <StyledReadOnlyExpandButton
                                        onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                    >
                                        <Typography>Show all</Typography>
                                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </StyledReadOnlyExpandButton>
                                </Tooltip>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Value</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                <Typography>{String(templateMode ? defaultValue : value)}</Typography>
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Boolean</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!templateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyValueField>
                                            <Typography>{String(defaultValue)}</Typography>
                                        </StyledReadOnlyValueField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
