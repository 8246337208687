import { useEffect, useState } from 'react';
import Cron, { HEADER } from 'react-cron-generator';
import cronstrue from 'cronstrue';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Dropdown } from '../../common/dropdown/Dropdown';
import { Button } from '../../common/buttons/Button';
import { CenteredLoadingSpinner } from '../../loading/CenteredLoadingSpinner';
import { PageContainer } from '../../layout';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { InfoIcon } from '../../icons/InfoIcon';
import {
    StyledCronGenerator,
    StyledCronWrapper,
    StyledDetails,
    StyledExpression,
    StyledScheduledDate,
    StyledScheduledDatesSection,
    StyledScheduledDatesTitle,
    StyledScheduledTriggerDetails,
    StyledSubtitle,
} from './ScheduledTriggerStyles';
import { scheduledTriggerDocumentationUrl } from '../../../utils/documentation';
import { WorkspaceEnvironments } from '../../../data/workspace';
import Checkbox from '@mui/material/Checkbox';
import { StyledFormControlLabel } from '../event-listener-details';
import Box from '@mui/material/Box';

interface ScheduledTriggerDetailsProps {
    actualLastScheduledDate?: Date;
    createdScriptUid?: string;
    cronExpression?: string;
    errors?: string;
    loading?: boolean;
    nextScheduledDates: Date[];
    remnantEnvironments: {
        environmentName: string;
        deploymentVersion: string;
    }[];
    savedScriptUid?: string;
    saving?: boolean;
    scripts: {
        uid: string;
        name: string;
    }[];
    uid: string;
    warnings?: string[];
    workspaceLocked?: boolean;
    selectedEnvironment?: WorkspaceEnvironments[number];
    disabled?: boolean;
    onCancel(): void;
    onCreateNewScript(): void;
    onSave(event: SaveScheduledTriggerEvent): void;
}

export interface SaveScheduledTriggerEvent {
    uid: string;
    cronExpression?: string;
    scriptUid?: string;
    disabled: boolean;
}

const defaultCronExpression = '0 0 00 1/1 * ?';

export const ScheduledTriggerDetails: React.FC<ScheduledTriggerDetailsProps> = ({
    actualLastScheduledDate,
    createdScriptUid,
    cronExpression,
    errors = '',
    loading = false,
    nextScheduledDates = [],
    remnantEnvironments = [],
    savedScriptUid,
    saving = false,
    scripts = [],
    uid,
    warnings = [],
    workspaceLocked = false,
    selectedEnvironment,
    disabled = false,
    onCancel,
    onCreateNewScript,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selectedScriptUid = createdScriptUid ?? savedScriptUid;
    const [generatorValue, setGeneratorValue] = useState(cronExpression || defaultCronExpression);
    const [cronError, setCronError] = useState(false);
    const [description, setDescription] = useState('');
    const [expression, setExpression] = useState(cronExpression || defaultCronExpression);
    const [currentlySelectedScriptUid, setCurrentlySelectedScriptUid] = useState(selectedScriptUid);
    const [isNewScheduledTrigger, setIsNewScheduledTrigger] = useState(false);
    const [currentDisabled, setCurrentDisabled] = useState(disabled);

    const options = {
        headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.MINUTES, HEADER.HOURLY, HEADER.DAILY],
    };

    const hasUnsavedChanges =
        expression !== cronExpression || currentlySelectedScriptUid !== savedScriptUid || currentDisabled !== disabled;

    const canSave = !saving && !cronError && hasUnsavedChanges && currentlySelectedScriptUid && !workspaceLocked;

    const remnantEnvironmentList = remnantEnvironments
        .map(({ environmentName, deploymentVersion }) => `${environmentName} (${deploymentVersion})`)
        .join(', ');

    useEffect(() => {
        setCurrentlySelectedScriptUid(selectedScriptUid);
    }, [selectedScriptUid]);

    useEffect(() => {
        if (cronExpression === 'NEW SCHEDULED TRIGGER') {
            setIsNewScheduledTrigger(true);
        } else {
            setIsNewScheduledTrigger(false);
        }
    });

    useEffect(() => {
        try {
            if (countSegments() === 5) {
                throw 'Error: 5 segment format is not supported';
            } else if (countSegments() === 7) {
                throw 'Error: 7 segment format is not supported';
            }
            const description = cronstrue.toString(expression);
            if (description.includes('undefined')) {
                throw 'Error: Invalid expression';
            }
            setDescription(description);
            setCronError(false);
        } catch (e) {
            setCronError(true);
            if (typeof e === 'string') {
                setDescription(e.replace('At least 5 parts are required', 'At least 6 parts are required'));
            } else {
                setDescription('Error: Unknown error occurred');
                setCronError(true);
                console.log(e);
            }
        }
    }, [expression]);

    const countSegments = (): number => expression.split(' ').filter((e) => e).length;
    const removeExtraSegment = (expr: string): string => {
        const tokens = expr.split(' ');
        return tokens.slice(0, tokens.length - 1).join(' ');
    };

    const dates = nextScheduledDates
        .map((sd, i) => {
            return (
                <StyledScheduledDate
                    key={i}
                    sx={(theme) => {
                        return i % 2 == 0
                            ? { backgroundColor: theme.palette.background.default }
                            : { backgroundColor: theme.palette.background.paper };
                    }}
                >
                    <ScheduleIcon />
                    <span className="chromatic-ignore">{sd.toLocaleString()}</span>
                </StyledScheduledDate>
            );
        })
        .filter((_, i) => i < 10);

    const displayedScripts = scripts.map((s) => {
        return {
            name: s.name,
            value: s.uid,
        };
    });

    const environmentDeployed = selectedEnvironment?.deployment !== undefined;

    return loading ? (
        <CenteredLoadingSpinner />
    ) : (
        <PageContainer sx={{ bgcolor: 'background.paper', minWidth: 500 }}>
            <DialogTitleMain
                title="Edit Scheduled Trigger"
                tooltipElement={
                    <Tooltip title="Learn more about Scheduled Triggers" placement="top">
                        <InfoIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={() => window.open(scheduledTriggerDocumentationUrl, '_blank')}
                        />
                    </Tooltip>
                }
            />

            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            {warnings.map((w, i) => {
                return <DialogAlert key={i} severity="warning" alertTitle="Warning" text={w} />;
            })}
            {!isNewScheduledTrigger && hasUnsavedChanges && !environmentDeployed && (
                <DialogAlert
                    severity="warning"
                    alertTitle="Warning"
                    text="Changing the schedule will take effect immediately after saving for the selected Environment."
                />
            )}
            {remnantEnvironments.length > 0 && (
                <DialogAlert
                    severity="info"
                    alertTitle="Information"
                    text={`This is a remnant Scheduled Trigger, which means that it has been deleted but is still active in the following Environments: ${remnantEnvironmentList}. You can continue editing the CRON expression for this remnant Scheduled Trigger.`}
                />
            )}
            <DialogAlert
                severity="info"
                alertTitle="Information"
                text="Schedules are calculated in UTC (GMT+0) time zone, Next Estimated Scheduled Dates and Last Scheduled Trigger Date are displayed in your local time zone."
            />
            <StyledScheduledTriggerDetails>
                <StyledCronGenerator>
                    <StyledSubtitle variant="subtitle2">
                        {workspaceLocked ? 'CRON Expression' : 'Generate CRON Expression'}
                    </StyledSubtitle>
                    {!workspaceLocked && (
                        <StyledCronWrapper className="cron-wrapper">
                            <Cron
                                options={options}
                                showResultText={false}
                                showResultCron={false}
                                onChange={
                                    workspaceLocked
                                        ? // eslint-disable-next-line @typescript-eslint/no-empty-function
                                          () => {}
                                        : (v) => {
                                              setGeneratorValue(v);
                                              setExpression(removeExtraSegment(v));
                                          }
                                }
                                value={generatorValue}
                            />
                        </StyledCronWrapper>
                    )}
                    <StyledDetails>
                        <TextField
                            disabled={workspaceLocked}
                            variant="outlined"
                            value={expression}
                            label="CRON Expression"
                            placeholder="Enter a CRON Expression"
                            required
                            onChange={(e) => {
                                setExpression(e.target.value);
                            }}
                        />
                        <StyledSubtitle variant="subtitle2">CRON Schedule</StyledSubtitle>
                        <StyledExpression
                            sx={(theme) => (cronError ? { color: theme.palette.error.main } : null)}
                            variant="subtitle1"
                        >
                            {description}
                        </StyledExpression>

                        {remnantEnvironments.length > 0 ? (
                            <Select
                                disabled
                                variant="outlined"
                                value={selectedScriptUid}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        height: 24,
                                        width: 24,
                                    },
                                }}
                            >
                                <MenuItem value={selectedScriptUid}>
                                    {scripts.find((s) => s.uid === selectedScriptUid)?.name}
                                </MenuItem>
                            </Select>
                        ) : (
                            <Dropdown
                                required
                                onCreateNew={onCreateNewScript}
                                disabled={workspaceLocked || environmentDeployed}
                                items={displayedScripts}
                                label="Run Script"
                                onSelect={(uid) => setCurrentlySelectedScriptUid(uid)}
                                selectedItem={currentlySelectedScriptUid}
                            />
                        )}
                        <Box>
                            <StyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentDisabled}
                                        onChange={() => setCurrentDisabled((prev) => !prev)}
                                    />
                                }
                                label="Disabled"
                            />
                        </Box>
                        <DialogActions>
                            <Button variant="outlined" onClick={onCancel} disabled={workspaceLocked}>
                                Cancel
                            </Button>
                            <Button
                                busy={saving}
                                disabled={!canSave}
                                onClick={() =>
                                    onSave({
                                        uid,
                                        cronExpression: expression,
                                        scriptUid: currentlySelectedScriptUid,
                                        disabled: currentDisabled,
                                    })
                                }
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </StyledDetails>
                </StyledCronGenerator>
                <div>
                    {!!nextScheduledDates.length && (
                        <StyledScheduledDatesSection>
                            <StyledScheduledDatesTitle variant="subtitle2">
                                Next Estimated Scheduled Dates
                            </StyledScheduledDatesTitle>
                            {dates}
                        </StyledScheduledDatesSection>
                    )}
                    {actualLastScheduledDate && (
                        <StyledScheduledDatesSection>
                            <StyledScheduledDatesTitle variant="subtitle2">
                                Last Scheduled Trigger Date:
                            </StyledScheduledDatesTitle>
                            <span className="chromatic-ignore">{actualLastScheduledDate.toLocaleString()}</span>
                        </StyledScheduledDatesSection>
                    )}
                </div>
            </StyledScheduledTriggerDetails>
        </PageContainer>
    );
};
