import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { loadErrorPage } from '../store/error';
import { invocationsData$, reportingError$ } from '../store/reporting';
import { selectedOrganizationUid$ } from '../store/organization';
import { getInvocations, InvocationsRequest } from '../data/reporting';

export const reportingLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        reportingError$.next(undefined);

        const invocationsData = await getInvocations({
            invocationId: routeMatch.search.invocationId,
            invocationIdComparator: routeMatch.search.invocationIdComparator,
            workspaces: routeMatch.search.workspaces,
            environment: routeMatch.search.environment,
            environmentComparator: routeMatch.search.environmentComparator,
            script: routeMatch.search.script,
            scriptComparator: routeMatch.search.scriptComparator,
            executionStatuses: routeMatch.search.executionStatuses,
            triggerTypes: routeMatch.search.triggerTypes,
            duration: routeMatch.search.duration,
            durationComparator: routeMatch.search.durationComparator,
            logCount: routeMatch.search.logCount,
            logCountComparator: routeMatch.search.logCountComparator,
            httpLogCount: routeMatch.search.httpLogCount,
            httpLogCountComparator: routeMatch.search.httpLogCountComparator,
            from: routeMatch.search.from,
            to: routeMatch.search.to,
            orderByField: routeMatch.search.orderByField,
            orderByDirection: routeMatch.search.orderByDirection,
            organizationUid: selectedOrganizationUid$.value,
        } as InvocationsRequest);

        invocationsData$.next(invocationsData);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Reporting',
            background: 'paper',
            genericMessage: 'Failed to load invocations.',
        });

        throw e;
    }

    return {};
};
