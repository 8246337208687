// General url-s
export const documentationUrl = 'https://docs.adaptavist.com/src';
export const slackCommunityUrl =
    'https://join.slack.com/t/srconnectcommunity/shared_invite/zt-26p3jxz3o-PnWFh9nBXz8m0o3zKaiMGQ';
export const supportPortalUrl = 'https://the-adaptavist-group-support.atlassian.net/servicedesk/customer/portal/20';
export const requestScriptingHelpUrl = 'https://avst-services.atlassian.net/servicedesk/customer/portal/35';
export const feedbackBoardUrl = 'https://scriptrunnerconnect.nolt.io/top';

//Youtube video url-s
export const youtubeChannelUrl = 'https://www.youtube.com/playlist?list=PLTfQ5lMxqu9q9E2qW-H3VvYoHy9mTNJU7';
export const youtubeEditorVideoUrl =
    'https://www.youtube.com/watch?v=orjEJbwGwpc&list=PLTfQ5lMxqu9q9E2qW-H3VvYoHy9mTNJU7';
export const setupGuideYoutubeVideoUrl =
    'https://www.youtube.com/watch?v=XYS-7gOBVXM&list=PLnsCytbU4bI7wiNj0nrHjJtU3U0IuBWjN';

// Feature-specific url-s
export const workingPrinciplesDocumentationUrl = 'https://docs.adaptavist.com/src/get-started/working-principle';
export const scriptingDocumentationUrl = 'https://docs.adaptavist.com/src/scripting';
export const runtimeDocumentationUrl = 'https://docs.adaptavist.com/src/scripting/runtime';
export const editorDocumentationUrl = 'https://docs.adaptavist.com/src/scripting/editor';
export const connectorDocumentationUrl = 'https://docs.adaptavist.com/src/connectors';
export const managedApiDocumentationUrl = 'https://docs.adaptavist.com/src/managed-apis';
export const apiConnectionDocumentationUrl = 'https://docs.adaptavist.com/src/workspaces/api-connections';
export const eventListenerDocumenationUrl = 'https://docs.adaptavist.com/src/workspaces/event-listeners';
export const deploymentsAndEnvironmentsDocumentationUrl =
    'https://docs.adaptavist.com/src/workspaces/deployments-and-environments';
export const packageManagerDocumentationUrl = 'https://docs.adaptavist.com/src/workspaces/package-manager';
export const scheduledTriggerDocumentationUrl = 'https://docs.adaptavist.com/src/workspaces/scheduled-triggers';
export const limitsAndQuotasDocumentationUrl = 'https://docs.adaptavist.com/src/latest/limits-and-quotas';
export const environmentVariablesDocumentationUrl =
    'https://docs.adaptavist.com/src/latest/workspaces/deployments-and-environments/environment-variables';
export const organizationsDocumentationUrl = 'https://docs.adaptavist.com/src/collaborate-with-teams';
export const genericConnectorDocumentationUrl = 'https://docs.adaptavist.com/src/latest/connectors/generic-connector';
export const genericEventDocumentationUrl =
    'https://docs.adaptavist.com/src/workspaces/event-listeners/generic-http-events';
export const httpLogsDocumentationUrl = 'https://docs.adaptavist.com/src/latest/reporting/http-logs';
export const recordStorageDocumentationUrl = 'https://docs.adaptavist.com/src/scripting/record-storage';
export const triggeringScriptsDocumentationUrl = 'https://docs.adaptavist.com/src/scripting/triggering-scripts';

// Advanced Automations url-s
export const mondayServiceManagementUrl =
    'https://the-adaptavist-group-support.atlassian.net/servicedesk/customer/portal/28/group/38/create/146';
export const mondayDocumentationUrl = 'https://docs.adaptavist.com/display/AA/Advanced+Automations+Home';
export const mondayConnectorDocumentationUrl = 'https://docs.adaptavist.com/aa/connectors';
export const mondayFeedbackBoardUrl = 'https://www.surveymonkey.co.uk/r/advanced-automations-feedback-1';
export const mondayBookDemoUrl = 'https://calendly.com/msala-aa/advanced-automations-demo-1';
export const mondayCodeExamplesUrl =
    'https://docs.adaptavist.com/display/public/AA/.GraphQL+Scripts+and+Equivalent+Managed+API+Scripts+vCurrent';
