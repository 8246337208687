import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../common/buttons/Button';
import { BitbucketIcon } from '../../icons/BitbucketIcon';
import { OpenInNewLink } from '../../common/OpenInNewLink';
import { ConnectionModalTextField, ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import {
    BitbucketOnPremiseWizardStage,
    BitbucketOnPremiseWizardSteps,
    StageType,
} from './BitbucketOnPremiseWizardSteps';
import Link from '@mui/material/Link';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { testUrl } from '../../../utils/connection';

export interface BitbucketOnPremiseClientInfo {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface BitbucketOnPremiseConnection {
    clientInfo: BitbucketOnPremiseClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: BitbucketOnPremiseWizardStage;
    setStage: (stage: BitbucketOnPremiseWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [testingUrl, setTestingUrl] = useState<boolean>(false);

    const BITBUCKET_ON_PREMISE_PATH = '/plugins/servlet/applinks/listApplicationLinks';

    const generateSettingsUrl = (): string => {
        return instanceUrl.endsWith(BITBUCKET_ON_PREMISE_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + BITBUCKET_ON_PREMISE_PATH
            : instanceUrl + BITBUCKET_ON_PREMISE_PATH;
    };

    switch (props.stage) {
        case BitbucketOnPremiseWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            This connector requires Bitbucket On-Premise instance url.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Enter the Bitbucket On-Premise URL"
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                setTestingUrl(true);
                                if (await testUrl(instanceUrl)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(BitbucketOnPremiseWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                                setTestingUrl(false);
                            }}
                            busy={testingUrl}
                            disabled={!instanceUrl || badUrlAttempted}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketOnPremiseWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application link, skip the steps below and click next."
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl()}>
                                    Bitbucket On-Premise OAuth application links page
                                </OpenInNewLink>{' '}
                                page, click <strong>Create link</strong> then check Application type:{' '}
                                <strong>External application</strong>, Direction: <strong>Incoming</strong>.
                            </li>
                            <li>
                                Click <strong>Continue</strong>.
                            </li>
                            <li>Copy the values below into the form.</li>
                            <li>
                                Select <strong>Write</strong> under <strong>System Admin</strong> and ignore other
                                permissions.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Redirect URL" value={props.callbackUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(BitbucketOnPremiseWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(BitbucketOnPremiseWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case BitbucketOnPremiseWizardStage.DETAILS:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={
                            <span>
                                If you already have an application link, go to{' '}
                                <Link href={generateSettingsUrl()} target="blank">
                                    Application links
                                </Link>{' '}
                                page, click options (...) to the right of your app link and click "View credentials".
                            </span>
                        }
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Copy the <strong>Client ID</strong> into the form below.
                            </li>
                            <li>
                                Copy the <strong>Client secret</strong> into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Client Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketOnPremiseWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketOnPremiseWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketOnPremiseWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Bitbucket On-Premise you need to authorize our app to be able to
                            make requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(BitbucketOnPremiseWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case BitbucketOnPremiseWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const BitbucketOnPremiseConnectionAppConfigureDialog: React.FC<BitbucketOnPremiseConnection> = (props) => {
    const alertError = props.errors?.includes('firewall') ? (
        <>
            {props.errors}{' '}
            <Link
                target="_blank"
                href={'https://docs.adaptavist.com/src/get-started/connect-to-services-behind-the-firewall'}
            >
                Read more about connecting to services behind the firewall.
            </Link>{' '}
        </>
    ) : (
        props.errors
    );
    const error = <DialogAlert severity="error" alertTitle="Error" text={alertError} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<BitbucketIcon />} />
            {props.errors && error}

            <BitbucketOnPremiseWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
