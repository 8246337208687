import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../common/buttons/Button';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../utils/handleKeyDown';

interface SlackEventListenerSetupDialogProps {
    eventTypeCategory: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const SlackEventListenerSetupDialog: React.FC<SlackEventListenerSetupDialogProps> = ({
    eventTypeCategory,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const isSlashCommand = eventTypeName === 'Slash Command';

    const isInteractiveComponentEvent = eventTypeCategory === 'Interactive Component';

    const isSlackEvent = !isSlashCommand && !isInteractiveComponentEvent;

    const eventPath = isSlashCommand
        ? '/slash-commands'
        : isInteractiveComponentEvent
        ? '/interactive-messages'
        : '/event-subscriptions';

    const eventType = isSlashCommand
        ? 'Slash Commands'
        : isInteractiveComponentEvent
        ? 'Interactivity & Shortcuts'
        : 'Event Subscriptions';

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for Slack
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <DialogContent>
                            <ReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            ></ReadOnlyTextField>

                            {isSlashCommand && (
                                <DialogContentText variant="subtitle2">
                                    You need to configure the slash command in your Slack app in order to listen to
                                    events in ScriptRunner Connect.
                                </DialogContentText>
                            )}

                            {isInteractiveComponentEvent && (
                                <DialogContentText variant="subtitle2">
                                    You need to enable interactivity in your Slack app in order to listen to interaction
                                    and shortcut events in ScriptRunner Connect.
                                </DialogContentText>
                            )}

                            {isSlackEvent && (
                                <DialogContentText variant="subtitle2">
                                    You need to enable event subscriptions in your Slack app in order to listen to Slack
                                    events in ScriptRunner Connect.
                                </DialogContentText>
                            )}

                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {setupBaseUrl && (
                                    <li>
                                        Visit the{' '}
                                        <Link target="_blank" href={`${setupBaseUrl}${eventPath}`}>
                                            {eventType}
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!setupBaseUrl && (
                                    <li>
                                        Open https://api.slack.com/apps/[YOUR_SLACK_APP_ID]{`${eventPath}`} URL in your
                                        browser
                                    </li>
                                )}

                                {isSlashCommand && (
                                    <>
                                        <li>
                                            Click Create <strong>New Command</strong> or edit an existing command if the
                                            slash command already exists.
                                        </li>
                                        <li>
                                            Enter a slash command beginning with <strong>/</strong> into the{' '}
                                            <strong>Command</strong> field.
                                        </li>
                                        <li>
                                            Paste the copied URL into the <strong>Request URL</strong> field.
                                        </li>
                                        <li>
                                            Enter a short description of the command in the{' '}
                                            <strong>Short Description</strong> field.
                                        </li>
                                        <li>
                                            Then click <strong>Save</strong>.
                                        </li>
                                    </>
                                )}
                                {isSlackEvent && (
                                    <>
                                        <li>
                                            Switch on the <strong>Enable Events</strong> toggle.
                                        </li>
                                        <li>
                                            Paste the copied URL into the <strong>Request URL</strong> field. Slack will
                                            now verify the URL. If it fails, click on retry. If it fails again, please
                                            contact support.
                                        </li>
                                        <li>
                                            Scroll down to <strong>Subscribe to bot events</strong>.
                                        </li>
                                        <li>
                                            Click on <strong>Add Bot User Event</strong>.
                                        </li>
                                        <li>
                                            Add the event:{' '}
                                            <strong>{eventTypeName.toLowerCase().replaceAll(' ', '_')}</strong>.
                                        </li>
                                        <li>
                                            Click on <strong>Save Changes</strong>.
                                        </li>
                                        <li>
                                            Re-install the app if you are prompted to do so. This will trigger Slack to
                                            add missing bot permissions to your Slack app
                                        </li>
                                    </>
                                )}
                                {isInteractiveComponentEvent && (
                                    <>
                                        <li>
                                            Switch on the <strong>Interactivity</strong> toggle.
                                        </li>
                                        <li>
                                            Paste the copied URL into the <strong>Request URL</strong> field. Slack will
                                            now verify the URL. If it fails, click on retry. If it fails again, please
                                            contact support.
                                        </li>
                                        <li>
                                            If you want to listen to your Slack apps's shortcut events then scroll down
                                            to <strong>Shortcuts</strong>. Click on <strong>Create New Shortcut</strong>{' '}
                                            and follow the Slack instructions.
                                        </li>
                                    </>
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
