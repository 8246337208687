import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { ZenDeskConnection } from './ZenDeskAppConfigureDialog';

export enum ZenDeskWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface ZenDeskWizardStepsProps {
    readonly stage: ZenDeskWizardStage;
}

export const ZenDeskWizardSteps: React.FC<ZenDeskWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={ZenDeskWizardStage.ADDURL}>
                <StepLabel>Add ZenDesk Instance URL</StepLabel>
            </Step>
            <Step key={ZenDeskWizardStage.CREATE}>
                <StepLabel>Create Application in ZenDesk</StepLabel>
            </Step>
            <Step key={ZenDeskWizardStage.DETAILS}>
                <StepLabel>Enter New Application Details</StepLabel>
            </Step>
            <Step key={ZenDeskWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    ZenDeskConnection & {
        stage: ZenDeskWizardStage;
        setStage: (stage: ZenDeskWizardStage) => void;
    }
>;
