import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { invocationsDataForReport$, invocationsReportError$ } from '../../store/report/invocations';
import { getInvocationsForReport, InvocationsReportRequest } from '../../data/report/invocations';

export const invocationsReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    invocationsReportError$.next(undefined);

    const invocationsData = await getInvocationsForReport({
        invocationId: routeMatch.search.invocationId,
        invocationIdComparator: routeMatch.search.invocationIdComparator,
        workspace: routeMatch.search.workspace,
        workspaceComparator: routeMatch.search.workspaceComparator,
        workspaceOwner: routeMatch.search.workspaceOwner,
        workspaceOwnerComparator: routeMatch.search.workspaceOwnerComparator,
        environment: routeMatch.search.environment,
        environmentComparator: routeMatch.search.environmentComparator,
        script: routeMatch.search.script,
        scriptComparator: routeMatch.search.scriptComparator,
        executionStatuses: routeMatch.search.executionStatuses,
        triggerTypes: routeMatch.search.triggerTypes,
        duration: routeMatch.search.duration,
        durationComparator: routeMatch.search.durationComparator,
        logCount: routeMatch.search.logCount,
        logCountComparator: routeMatch.search.logCountComparator,
        httpLogCount: routeMatch.search.httpLogCount,
        httpLogCountComparator: routeMatch.search.httpLogCountComparator,
        from: routeMatch.search.from,
        to: routeMatch.search.to,
        orderByField: routeMatch.search.orderByField,
        orderByDirection: routeMatch.search.orderByDirection,
    } as InvocationsReportRequest);

    invocationsDataForReport$.next(invocationsData);

    return {};
};
