import { FeedbackEvent } from '@avst-stitch/feedback-lib';
import { useObservableState } from 'observable-hooks';
import { Console } from '../../components/workspace/console/Console';
import { getJSXElementForFeedbackEvent } from '../../feedback';
import { selectedEnvironmentUid$, selectedWorkspaceEnvironments$, selectedWorkspaceUid$ } from '../../store/workspace';
import {
    changeLiveFeedbackSwitchAction$,
    changeFilterByEnvironmentSwitchAction$,
    clearWorkspaceFeedbackAction$,
    displayLiveFeedback$,
    filterByEnvironment$,
    filteredFeedback$,
} from '../../store/workspace/feedback';
import { getFilterKey } from '../../utils/feedback';
import { ColumnDropdownProps, LogLevel } from '../../components/workspace/console/ConsoleHeader';
import {
    changeConsoleFullScreenAction$,
    columns$,
    consoleFullScreen$,
    logLevels$,
    searchLogAction$,
    searchLogQuery$,
} from '../../store/workspace/console';

export const ConsoleContainer: React.FC = () => {
    const feedback = useObservableState(filteredFeedback$);
    const displayLiveFeedback = useObservableState(displayLiveFeedback$);
    const filterByEnvironment = useObservableState(filterByEnvironment$);
    const isFullScreen = useObservableState(consoleFullScreen$);
    const workspaceEnvironments = useObservableState(selectedWorkspaceEnvironments$);
    const searchLogsQuery = useObservableState(searchLogQuery$);
    const feedbackTypeToIgnore = '@avst-stitch/runtime-events/InvocationScheduled';
    const logLevels = useObservableState(logLevels$);
    const columns = useObservableState(columns$);

    const getWorkspaceFeedback = (workspaceUid: string): FeedbackEvent<Record<string, unknown>>[] => {
        const workspaceFeedback: FeedbackEvent<Record<string, unknown>>[] = [];

        for (const key of Object.keys(feedback)) {
            if (key.startsWith(workspaceUid) && feedback[key]) {
                workspaceFeedback.push(...(feedback[key] ?? []));
            }
        }

        return workspaceFeedback;
    };

    const matchesSearchQuery = (properties: FeedbackEvent, query: string | undefined): boolean => {
        const propertiesToCheck = ['message', 'level', 'invocationId', 'scriptName', 'environmentUid'];
        const lowerCaseQuery = query ? query.toLocaleLowerCase() : '';

        return propertiesToCheck.some((property) => {
            const value = properties[property as keyof FeedbackEvent];
            if (property === 'environmentUid') {
                const environmentName = workspaceEnvironments.find((e) => e.uid === value)?.name;
                return environmentName?.toLocaleLowerCase().includes(lowerCaseQuery);
            }

            if (typeof value === 'string') {
                return value.toLocaleLowerCase().includes(lowerCaseQuery);
            }
        });
    };

    const filteredFeedback = (
        filterByEnvironment
            ? feedback[getFilterKey(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '')] ?? []
            : getWorkspaceFeedback(selectedWorkspaceUid$.value ?? '')
    )
        .filter((e) => displayLiveFeedback || e.triggerType === 'MANUAL' || !e.invocationId)
        .filter((properties) => matchesSearchQuery(properties, searchLogsQuery))
        .sort((a, b) => a.time - b.time);

    const updateLogLevels = (newLogLevels: LogLevel[]): void => {
        logLevels$.next(newLogLevels);
    };

    const updateColumns = (newColumns: ColumnDropdownProps[]): void => {
        columns$.next(newColumns);
    };

    return (
        <Console
            logs={filteredFeedback.map((f) => ({
                time: f.time,
                source: f.scriptName,
                environmentName: workspaceEnvironments.find((e) => e.uid === f.environmentUid)?.name,
                invocationId: f.invocationId,
                level: f.level,
                message: getJSXElementForFeedbackEvent(f),
                plainText: f.type !== feedbackTypeToIgnore ? f.message : '',
            }))}
            filterByEnvironment={filterByEnvironment}
            onClear={() => clearWorkspaceFeedbackAction$.next()}
            isConsoleFullScreen={isFullScreen}
            onExpand={(f) => changeConsoleFullScreenAction$.next(f)}
            onChangeLiveFeedbackSwitch={(enabled) => changeLiveFeedbackSwitchAction$.next(enabled)}
            onChangeFilterByEnvironment={(enabled) => changeFilterByEnvironmentSwitchAction$.next(enabled)}
            onSearch={(q) => searchLogAction$.next(q)}
            searchValue={searchLogsQuery ?? ''}
            logLevels={logLevels}
            columns={columns}
            onLogLevelChecked={updateLogLevels}
            onColumnsChecked={updateColumns}
        />
    );
};
