import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { DialogAlert, DialogCloseButton } from '../../dialog';
import { Button } from '../../common/buttons/Button';
import { OrganizationPlan } from '../../../data/organization';
import { Plan, prices } from '../create-organization-wizard/CreateOrganizationWizardDialog';

export interface ChangeOrganizationPlanConfirmPurchaseDialogProps {
    open?: boolean;
    currentPaymentType: OrganizationPlan['paymentType'];
    billingEmail?: string;
    nextPlan: Plan;
    errors?: string;
    loading?: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;
}

const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledTableDisclaimerRow = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const StyledBulletList = styled('ul')(({ theme }) => ({
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledConfirmButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    '&:hover': {
        backgroundColor: theme.palette.success.dark,
    },
}));

export const ChangeOrganizationPlanConfirmPurchaseDialog: React.FC<
    ChangeOrganizationPlanConfirmPurchaseDialogProps
> = ({
    open = false,
    currentPaymentType,
    billingEmail,
    nextPlan,
    errors,
    loading = false,
    onCancel,
    onConfirm,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const nextPlanPrice = nextPlan.tier === 'free' ? '0' : prices[nextPlan.tier]?.[nextPlan.period] ?? '0';

    return (
        <Dialog open={open}>
            <div>
                <DialogCloseButton disabled={loading} onClick={onCancel} />
                <StyledDialogTitle variant="h4" className="extra-bold">
                    Confirm {currentPaymentType === 'INVOICE' ? 'Invoice ' : ''}Purchase
                </StyledDialogTitle>
            </div>
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <StyledDialogContent>
                <StyledCard>
                    <StyledTableRow>
                        <Typography component="h4" className="extra-bold">
                            Payment summary
                        </Typography>
                    </StyledTableRow>
                    <StyledTableRow>
                        <Typography component="h4" className="extra-bold">
                            Plan
                        </Typography>
                        <Typography component="h4" sx={{ textTransform: 'capitalize' }}>
                            {nextPlan.tier} ({nextPlan.period === 'monthly' ? 'Monthly' : 'Yearly'})
                        </Typography>
                    </StyledTableRow>
                    <StyledTableRow>
                        <Typography component="h4" className="extra-bold">
                            Total Amount
                        </Typography>
                        <Typography component="h4">${nextPlanPrice}</Typography>
                    </StyledTableRow>
                </StyledCard>
                {billingEmail && (
                    <StyledCard sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                        <StyledTableRow>
                            <Typography component="h4" className="extra-bold">
                                Billing Details
                            </Typography>
                        </StyledTableRow>
                        <StyledTableRow>
                            <Typography component="h4" className="extra-bold">
                                Email
                            </Typography>
                            <Typography component="h4">{billingEmail}</Typography>
                        </StyledTableRow>
                    </StyledCard>
                )}
                <StyledCard sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                    <StyledTableRow>
                        <Typography component="h4" className="extra-bold">
                            Disclaimer
                        </Typography>
                    </StyledTableRow>
                    {currentPaymentType === 'CARD' && (
                        <StyledTableDisclaimerRow>
                            <Typography component="h4">
                                Upon clicking <b>Confirm</b>, the process varies depending on your plan type:
                            </Typography>

                            <StyledBulletList>
                                <li>
                                    <b>Annual plans:</b> the plan amount is added to your payment cycle and your credit
                                    card is charged immediately.
                                </li>
                                <li>
                                    <b>Monthly plans:</b> the difference is paid the following month when you switch
                                    between plans.
                                </li>
                            </StyledBulletList>

                            <Typography component="h4">
                                Changing your plan will reset your billing cycle and may result in a{' '}
                                <b>pro-rata charge</b> for previous usage.
                            </Typography>
                        </StyledTableDisclaimerRow>
                    )}
                    {currentPaymentType === 'INVOICE' && (
                        <StyledTableDisclaimerRow>
                            <Typography component="h4">
                                Upon clicking <b>Confirm</b>, the process varies depending on your plan type:
                            </Typography>
                            <StyledBulletList>
                                <li>
                                    <b>Annual plans:</b> the plan amount is added to your payment cycle and your credit
                                    card is charged immediately.
                                </li>
                                <li>
                                    <b>Monthly plans:</b> the difference is paid the following month when you switch
                                    between plans.
                                </li>
                            </StyledBulletList>
                            <Typography component="h4">
                                Changing your plan will reset your billing cycle and may result in a{' '}
                                <b>pro-rata charge</b> for previous usage.
                            </Typography>
                        </StyledTableDisclaimerRow>
                    )}
                </StyledCard>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button busy={loading} variant="outlined" onClick={onCancel}>
                    Back
                </Button>
                <StyledConfirmButton busy={loading} onClick={onConfirm}>
                    Confirm
                </StyledConfirmButton>
            </StyledDialogActions>
        </Dialog>
    );
};
