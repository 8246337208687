import { useObservableState, useSubscription } from 'observable-hooks';
import { OrganizationUsageDetails } from '../../components/organization/organization-details/usage/OrganizationUsageDetails';
import { organizationSwitchedAction$, selectedOrganizationUsage$ } from '../../store/organization';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../utils/path';

export const OrganizationUsageContainer: React.FC = () => {
    const { connectors, invocations, recordStorage } = useObservableState(selectedOrganizationUsage$) || {};

    const navigate = useNavigate();

    useSubscription(organizationSwitchedAction$, (uid) => navigate({ to: `${getBasePath()}team/${uid}/usage` }));
    return <OrganizationUsageDetails connectors={connectors} invocations={invocations} recordStorage={recordStorage} />;
};
