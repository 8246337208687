import { BehaviorSubject, Subject, map, filter } from 'rxjs';
import { getInvocations, InvocationsRequest, InvocationsResponse } from '../data/reporting';
import { InformativeError } from '../utils/repository';
import { monitor } from './monitor';

export const invocationsData$ = monitor(
    'invocations$',
    new BehaviorSubject<InvocationsResponse>({ invocations: [], workspaces: {} })
);

export const reportingLoading$ = monitor('reportingLoading$', new BehaviorSubject(false));

export const reportingError$ = monitor('reportingError$', new BehaviorSubject<string | undefined>(undefined));

export const queryInvocationsAction$ = monitor('queryInvocationsAction$', new Subject<InvocationsRequest>());

export const searchInvocationsAction$ = monitor('searchInvocationsAction$', new Subject<InvocationsRequest>());

export const addInvocationsAction$ = monitor('addInvocationsAction$', new Subject<InvocationsResponse>());

queryInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !!nextToken),
        map(async (request) => {
            reportingError$.next(undefined);

            try {
                const invocationsResponse = await getInvocations(request);
                addInvocationsAction$.next(invocationsResponse);
            } catch (e) {
                if (e instanceof InformativeError) {
                    reportingError$.next(e.message);
                } else {
                    reportingError$.next(
                        'Failed to query for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            reportingLoading$.next(false);
        })
    )
    .subscribe();

searchInvocationsAction$
    .pipe(
        filter(({ nextToken }) => !nextToken),
        map(async (request) => {
            reportingLoading$.next(true);
            reportingError$.next(undefined);

            try {
                const invocationsResponse = await getInvocations(request);
                invocationsData$.next(invocationsResponse);
            } catch (e) {
                if (e instanceof InformativeError) {
                    reportingError$.next(e.message);
                } else {
                    reportingError$.next(
                        'Failed to search for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            reportingLoading$.next(false);
        })
    )
    .subscribe();

addInvocationsAction$
    .pipe(
        filter(({ invocations }) => invocations.length > 0),
        map(({ invocations, nextToken, workspaces }) => {
            const currentInvocationsData = invocationsData$.value;
            return {
                invocations: [...currentInvocationsData.invocations, ...invocations],
                nextToken,
                workspaces,
            };
        })
    )
    .subscribe((invocationsData) => invocationsData$.next(invocationsData));
