import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { WorkspaceSelector } from '../components/workspace/workspace-selector';
import {
    updateWizardStateAction$,
    updateWizardStepAction$,
    WizardState,
    WizardStep,
    wizardWorkspaceDetails$,
} from '../store/wizard';
import {
    openNewBlankWorkspaceAction$,
    loggedInUserWorkspaces$,
    newWorkspaceFromTemplate$,
    deleteWorkspaceAction$,
    workspaceDeletedAction$,
    newDuplicatedWorkspace$,
} from '../store/workspaces';
import { getBasePath } from '../utils/path';
import { loggedInUserDetails$ } from '../store/user';
import { organizationSwitchedAction$ } from '../store/organization';

export const WorkspacesContainer: React.FC = () => {
    const workspaces = useObservableState(loggedInUserWorkspaces$);
    const wizardWorkspaceDetails = useObservableState(wizardWorkspaceDetails$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);

    const navigate = useNavigate();

    const navigateToWorkspace = (workspaceUid: string, environmentUid: string): void =>
        navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });

    const selectWorkspace = (workspaceUid: string): void => {
        const workspace = loggedInUserWorkspaces$.value.find((workspace) => workspace.uid === workspaceUid);
        if (workspace && workspace.environments.length > 0 && workspace.environments[0]) {
            navigateToWorkspace(
                workspace.uid,
                (workspace.environments.find((e) => e.selected) ?? workspace.environments[0]).uid
            );
        }
    };

    useSubscription(organizationSwitchedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}workspaces` }), 100);
    });

    useSubscription(workspaceDeletedAction$, (uid) => {
        if (wizardWorkspaceDetails?.workspaceUid === uid) {
            updateWizardStateAction$.next(WizardState.CLOSED);
        }
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: `${getBasePath()}dashboard` });
        setTimeout(() => navigate({ to: `${getBasePath()}workspaces` }), 100);
    });

    return (
        <WorkspaceSelector
            userCanWorkWithTemplates={userCanWorkWithTemplates}
            workspaces={workspaces.map(
                ({
                    uid,
                    name,
                    template,
                    description,
                    incomingApps,
                    outgoingApps,
                    environments,
                    organization,
                    members,
                }) => ({
                    uid,
                    title: name,
                    description,
                    template,
                    incoming: incomingApps,
                    outgoing: outgoingApps,
                    environments,
                    users: members,
                    orgName: organization?.name,
                    shared: members.length > 1,
                })
            )}
            onCreateBlank={() => {
                newDuplicatedWorkspace$.next(undefined);
                newWorkspaceFromTemplate$.next(undefined);
                openNewBlankWorkspaceAction$.next('home');
            }}
            onDelete={(uid) => deleteWorkspaceAction$.next(uid)}
            onDuplicate={(details) => {
                newDuplicatedWorkspace$.next(details);
                newWorkspaceFromTemplate$.next(undefined);
                openNewBlankWorkspaceAction$.next('duplicated');
            }}
            onSelect={selectWorkspace}
            onStartWizard={() => updateWizardStepAction$.next(WizardStep.START)}
        />
    );
};
