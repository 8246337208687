import { useAuth0 } from '@auth0/auth0-react';
import { useObservableState } from 'observable-hooks';
import { configTopic$ } from '../store/config';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';

export interface Auth0UserDetails {
    readonly sub?: string;
    readonly mfaEnabled?: boolean;
}
export interface UserDetails extends Auth0UserDetails {
    readonly uid?: string;
    readonly name?: string;
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly email?: string;
    readonly canWorkWithTemplates?: boolean;
    readonly termsAccepted?: boolean;
    readonly accountVerified?: boolean;
    readonly stitchTeamMember?: boolean;
    readonly company?: string | null;
    readonly roleUid?: string | null;
    readonly customRole?: string | null;
    readonly loggedInWithIdp?: string;
    readonly maxFreeTeams?: number;
    readonly deleteDate?: Date;
    readonly publishUserRegisteredEvent?: boolean;
    readonly emailNotificationsEnabled?: boolean;
    readonly impersonating?: {
        readonly email: string;
        readonly firstName?: string;
        readonly lastName?: string;
    };
    readonly userOrigin?: string | null;
    readonly scriptingFamiliarityUid?: string | null;
    readonly userAppPreference?: string[];
    readonly migrationPreference?: boolean;
    readonly syncPreference?: boolean;
    readonly integrationsPreference?: boolean;
    readonly organizations?: SidenavOrganization[];
}

export interface AuthContext {
    readonly userDetails: UserDetails;
    readonly error?: Error;
    readonly isAuthenticated: boolean;
    readonly isLoading: boolean;
    readonly operations: AuthOperations;
}

export interface AuthTokens {
    readonly id_token: string;
    readonly access_token: string;
    readonly refresh_token?: string;
    readonly expires_in: number;
}

export interface WithAuthorization {
    readonly authorization: string;
}

/**
 * Operations that can be performed with the AuthContext
 */
export interface AuthOperations {
    readonly login: (loginOpts?: { showSignup: boolean }) => Promise<void>;
    readonly logout: (logoutOpts: { redirectUrl: string }) => Promise<void>;
    readonly getTokens: GetTokens;
    readonly withAuth: <T>(req: T) => Promise<T & WithAuthorization>;
}

type GetTokens = () => Promise<AuthTokens>;

export const useAuth = (): AuthContext => {
    const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const { auth0 } = useObservableState(configTopic$);

    const getTokens: GetTokens = async () => getAccessTokenSilently({ detailedResponse: true, audience: auth0.apiUrl });

    return {
        userDetails: {
            sub: user?.sub,
        },
        error,
        isAuthenticated,
        isLoading,
        operations: {
            login: async ({ showSignup } = { showSignup: false }) => {
                loginWithRedirect({
                    appState: {
                        target: window.location.href,
                    },
                    detailedResponse: true,
                    prompt: 'select_account',
                    ...(showSignup ? { screen_hint: 'signup' } : {}),
                });
            },
            logout: async ({ redirectUrl } = { redirectUrl: window.location.origin }) =>
                logout({ returnTo: redirectUrl }),
            getTokens: getTokens,
            withAuth: async (req) => {
                return { ...req, authorization: (await getTokens()).access_token };
            },
        },
    };
};
