/* eslint-disable @typescript-eslint/no-explicit-any */

export const initPendo = async (apiKey: string): Promise<void> => {
    // Create a queue, but don't obliterate an existing one!
    const pendo = ((window as any).pendo = (window as any).pendo || []);

    // If the real pendo.js is already on the page return.
    if (pendo.initialize) return;

    if (pendo.invoked) {
        if (window.console && console.error) {
            console.error('Pendo snippet included twice.');
        }
        return;
    }

    pendo.invoked = true;

    // A list of the methods in pendo.js to stub.
    pendo.methods = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];

    pendo.factory = function (method: any) {
        return function () {
            // eslint-disable-next-line prefer-rest-params
            const args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            pendo.push(args);
            return pendo;
        };
    };

    for (let i = 0; i < pendo.methods.length; i++) {
        const key = pendo.methods[i];
        pendo[key] = pendo.factory(key);
    }

    pendo.load = function (key: any) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.eu.pendo.io/agent/static/' + key + '/pendo.js';

        const first = document.getElementsByTagName('script')[0];
        first?.parentNode?.insertBefore(script, first);
    };
    pendo.load(apiKey);
};

export const initializePendoForUser = (
    userUid: string,
    organizationUid?: string,
    orgDetails?: Record<string, unknown>
): void => {
    console.debug(`Init pendo for user ${userUid} and team ${organizationUid}`);
    if (!organizationUid) {
        console.debug('No team is selected for user');
    }
    try {
        (window as any).pendo?.initialize({
            visitor: {
                id: userUid,
            },
            account: {
                id: organizationUid,
                ...orgDetails,
            },
        });
    } catch (e) {
        console.error('Error while calling Pendo initialize', e);
    }
};

export const pendoAnalyticsTrack = (event: string, properties?: any): void => {
    try {
        (window as any).pendo?.track(event, properties);
    } catch (e) {
        console.error('Error while calling Pendo track', e);
    }
};
