import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import {
    StyledListItemButton,
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
    StyledOrganizationAvatar,
} from './SidenavComponents';

export type SidenavOrganizationSettingsSubRoute = 'details' | 'usage' | 'members' | 'plan' | 'billing';

interface SidenavOrganizationSettingsNavigatorProps {
    collapsed: boolean;
    isSelected: boolean;
    organizationName: string;
    ownedDefaultOrganization?: boolean;
    onNavigateToOrganizationSettings(subRoute: SidenavOrganizationSettingsSubRoute): void;
}

export const SidenavOrganizationSettingsNavigator: React.FC<SidenavOrganizationSettingsNavigatorProps> = ({
    collapsed,
    isSelected,
    organizationName,
    ownedDefaultOrganization = false,
    onNavigateToOrganizationSettings,
}) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

    const menuItems = [
        {
            icon: (
                <StyledOrganizationAvatar ownedDefaultOrganization={ownedDefaultOrganization}>
                    {organizationName.slice(0, 2).toLocaleUpperCase()}
                </StyledOrganizationAvatar>
            ),
            label: 'General',
            value: 'details' as const,
        },
        { icon: <DataUsageOutlinedIcon />, label: 'Usage', value: 'usage' as const },
        { icon: <PeopleOutlineOutlinedIcon />, label: 'Members', value: 'members' as const },
        { icon: <StyleOutlinedIcon />, label: 'Plans', value: 'plan' as const },
        { icon: <CreditCardOutlinedIcon />, label: 'Billing', value: 'billing' as const },
    ].map((m) => (
        <StyledMenuItem
            key={m.value}
            onClick={() => {
                onNavigateToOrganizationSettings(m.value);
                setAnchor(null);
            }}
        >
            <StyledMenuItemContent>
                {m.icon}
                <StyledMenuItemText>{m.label}</StyledMenuItemText>
            </StyledMenuItemContent>
        </StyledMenuItem>
    ));

    return (
        <>
            <StyledListItemButton
                collapsed={collapsed}
                selected={!!anchor || isSelected}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
            >
                <Tooltip title="Team settings">
                    <SettingsOutlinedIcon />
                </Tooltip>
                <Typography>Team settings</Typography>
            </StyledListItemButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
