import { BehaviorSubject, map, Subject } from 'rxjs';
import { WorkspaceSharingDetails, SaveWorkspaceSharingDetailsRequest } from '../../data/workspaces';
import { monitor } from '../monitor';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import {
    addWorkspaceToOrganization,
    getWorkspaceSharingDetails,
    saveWorkspaceSharingDetails,
} from '../../data/sharing';
import { organizationSelectedForSharing$ } from '../organizations';
import { InformativeError } from '../../utils/repository';
import { selectedWorkspace$ } from '.';

export const workspaceSharingDialogOpen$ = monitor('workspaceSharingDialogOpen$', new BehaviorSubject(false));
export const workspaceSharingDetails$ = monitor(
    'workspaceSharingDetails$',
    new BehaviorSubject<WorkspaceSharingDetails | undefined>(undefined)
);
export const loadingWorkspaceSharingDetails$ = monitor('loadingWorkspaceSharingDetails$', new BehaviorSubject(false));
export const savingWorkspaceSharingDetails$ = monitor('savingWorkspaceSharingDetails$', new BehaviorSubject(false));
export const addWorkspaceToOrganizationDialogOpen$ = monitor(
    'addWorkspaceToOrganizationDialogOpen$',
    new BehaviorSubject(false)
);
export const addingWorkspaceToOrganization$ = monitor('addingWorkspaceToOrganization$', new BehaviorSubject(false));

export const workspaceBeingShared$ = monitor(
    'workspaceBeingShared$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const addWorkspaceToOrganizationError$ = monitor(
    'addWorkspaceToOrganizationError$',
    new BehaviorSubject<string>('')
);

export const openWorkspaceSharingDialogAction$ = monitor('openWorkspaceSharingDialogAction$', new Subject<string>());
export const getWorkspaceSharingDetailsAction$ = monitor('getWorkspaceSharingDetailsAction$', new Subject<string>());
export const saveWorkspaceSharingDetailsAction$ = monitor(
    'saveWorkspaceSharingDetailsAction$',
    new Subject<SaveWorkspaceSharingDetailsRequest>()
);
export const cancelWorkspaceSharingDetailsAction$ = monitor(
    'cancelWorkspaceSharingDetailsAction$',
    new Subject<void>()
);
export const addWorkspaceToOrganizationAction$ = monitor(
    'addWorkspaceToOrganizationAction$',
    new Subject<{ workspaceUid: string; organizationUid: string }>()
);
export const cancelAddWorkspaceToOrganizationAction$ = monitor(
    'cancelAddWorkspaceToOrganizationAction$',
    new Subject<void>()
);

openWorkspaceSharingDialogAction$.subscribe((workspaceUid: string) => {
    getWorkspaceSharingDetailsAction$.next(workspaceUid);
    workspaceSharingDialogOpen$.next(true);
});

cancelWorkspaceSharingDetailsAction$.subscribe(() => {
    workspaceSharingDetails$.next(undefined);
    workspaceSharingDialogOpen$.next(false);
});

cancelAddWorkspaceToOrganizationAction$.subscribe(() => {
    addWorkspaceToOrganizationDialogOpen$.next(false);
    addWorkspaceToOrganizationError$.next('');
    workspaceBeingShared$.next(undefined);
    organizationSelectedForSharing$.next(undefined);
});

getWorkspaceSharingDetailsAction$
    .pipe(
        map(async (uid) => {
            loadingWorkspaceSharingDetails$.next(true);
            try {
                const response = await getWorkspaceSharingDetails(uid);
                workspaceSharingDetails$.next(response);
            } catch (err) {
                console.error('Error while retrieving workspace sharing details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to retrieve Workspace sharing details, please try again, if the issue persists please contact support.`,
                });
            }
            loadingWorkspaceSharingDetails$.next(false);
        })
    )
    .subscribe();

saveWorkspaceSharingDetailsAction$
    .pipe(
        map(async ({ workspaceUid, visibility }) => {
            savingWorkspaceSharingDetails$.next(true);
            try {
                await saveWorkspaceSharingDetails(workspaceUid, visibility);
                workspaceSharingDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message:
                        visibility === 'PRIVATE'
                            ? 'Workspace shared with administrators only.'
                            : 'Workspace shared with all members.',
                });
            } catch (err) {
                console.error('Error while saving workspace sharing details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to save Workspace sharing details, please try again, if the issue persists please contact support.`,
                });
            }
            savingWorkspaceSharingDetails$.next(false);
        })
    )
    .subscribe();

addWorkspaceToOrganizationAction$
    .pipe(
        map(async ({ workspaceUid, organizationUid }) => {
            addWorkspaceToOrganizationError$.next('');
            addingWorkspaceToOrganization$.next(true);
            try {
                const { uid, name, members } = await addWorkspaceToOrganization(workspaceUid, organizationUid);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace successfully added to team.',
                });
                if (selectedWorkspace$.value) {
                    selectedWorkspace$.next({
                        ...selectedWorkspace$.value,
                        organization: { name, uid, members },
                    });
                }
                organizationSelectedForSharing$.next(undefined);
                addWorkspaceToOrganizationDialogOpen$.next(false);
                openWorkspaceSharingDialogAction$.next(workspaceUid);
            } catch (err) {
                if (err instanceof InformativeError) {
                    addWorkspaceToOrganizationError$.next(err.message);
                } else {
                    addWorkspaceToOrganizationError$.next(
                        'Unknown error occurred while adding Workspace to team. Please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while adding Workspace to team', err);
                }
            }
            addingWorkspaceToOrganization$.next(false);
        })
    )
    .subscribe();
