import {
    createWorkspace as createWorkspaceRpc,
    updateWorkspaceDetails as updateWorkspaceDetailsRpc,
    getMyWorkspacesByOrganization as getMyWorkspacesByOrganizationRpc,
    deleteWorkspace as deleteWorkspaceRpc,
    getSaveWorkspaceFormDetails as getSaveWorkspaceFormDetailsRpc,
} from '@avst-stitch/repository-lib/lib';
import {
    Response as CreateWorkspaceResponse,
    Request as CreateWorkspaceRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/createWorkspace';
import { Response as UserWorkspaces } from '@avst-stitch/repository-lib/lib/rpcs/getMyWorkspaces';
import {
    Response as UpdateWorkspaceDetailsResponse,
    Request as UpdateWorkspaceDetailsRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/updateWorkspaceDetails';
import { Response as WorkspaceFormDetails } from '@avst-stitch/repository-lib/lib/rpcs/getSaveWorkspaceFormDetails';
import { Response as WorkspaceSharingDetails } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceSharingDetails';
import { Request as SaveWorkspaceSharingDetailsRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveWorkspaceSharingDetails';
import { repositoryInvoker } from '../utils/repository';
export type {
    CreateWorkspaceRequest,
    CreateWorkspaceResponse,
    UserWorkspaces,
    WorkspaceFormDetails,
    WorkspaceSharingDetails,
    SaveWorkspaceSharingDetailsRequest,
    UpdateWorkspaceDetailsResponse,
};

export const createWorkspace = async (
    createWorkspaceProps: CreateWorkspaceRequest
): Promise<CreateWorkspaceResponse> => {
    return await createWorkspaceRpc(repositoryInvoker, createWorkspaceProps);
};

export const deleteWorkspace = async (uid: string): Promise<void> => {
    await deleteWorkspaceRpc(repositoryInvoker, {
        uid,
    });
};

export const updateWorkspaceDetails = async (
    updateWorkspaceDetailsProps: UpdateWorkspaceDetailsRequest
): Promise<UpdateWorkspaceDetailsResponse> => {
    return await updateWorkspaceDetailsRpc(repositoryInvoker, updateWorkspaceDetailsProps);
};

export const getLoggedInUserWorkspaces = async (
    organizationUid: string,
    source?: 'workspaces'
): Promise<UserWorkspaces> => {
    return await getMyWorkspacesByOrganizationRpc(repositoryInvoker, { organizationUid, source });
};

export const getSaveWorkspaceFormDetails = async (workspaceUid?: string): Promise<WorkspaceFormDetails> => {
    return await getSaveWorkspaceFormDetailsRpc(repositoryInvoker, {
        workspaceUid,
    });
};
