import { getInvocations as getInvocationsRpc } from '@avst-stitch/repository-lib/lib';
import { getMyAuditLogs as getMyAuditLogsRpc } from '@avst-stitch/repository-lib/lib';
import { getMyAuditLogsUrl as getMyAuditLogsUrlRpc } from '@avst-stitch/repository-lib/lib/rpcs/getMyAuditLogsUrl';
import {
    Request as InvocationsRequest,
    Response as InvocationsResponse,
    InvocationRecord,
    InvocationWorkspace,
} from '@avst-stitch/repository-lib/lib/rpcs/getInvocations';
import {
    Request as UserAuditLogsRequest,
    Response as UserAuditLogsResponse,
} from '@avst-stitch/repository-lib/lib/rpcs/getMyAuditLogs';
import {
    Request as UserAuditLogsUrlRequest,
    Response as UserAuditLogsUrlResponse,
} from '@avst-stitch/repository-lib/lib/rpcs/getMyAuditLogsUrl';
import { repositoryInvoker } from '../utils/repository';
export type {
    InvocationsRequest,
    InvocationsResponse,
    InvocationWorkspace,
    InvocationRecord,
    UserAuditLogsRequest,
    UserAuditLogsResponse,
    UserAuditLogsUrlRequest,
    UserAuditLogsUrlResponse,
};

export type Invocations = InvocationRecord[];

export const getInvocations = async (request: InvocationsRequest): Promise<InvocationsResponse> => {
    return await getInvocationsRpc(repositoryInvoker, request);
};

export const getLoggedInUserAuditLogs = async (request: UserAuditLogsRequest): Promise<UserAuditLogsResponse> => {
    return await getMyAuditLogsRpc(repositoryInvoker, request);
};

export const getLoggedInUserAuditLogsUrl = async (
    request: UserAuditLogsUrlRequest
): Promise<UserAuditLogsUrlResponse> => {
    return await getMyAuditLogsUrlRpc(repositoryInvoker, request);
};
