import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface FilterProps {
    filters: string[];
    selectedFilters: string[];
    onUpdate(event: SelectChangeEvent<unknown>): void;
}

const StyledSelect = styled(Select)(() => ({
    width: 110,
    '& .MuiInputBase-input:focus': {
        backgroundColor: 'transparent',
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
    '&:hover': {
        backgroundColor: theme.palette.background.default + '!important',
    },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    height: 18,
    width: 18,
    marginRight: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
    },
}));

export const ColumnFilters: React.FC<FilterProps> = ({ filters, onUpdate, selectedFilters }) => {
    return (
        <>
            <FormControl variant="standard" sx={{ height: 24 }}>
                <InputLabel id="columns-select-label" shrink={false}>
                    Columns
                </InputLabel>
                <StyledSelect
                    labelId="columns-select-label"
                    id="columns-select"
                    disableUnderline={true}
                    renderValue={() => ''}
                    multiple
                    value={selectedFilters}
                    onChange={onUpdate}
                    MenuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'right' },
                    }}
                >
                    {filters?.map((filter, index) => {
                        const isChecked = selectedFilters.some((a) => a === filter);
                        return (
                            <StyledMenuItem key={index} value={filter} selected={false}>
                                <StyledCheckbox checked={isChecked} />
                                <ListItemText primary={filter} />
                            </StyledMenuItem>
                        );
                    })}
                </StyledSelect>
            </FormControl>
        </>
    );
};
