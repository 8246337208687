import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { AddNewMemberDialog } from '../../components/organization/member-management/AddNewMemberDialog';
import { MemberManagement } from '../../components/organization/member-management/MemberManagement';
import {
    addNewOrganizationMemberAction$,
    addNewOrganizationMemberDialogOpen$,
    organizationMemberInviteSentAction$,
    organizationMembers$,
    resendOrganizationMemberInviteAction$,
    selectedOrganizationUid$,
    sendingOrganizationMemberInvite$,
    sendOrganizationMemberInviteError$,
    updateOrganizationInvitedUserPermissionsAction$,
    updateOrganizationMemberPermissionsAction$,
    removeOrganizationInviteAction$,
    removeOrganizationMemberAction$,
    organizationInviteRemovedAction$,
    organizationMemberRemovedAction$,
    organizationInvitedUserPermissionsUpdatedAction$,
    organizationMemberPermissionsUpdatedAction$,
    memberManagementError$,
    memberManagementSaving$,
    organizationSwitchedAction$,
} from '../../store/organization';
import { loggedInUserDetails$ } from '../../store/user';
import { getBasePath } from '../../utils/path';
import { promptQuestion } from '../../store/confirm';
import { AYSYWTCTPOTU, AYSYWTRTU } from '../../i18n';

export const OrganizationMembersContainer: React.FC = () => {
    const selectedOrgUid = useObservableState(selectedOrganizationUid$);
    const orgMembers = useObservableState(organizationMembers$);
    const newMemberDialogOpen = useObservableState(addNewOrganizationMemberDialogOpen$);
    const inviteMemberInviteError = useObservableState(sendOrganizationMemberInviteError$);
    const sendingOrganizationMemberInvite = useObservableState(sendingOrganizationMemberInvite$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const memberManagementError = useObservableState(memberManagementError$);
    const saving = useObservableState(memberManagementSaving$);
    const navigate = useNavigate();

    useSubscription(organizationMemberInviteSentAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/members` }), 100);
    });

    useSubscription(organizationMemberPermissionsUpdatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: getBasePath() }); //Forcing the organization loader here
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/members` }), 100);
    });

    useSubscription(organizationInviteRemovedAction$, () => {
        navigate({ to: getBasePath() });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/members` }), 100);
    });

    useSubscription(organizationMemberRemovedAction$, () => {
        navigate({ to: getBasePath() });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/members` }), 100);
    });

    useSubscription(organizationInvitedUserPermissionsUpdatedAction$, () => {
        navigate({ to: getBasePath() });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/members` }), 100);
    });

    useSubscription(organizationSwitchedAction$, (uid) => navigate({ to: `${getBasePath()}team/${uid}/members` }));

    const canInvite = orgMembers?.users.find((u) => u.email === loggedInUserDetails?.email)?.role !== 'MEMBER';

    return (
        <>
            <MemberManagement
                saving={saving}
                canInvite={canInvite}
                users={orgMembers?.users ?? []}
                pendingUsers={orgMembers?.pendingInviteUsers ?? []}
                roles={[
                    { name: 'Super Admin', value: 'SUPER_ADMIN' },
                    { name: 'Admin', value: 'ADMIN' },
                    { name: 'Member', value: 'MEMBER' },
                ]}
                onInvitedUserRoleUpdate={(event) =>
                    promptQuestion({
                        message: AYSYWTCTPOTU,
                        onProceed: () => {
                            updateOrganizationInvitedUserPermissionsAction$.next({
                                organizationUid: selectedOrgUid ?? '',
                                inviteUid: event.inviteUid ?? '',
                                role: event.role,
                            });
                        },
                    })
                }
                onUserRoleUpdate={(event) =>
                    promptQuestion({
                        message: AYSYWTCTPOTU,
                        onProceed: () =>
                            updateOrganizationMemberPermissionsAction$.next({
                                organizationUid: selectedOrgUid ?? '',
                                memberUid: event.memberUid ?? '',
                                role: event.role,
                            }),
                    })
                }
                onInviteRemove={(uid) =>
                    promptQuestion({
                        message: AYSYWTRTU,
                        onProceed: () => {
                            removeOrganizationInviteAction$.next({
                                organizationUid: selectedOrgUid ?? '',
                                removedInvite: uid,
                            });
                        },
                    })
                }
                onUserRemove={(uid) =>
                    removeOrganizationMemberAction$.next({
                        organizationUid: selectedOrgUid ?? '',
                        removedUser: uid,
                    })
                }
                onAddMember={() => addNewOrganizationMemberDialogOpen$.next(true)}
                onResendInvite={(uid) => resendOrganizationMemberInviteAction$.next(uid)}
                error={memberManagementError}
            />
            <AddNewMemberDialog
                open={newMemberDialogOpen}
                errors={inviteMemberInviteError}
                saving={sendingOrganizationMemberInvite}
                onSend={(invite) => {
                    addNewOrganizationMemberAction$.next({
                        email: invite.email,
                        organizationUid: selectedOrgUid ?? '',
                    });
                }}
                onCancel={() => {
                    addNewOrganizationMemberDialogOpen$.next(false);
                    sendOrganizationMemberInviteError$.next(undefined);
                }}
            />
        </>
    );
};
