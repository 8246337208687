import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button } from '../common/buttons/Button';
import { SxProps, Theme } from '@mui/material/styles';

// ## shared empty state page styled elements e.g. new workspace, connector, organisation, error

interface PageCenterProps {
    readonly sx?: SxProps<Theme>;
}

interface PageEmptyStateImgProps {
    readonly src: string;
    readonly alt: string;
    readonly sx?: SxProps<Theme>;
}

const StyledCenterPage = styled('div')(() => ({
    maxWidth: 430,
    position: 'absolute',
    right: '50%',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(50%, -50%)',
}));

const StyledInstructions = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
}));

const StyledEmptyImg = styled('img')(({ theme }) => ({
    height: 200,
    marginBottom: theme.spacing(3),
    width: 200,
}));

export const StyledActionButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

// if you want to left align the contents pass in sx={{ textAlign: 'left'}}
export const EmptyStateContainer: React.FC<PageCenterProps> = ({ sx, children }) => {
    return (
        <StyledCenterPage sx={sx} className="pagecentergroup">
            {children}
        </StyledCenterPage>
    );
};

export const EmptyStateText: React.FC = ({ children }) => {
    return <StyledInstructions>{children}</StyledInstructions>;
};

export const EmptyStateImg: React.FC<PageEmptyStateImgProps> = ({ src, alt, sx }) => {
    return <StyledEmptyImg className="chromatic-ignore" src={src} alt={alt} sx={sx} />;
};
