import { useObservableState } from 'observable-hooks';
import {
    organizationBillingDetails$,
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
} from '../../store/organization';
import {
    changeOrganizationPlanActionV2$,
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$,
    closeChangeOrganizationPlanConfirmationDialogAction$,
    openChangeOrganizationPlanConfirmPurchaseDialogAction$,
} from '../../store/organization/changeOrganizationPlan';
import { changeOrganizationPlanConfirmationDialogOpen$ } from '../../store/organization/changeOrganizationPlan';
import { ChangeOrganizationPlanConfirmationDialog } from '../../components/organization/change-organization-plan-wizard/ChangeOrganizationPlanConfirmationDialog';

export const ChangeOrganizationPlanConfirmationContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanConfirmationDialogOpen$);
    const nextPlan = useObservableState(changeOrganizationPlanConfirmPurchaseDialogNextPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const billingDetails = useObservableState(organizationBillingDetails$);

    if (!currentPlan || !nextPlan) {
        return null;
    }

    const handleProceed = (): void => {
        closeChangeOrganizationPlanConfirmationDialogAction$.next();
        if (currentPlan.tier === 'free' || nextPlan.tier === 'free' || currentPlan.subscriptionStatus === 'SUSPENDED') {
            changeOrganizationPlanActionV2$.next({
                plan: nextPlan,
                paymentMethod: nextPlan.paymentMethod,
                organizationUid: orgUid || '',
                existingBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                originalReferrer: window.location.origin,
            });
        } else {
            openChangeOrganizationPlanConfirmPurchaseDialogAction$.next(nextPlan);
        }
    };

    return (
        <ChangeOrganizationPlanConfirmationDialog
            open={open}
            currentPlan={currentPlan}
            nextPlan={nextPlan}
            onCancel={() => closeChangeOrganizationPlanConfirmationDialogAction$.next()}
            onProceed={handleProceed}
        />
    );
};
