import { FormControl, MenuItem, TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface InvocationsReportPageTextFieldFilterProps {
    comparatorLabel: string;
    comparatorValue: string;
    filterLabel: string;
    filterValue: string | number;
    filterType?: 'number';
    onChangeComparator(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    onChangeFilter(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

export const InvocationsReportPageTextFieldFilter: React.FC<InvocationsReportPageTextFieldFilterProps> = ({
    comparatorLabel,
    comparatorValue,
    filterLabel,
    filterValue,
    filterType,
    onChangeComparator,
    onChangeFilter,
}) => {
    const menuItems = filterType
        ? [
              {
                  value: 'gt',
                  text: 'Greater Than',
              },
              {
                  value: 'lt',
                  text: 'Less Than',
              },
          ]
        : [
              {
                  value: 'equals',
                  text: 'Equals',
              },
              {
                  value: 'contains',
                  text: 'Contains',
              },
          ];

    return (
        <div>
            <FormControl size="small">
                <TextField
                    label={filterLabel}
                    variant="outlined"
                    type={filterType}
                    value={filterValue}
                    onChange={onChangeFilter}
                />
            </FormControl>
            <FormControl>
                <TextField
                    select
                    variant="outlined"
                    label={comparatorLabel}
                    value={comparatorValue}
                    onChange={onChangeComparator}
                >
                    {menuItems.map((item, idx) => (
                        <MenuItem key={idx} value={item.value}>
                            {item.text}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
        </div>
    );
};
