import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../common/buttons/Button';
import { OpenInNewLink } from '../../common/OpenInNewLink';
import { ConnectionModalSecretTextField, ConnectionModalTextField } from '../../textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { TrelloWizardStage, TrelloWizardSteps, StageType } from './TrelloWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../dialog';
import { TrelloIcon } from '../../icons/TrelloIcon';

export interface TrelloClientInfo {
    apiKey: string;
    token: string;
}

export interface TrelloConnection {
    clientInfo: TrelloClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: TrelloWizardStage;
    setStage: (stage: TrelloWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
}

export interface onSaveProps {
    apiKey: string;
    token: string;
}

const StageContent: StageType = (props) => {
    const [apiKey, setApiKey] = useState(props.clientInfo.apiKey);
    const [token, setToken] = useState(props.clientInfo.token);

    const POWER_UP = 'https://trello.com/power-ups/admin';
    const SCOPES = ['read', 'write', 'account'].join(',');
    const TOKEN_URL = `https://trello.com/1/authorize?expiration=never&scope=${SCOPES}&response_type=token&key=${apiKey}&name=Stitch%20It`;

    switch (props.stage) {
        case TrelloWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have a Power-Up assigned to your workspace, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the <OpenInNewLink url={POWER_UP}>Power-Ups</OpenInNewLink> page in Trello and
                                click <strong>New</strong>.
                            </li>
                            <li>Copy the value below into the form.</li>
                            <li>Fill the rest of the form (Iframe connector URL is not necessary).</li>
                            <li>
                                Click <strong>Create</strong>.
                            </li>

                            <ReadOnlyTextField label="New Power-Up or Integration" value="ScriptRunner Connect" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button onClick={() => props.setStage(TrelloWizardStage.INPUT_API_KEY)}>Next</Button>
                    </DialogActions>
                </>
            );
        case TrelloWizardStage.INPUT_API_KEY:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>If you don't have an API Key, generate one in the Power-Up API Key section.</li>
                            <li>
                                Copy the <strong>API Key</strong> into the form below. <br />
                            </li>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="API Key"
                            value={apiKey}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setApiKey(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TrelloWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!apiKey}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TrelloWizardStage.INPUT_TOKEN);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case TrelloWizardStage.INPUT_TOKEN:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Generate a <OpenInNewLink url={TOKEN_URL}>Token</OpenInNewLink>
                            </li>
                            <li>
                                Copy the <strong>Token</strong> into the form below. <br />
                            </li>
                        </DialogContentText>
                        <ConnectionModalSecretTextField
                            label="Token"
                            value={token}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setToken(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TrelloWizardStage.INPUT_API_KEY);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!apiKey || !token}
                            onClick={() =>
                                props.onSave({
                                    apiKey,
                                    token,
                                })
                            }
                        >
                            Done
                        </Button>
                    </DialogActions>
                </>
            );
    }
};

export const TrelloConnectionAppConfigureDialog: React.FC<TrelloConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<TrelloIcon />} />
            {props.errors && error}
            <TrelloWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
