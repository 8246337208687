import { useObservableState } from 'observable-hooks';
import { ZenDeskConnectionAppConfigureDialog } from '../../../components/connections/zendesk/ZenDeskAppConfigureDialog';
import { configTopic$ } from '../../../store/config';
import {
    zenDeskConnectionSaving$,
    zenDeskManageConnectionDetails$,
    zenDeskAppSetupDialogErrors$,
    zenDeskAppSetupDialogOpen$,
    zenDeskAppSetupCloseDialogAction$,
    zenDeskAppSetupAuthorizeAction$,
    zenDeskAppSetupDialogStage$,
} from '../../../store/connection/zendesk/setup-connection';

export const ZenDeskConfigureDialog: React.FC = () => {
    const open = useObservableState(zenDeskAppSetupDialogOpen$);
    const saving = useObservableState(zenDeskConnectionSaving$);
    const details = useObservableState(zenDeskManageConnectionDetails$);
    const errors = useObservableState(zenDeskAppSetupDialogErrors$);
    const currentStage = useObservableState(zenDeskAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.zenDesk?.baseUrl;

    return (
        <ZenDeskConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                zenDeskAppSetupCloseDialogAction$.next();
                zenDeskAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => zenDeskAppSetupDialogStage$.next(stage)}
            clientInfo={{
                instanceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => zenDeskAppSetupDialogErrors$.next(error)}
            clearErrors={() => zenDeskAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => zenDeskAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
        />
    );
};
