import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Alert } from '../../common/alerts/Alert';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import Link from '@mui/material/Link';
interface ZenDeskEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    zendeskUrl?: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
}

export const ZenDeskEventListenerSetupDialog: React.FC<ZenDeskEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    zendeskUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
}) => {
    const appName = APP.ZENDESK.NAME;

    const canSave = !loading && !saving;

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyDown({ event, enterCondition: canSave, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {appName}
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        {workspaceLocked && (
                            <Alert
                                severity="warning"
                                alertTitle="Warning"
                                text="The workspace is currently locked and the token field cannot be edited"
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        {errors && <Alert severity="error" alertTitle="Error" text={errors} sx={{ marginBottom: 2 }} />}
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup an outgoing webhook in your {appName} project in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {zendeskUrl && (
                                    <li>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={zendeskUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!zendeskUrl && <li>Open https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.</li>}
                                <li>
                                    In <strong>Admin Centre</strong>, click <strong>Apps and integrations</strong> in
                                    the sidebar, then select <strong>Webhooks</strong>.
                                </li>
                                <li>
                                    Click the <strong>Create webhook</strong> button on the top right of the page.
                                </li>
                                <li>Subscribe to one or more Zendesk webhook events.</li>
                                <li>
                                    Click the <strong>Next</strong> button on the bottom right of the page.
                                </li>
                                <li>Enter a Name for the webhook.</li>
                                <li>
                                    Paste the URL from this page into the <strong>Endpoint URL</strong> field.
                                </li>
                                <li>
                                    Click on <strong>Create webhook</strong>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
