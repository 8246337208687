import { useObservableState } from 'observable-hooks';
import { useNavigate, useSearch } from 'react-location';
import {
    InvocationsReportPage,
    InvocationsReportFilters,
} from '../../components/report/invocations/InvocationsReportPage';
import {
    invocationsDataForReport$,
    invocationsReportError$,
    invocationsReportLoading$,
    queryInvocationsForReportAction$,
    searchInvocationsForReportAction$,
} from '../../store/report/invocations';

export const InvocationsReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const search = useSearch();
    const invocationsData = useObservableState(invocationsDataForReport$);
    const isLoading = useObservableState(invocationsReportLoading$);
    const errors = useObservableState(invocationsReportError$);

    return (
        <InvocationsReportPage
            invocations={invocationsData.invocations}
            nextToken={invocationsData.nextToken}
            isLoading={isLoading}
            errors={errors}
            filters={
                {
                    invocationId: search.invocationId,
                    invocationIdComparator: search.invocationIdComparator,
                    workspace: search.workspace,
                    workspaceComparator: search.workspaceComparator,
                    workspaceOwner: search.workspaceOwner,
                    workspaceOwnerComparator: search.workspaceOwnerComparator,
                    environment: search.environment,
                    environmentComparator: search.environmentComparator,
                    script: search.script,
                    scriptComparator: search.scriptComparator,
                    executionStatuses: search.executionStatuses,
                    triggerTypes: search.triggerTypes,
                    duration: search.duration,
                    durationComparator: search.durationComparator,
                    logCount: search.logCount,
                    logCountComparator: search.logCountComparator,
                    httpLogCount: search.httpLogCount,
                    httpLogCountComparator: search.httpLogCountComparator,
                    from: search.from,
                    to: search.to,
                    orderByField: search.orderByField,
                    orderByDirection: search.orderByDirection,
                } as InvocationsReportFilters
            }
            onQueryInvocations={(request) => queryInvocationsForReportAction$.next(request)}
            onSearchInvocations={(request) => searchInvocationsForReportAction$.next(request)}
            onSearch={(request) => {
                navigate({
                    search: request,
                });
            }}
        />
    );
};
