import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../dialog/DialogComponents';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { WorkspacePackages } from '../../data/package-manager';

export interface PackageUpgrade {
    documentationLink: string;
    installedVersion: string;
    latestVersion?: string;
    majorUpdate?: boolean;
    name: string;
    npmLink: string;
    type: string;
}

interface PackageUpgradeEvent {
    packages: WorkspacePackages;
    changelogClickCount: number;
}

interface PackageUpgradeDialogProps {
    deployedEnvironmentMode?: boolean;
    errors?: string;
    loading?: boolean;
    open?: boolean;
    packages: PackageUpgrade[];
    saving?: boolean;
    loadingTypeDeclarations?: boolean;
    loadingTypesMessage?: string;
    onApplyChanges(event: PackageUpgradeEvent): void;
    onCancel(changelogClickCount: number): void;
}

type SortBy = 'name' | 'type';
type SortOrder = 'asc' | 'desc';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        flexBasis: 900,
        maxWidth: 900,
        padding: theme.spacing(2),
    },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    marginBottom: theme.spacing(2),
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '&:last-of-type': {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
    '& .MuiTypography-root': {
        fontSize: theme.typography.subtitle2.fontSize,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 240,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.checkbox': {
        padding: theme.spacing(1, 0, 1, 1),
    },
    '&.icon': {
        padding: theme.spacing(2, 1),
        '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
        },
    },
    '&:last-of-type': {
        paddingRight: theme.spacing(4),
    },
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
    '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
    },
}));

export const PackageUpgradeDialog: React.FC<PackageUpgradeDialogProps> = ({
    deployedEnvironmentMode = false,
    errors,
    open = false,
    packages,
    saving = false,
    loadingTypeDeclarations = false,
    onApplyChanges,
    onCancel,
    loadingTypesMessage,
}) => {
    const [selectedPackages, setSelectedPackages] = useState<WorkspacePackages>([]);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [sortOrder, setSortOrder] = useState<{ name: SortOrder; type: SortOrder }>({ name: 'asc', type: 'asc' });
    const [changelogClicks, setChangelogClicks] = useState<string[]>([]);

    useEffect(() => {
        setSelectedPackages([]);
    }, [packages]);

    const handleSelectPackage = (pkg: WorkspacePackages[number]): void => {
        if (selectedPackages.some((sPkg) => sPkg.name === pkg.name)) {
            setSelectedPackages(selectedPackages.filter((sp) => sp.name !== pkg.name));
        } else {
            setSelectedPackages([...selectedPackages, { name: pkg.name, type: pkg.type, version: pkg.version }]);
        }
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setSelectedPackages(
                packages.map((p) => ({ name: p.name, type: p.type, version: p.latestVersion ?? p.installedVersion }))
            );
            return;
        }
        setSelectedPackages([]);
    };

    const handleSort = (selectedSortBy: SortBy): void => {
        if (selectedSortBy === sortBy) {
            setSortOrder({ ...sortOrder, [selectedSortBy]: sortOrder[selectedSortBy] === 'asc' ? 'desc' : 'asc' });
        } else {
            setSortBy(sortBy === 'name' ? 'type' : 'name');
        }
    };

    const handleClickChangelog = (event: React.MouseEvent<HTMLSpanElement>, link: string): void => {
        event.stopPropagation();
        if (!changelogClicks.includes(link)) {
            setChangelogClicks([...changelogClicks, link]);
        }
    };

    const handleSave = (): void => {
        onApplyChanges({
            packages: selectedPackages,
            changelogClickCount: changelogClicks.length,
        });
    };

    const majorUpdates = packages.filter((p) => p.majorUpdate);
    const mainAlertTitle = `Outdated Package${packages.length !== 1 ? 's' : ''} Detected`;
    const warningAlertTitle = `Major Package Update${majorUpdates.length !== 1 ? 's' : ''}`;
    const saveButtonText = `Apply Changes ${selectedPackages.length > 0 ? '(' + selectedPackages.length + ')' : ''}`;
    const allOptionsSelected = selectedPackages.length === packages.length;
    const canSave = !saving && selectedPackages.length > 0 && !loadingTypeDeclarations;

    const headCells = [
        {
            id: 'name',
            label: 'Name',
            sortable: true,
        },
        {
            id: 'type',
            label: 'Type',
            sortable: true,
        },
        {
            id: 'installed-version',
            label: 'Installed Version',
        },
        {
            id: 'latest-version',
            label: 'Latest Version',
        },
        {
            id: 'major-update',
        },
        {
            id: 'documentation',
            label: 'Documentation',
        },
    ];

    const sortedPackages = packages.sort((a, b) => {
        if (sortOrder[sortBy] === 'asc') {
            if (a[sortBy].toLocaleLowerCase() < b[sortBy].toLocaleLowerCase()) {
                return -1;
            }
            if (a[sortBy].toLocaleLowerCase() > b[sortBy].toLocaleLowerCase()) {
                return 1;
            }
            return 0;
        } else {
            if (a[sortBy].toLocaleLowerCase() > b[sortBy].toLocaleLowerCase()) {
                return -1;
            }
            if (a[sortBy].toLocaleLowerCase() < b[sortBy].toLocaleLowerCase()) {
                return 1;
            }
            return 0;
        }
    });

    const tableRows = sortedPackages.map((p) => {
        const isSelected = selectedPackages.some((sp) => sp.name === p.name);

        return (
            <StyledTableRow
                hover
                onClick={() =>
                    handleSelectPackage({ name: p.name, type: p.type, version: p.latestVersion ?? p.installedVersion })
                }
                role="checkbox"
                key={p.name}
                selected={isSelected}
            >
                <StyledTableCell className="checkbox">
                    <Checkbox checked={isSelected} aria-label={isSelected ? 'Unselect package' : 'Select package'} />
                </StyledTableCell>
                <StyledTableCell>
                    <Link href={p.npmLink} target="_blank" onClick={(e) => e.stopPropagation()}>
                        {p.name}
                    </Link>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography>{p.type}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography>{p.installedVersion}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography>{p.latestVersion}</Typography>
                </StyledTableCell>
                <StyledTableCell className="icon">
                    {p.majorUpdate && (
                        <Tooltip title="Major version upgrade, will most likely introduce breaking changes.">
                            <WarningAmberIcon sx={{ color: 'warning.light' }} />
                        </Tooltip>
                    )}
                </StyledTableCell>
                <StyledTableCell>
                    <Link
                        noWrap
                        href={p.documentationLink}
                        target="_blank"
                        onClick={(e) => handleClickChangelog(e, p.documentationLink)}
                    >
                        View Changelog
                    </Link>
                </StyledTableCell>
            </StyledTableRow>
        );
    });

    return (
        <StyledDialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave,
                    enterFn: handleSave,
                    escFn: () => onCancel(changelogClicks.length),
                })
            }
        >
            <DialogTitleMain title="Package Updates Available" />
            <DialogAlert
                alertTitle={mainAlertTitle}
                text="Ensure software security, stability and performance by regularly updating packages."
                severity="info"
            />
            {loadingTypeDeclarations && (
                <DialogAlert
                    alertTitle={loadingTypesMessage}
                    text="Package updates can only be applied when the editor has finished loading types."
                    severity="info"
                />
            )}
            {deployedEnvironmentMode && (
                <DialogAlert
                    alertTitle="Warning"
                    text="Package updates will only be applied for the HEAD version, you will need to release a new version into the current environment to apply package updates."
                    severity="warning"
                />
            )}
            {errors && <DialogAlert alertTitle="Error" text={errors} severity="error" />}
            <StyledTableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableHeadRow>
                            <StyledTableCell className="checkbox">
                                <Checkbox
                                    aria-label={allOptionsSelected ? 'Unselect all' : 'Select all'}
                                    indeterminate={selectedPackages.length > 0 && !allOptionsSelected}
                                    checked={allOptionsSelected}
                                    onChange={handleSelectAll}
                                />
                            </StyledTableCell>
                            {headCells.map((hc) => (
                                <StyledTableCell
                                    key={hc.id}
                                    onClick={() => (hc.sortable ? handleSort(hc.id as SortBy) : {})}
                                    sx={hc.sortable ? { cursor: 'pointer' } : null}
                                >
                                    {hc.sortable ? (
                                        <StyledTableSortLabel
                                            active={sortBy === hc.id}
                                            direction={sortOrder[hc.id as SortBy]}
                                        >
                                            <Typography> {hc.label}</Typography>
                                        </StyledTableSortLabel>
                                    ) : (
                                        <Typography>{hc.label}</Typography>
                                    )}
                                </StyledTableCell>
                            ))}
                        </StyledTableHeadRow>
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </StyledTableContainer>
            {majorUpdates.length > 0 && (
                <DialogAlert
                    alertTitle={warningAlertTitle}
                    text="Please carefully review each changelog for information regarding potential breaking changes, as they are inevitable."
                    severity="warning"
                />
            )}
            <DialogActions sx={{ margin: 0 }}>
                <Button variant="outlined" onClick={() => onCancel(changelogClicks.length)}>
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={!canSave} busy={saving}>
                    {saveButtonText}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
