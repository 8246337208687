import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import { Button } from '../common/buttons/Button';
import { PageGridContainer } from '../layout';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import {
    OnboardingAppItem,
    OnboardingGoalCard,
    OnboardingWizardStage,
    OnboardingWizardSteps,
} from './OnboardingComponents';

export interface OnboardingWizardProps {
    apps: {
        uid: string;
        name: string;
    }[];
    loading?: boolean;
    open?: boolean;
    roles: {
        uid: string;
        label: string;
        description?: string;
    }[];
    saving?: boolean;
    scriptingFamiliarityOptions: {
        uid: string;
        label: string;
        description?: string;
    }[];
    userRoleUid?: string;
    userCustomRole?: string;
    userSelectedApps?: string[];
    userSelectedGoals?: string[];
    onFinish(event: OnboardingResponses): void;
}

export interface OnboardingResponses {
    selectedRole: string;
    selectedScriptingFamiliarity: string;
    selectedApps: string[];
    selectedCustomRole: string;
    personalization: {
        integrations: boolean;
        sync: boolean;
        migrations: boolean;
    };
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 310,
    paddingTop: theme.spacing(3),
    position: 'relative',
    maxWidth: 900,
    minWidth: 600,
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    '& .MuiSvgIcon-root': {
        height: 28,
        marginRight: theme.spacing(1),
        width: 28,
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(3),
    width: '100%',
}));

const StyledOption = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    justifyContent: 'space-between',
    height: 56,
    padding: theme.spacing(0, 1.5),
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(2),
    },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    '&:before, &:after': {
        position: 'unset',
    },
}));

const StyledTextField = styled(TextField)(() => ({
    margin: 0,
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

const StyledAppContainer = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    maxHeight: 496,
    overflow: 'auto',
    padding: theme.spacing(3),
    width: '100%',
}));

const StyledGoalContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    width: '100%',
}));

const StyledFooter = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    width: '100%',
}));

const StyledFooterColumn = styled('div')(() => ({
    display: 'flex',
    '&.left': {
        justifyContent: 'flex-start',
        width: '30%',
    },
    '&.center': {
        justifyContent: 'center',
        width: '40%',
    },
    '&.right': {
        justifyContent: 'flex-end',
        width: '30%',
    },
}));

export const OnboardingWizard: React.FC<OnboardingWizardProps> = ({
    apps,
    loading = false,
    open = false,
    roles,
    saving = false,
    scriptingFamiliarityOptions,
    onFinish,
    userRoleUid,
    userSelectedApps,
    userSelectedGoals,
    userCustomRole,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [stage, setStage] = useState<OnboardingWizardStage>(OnboardingWizardStage.SELECT_PROFESSION);
    const [currentRole, setCurrentRole] = useState(userRoleUid ?? '');
    const [customRole, setCustomRole] = useState(userCustomRole ?? '');
    const [currentFamiliarity, setCurrentFamiliarity] = useState('');
    const [selectedApps, setSelectedApps] = useState<string[]>(userSelectedApps ?? []);
    const [selectedGoals, setSelectedGoals] = useState<string[]>(userSelectedGoals ?? []);

    useEffect(() => {
        if (currentRole) {
            setCustomRole('');
        }
    }, [currentRole]);

    const handleSelectApp = (uid: string): void => {
        if (selectedApps.includes(uid)) {
            setSelectedApps(selectedApps.filter((sa) => sa !== uid));
        } else {
            setSelectedApps([...selectedApps, uid]);
        }
    };

    const handleSelectGoal = (title: string): void => {
        if (selectedGoals.includes(title)) {
            setSelectedGoals(selectedGoals.filter((sg) => sg !== title));
        } else {
            setSelectedGoals([...selectedGoals, title]);
        }
    };

    const handleOpenApp = (): void => {
        const personalization = {
            integrations: selectedGoals.includes('Automation'),
            migrations: selectedGoals.includes('Migration'),
            sync: selectedGoals.includes('Synchronisation'),
        };
        onFinish({
            personalization,
            selectedApps,
            selectedRole: currentRole,
            selectedCustomRole: customRole,
            selectedScriptingFamiliarity: currentFamiliarity,
        });
    };

    const roleOptions = roles.map((role) => (
        <StyledOption onClick={() => setCurrentRole(role.uid)} key={role.uid}>
            <FormControlLabel
                value={role.uid}
                aria-label={role.label}
                control={<Radio checked={currentRole === role.uid} />}
                label={role.label}
            />
            <Typography color="text.secondary">{role.description}</Typography>
        </StyledOption>
    ));

    const codingLevelOptions = scriptingFamiliarityOptions.map((option) => (
        <StyledOption onClick={() => setCurrentFamiliarity(option.uid)} key={option.uid}>
            <FormControlLabel
                value={option.uid}
                aria-label={option.label}
                control={<Radio checked={currentFamiliarity === option.uid} />}
                label={option.label}
            />
            <Typography color="text.secondary">{option.description}</Typography>
        </StyledOption>
    ));

    const appItems = apps
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((app) => (
            <OnboardingAppItem
                key={app.uid}
                name={app.name}
                selected={selectedApps.includes(app.uid)}
                uid={app.uid}
                onSelect={handleSelectApp}
            />
        ));

    const goals = [
        {
            icon: <PrecisionManufacturingOutlinedIcon sx={{ color: 'primary.main' }} />,
            title: 'Automation',
        },
        {
            icon: <MoveDownOutlinedIcon sx={{ color: 'secondary.main' }} />,
            title: 'Migration',
        },
        {
            icon: <CloudSyncOutlinedIcon sx={{ color: 'info.main' }} />,
            title: 'Synchronisation',
        },
    ];

    const goalCards = goals.map((goal) => (
        <OnboardingGoalCard
            key={goal.title}
            icon={goal.icon}
            selected={selectedGoals.includes(goal.title)}
            title={goal.title}
            onSelect={handleSelectGoal}
        />
    ));

    const isRoleSelected = !!currentRole || !!customRole;
    const appsNextButtonText = `Next ${selectedApps.length > 0 ? '(' + selectedApps.length + ')' : ''}`;
    const goalsNextButtonText = `Next ${selectedGoals.length > 0 ? '(' + selectedGoals.length + ')' : ''}`;

    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case OnboardingWizardStage.SELECT_PROFESSION:
                return (
                    <StyledPaper
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterCondition: isRoleSelected,
                                enterFn: () => setStage(OnboardingWizardStage.SELECT_CODING_LEVEL),
                            })
                        }
                    >
                        <Typography variant="h5" component="h2" mb={1}>
                            What is your profession?
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="normal" mb={3} color="text.secondary">
                            Personalise your experience by providing details about yourself
                        </Typography>
                        <StyledContent>
                            <Typography variant="subtitle1" mb={2}>
                                Select one of the following options
                            </Typography>
                            <RadioGroup
                                onChange={(e) => {
                                    setCurrentRole(e.target.value);
                                }}
                            >
                                {roleOptions}
                            </RadioGroup>
                            <StyledDivider>or</StyledDivider>
                            <StyledTextField
                                value={customRole}
                                label="Custom role"
                                placeholder="Enter role"
                                onChange={(e) => setCustomRole(e.target.value)}
                                onFocus={() => setCurrentRole('')}
                            />
                        </StyledContent>
                        <StyledFooter>
                            <StyledFooterColumn className="left" />
                            <StyledFooterColumn className="center">
                                <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                            </StyledFooterColumn>
                            <StyledFooterColumn className="right">
                                <Button
                                    disabled={!isRoleSelected}
                                    onClick={() => setStage(OnboardingWizardStage.SELECT_CODING_LEVEL)}
                                >
                                    Next
                                </Button>
                            </StyledFooterColumn>
                        </StyledFooter>
                    </StyledPaper>
                );
            case OnboardingWizardStage.SELECT_CODING_LEVEL:
                return (
                    <StyledPaper
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterCondition: !!currentFamiliarity,
                                enterFn: () => setStage(OnboardingWizardStage.SELECT_APPS),
                            })
                        }
                    >
                        <Typography variant="h5" component="h2" mb={1}>
                            How well do you know JavaScript?
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="normal" mb={3} color="text.secondary">
                            SR Connect uses JavaScript to build integration business logic
                        </Typography>
                        <StyledContent>
                            <Typography variant="subtitle1" mb={2}>
                                Select one of the following options
                            </Typography>
                            <RadioGroup onChange={(e) => setCurrentFamiliarity(e.target.value)}>
                                {codingLevelOptions}
                            </RadioGroup>
                        </StyledContent>
                        <StyledFooter>
                            <StyledFooterColumn className="left">
                                <Button
                                    variant="text"
                                    onClick={() => setStage(OnboardingWizardStage.SELECT_PROFESSION)}
                                >
                                    Back
                                </Button>
                            </StyledFooterColumn>
                            <StyledFooterColumn className="center">
                                <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                            </StyledFooterColumn>
                            <StyledFooterColumn className="right">
                                <Button
                                    disabled={!currentFamiliarity}
                                    onClick={() => setStage(OnboardingWizardStage.SELECT_APPS)}
                                >
                                    Next
                                </Button>
                            </StyledFooterColumn>
                        </StyledFooter>
                    </StyledPaper>
                );

            case OnboardingWizardStage.SELECT_APPS:
                return (
                    <StyledPaper
                        onKeyDown={(event) =>
                            handleKeyDown({ event, enterFn: () => setStage(OnboardingWizardStage.SELECT_GOALS) })
                        }
                    >
                        <Typography variant="h5" component="h2" mb={1}>
                            What apps do you use?
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="normal" mb={3} color="text.secondary">
                            Personalise your experience by providing details about yourself
                        </Typography>
                        <StyledContent>
                            <Typography variant="subtitle1" mb={2}>
                                Select all that apply
                            </Typography>
                            <StyledAppContainer>
                                <PageGridContainer sx={{ padding: 0, columnGap: 1, rowGap: 2 }}>
                                    {appItems}
                                </PageGridContainer>
                            </StyledAppContainer>
                        </StyledContent>
                        <StyledFooter>
                            <StyledFooterColumn className="left">
                                <Button
                                    variant="text"
                                    onClick={() => setStage(OnboardingWizardStage.SELECT_CODING_LEVEL)}
                                >
                                    Back
                                </Button>
                            </StyledFooterColumn>
                            <StyledFooterColumn className="center">
                                <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                            </StyledFooterColumn>
                            <StyledFooterColumn className="right">
                                <Button onClick={() => setStage(OnboardingWizardStage.SELECT_GOALS)}>
                                    {appsNextButtonText}
                                </Button>
                            </StyledFooterColumn>
                        </StyledFooter>
                    </StyledPaper>
                );
            case OnboardingWizardStage.SELECT_GOALS:
                return (
                    <StyledPaper
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterCondition: !saving,
                                enterFn: handleOpenApp,
                                escCondition: !saving,
                                escFn: handleOpenApp,
                            })
                        }
                    >
                        <Typography variant="h5" component="h2" mb={1}>
                            What is your main goal?
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="normal" mb={3} color="text.secondary">
                            Personalise your experience by providing details about yourself
                        </Typography>
                        <StyledContent>
                            <Typography variant="subtitle1" mb={2}>
                                Select all that apply
                            </Typography>
                            <StyledGoalContainer>{goalCards}</StyledGoalContainer>
                        </StyledContent>
                        <StyledFooter>
                            <StyledFooterColumn className="left">
                                <Button variant="text" onClick={() => setStage(OnboardingWizardStage.SELECT_APPS)}>
                                    Back
                                </Button>
                            </StyledFooterColumn>
                            <StyledFooterColumn className="center">
                                <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                            </StyledFooterColumn>
                            <StyledFooterColumn className="right">
                                <Button busy={saving} disabled={saving} onClick={handleOpenApp}>
                                    {goalsNextButtonText}
                                </Button>
                            </StyledFooterColumn>
                        </StyledFooter>
                    </StyledPaper>
                );
        }
    };

    return (
        <StyledDialog open={open} fullScreen>
            <StyledTitleContainer>
                <SRConnectIcon />
                <DialogTitle fontWeight="normal">ScriptRunner Connect</DialogTitle>
            </StyledTitleContainer>
            {loading ? (
                <StyledPaper>
                    <AbsoluteCenteredLoadingSpinner />
                </StyledPaper>
            ) : (
                getStageContent()
            )}
        </StyledDialog>
    );
};
