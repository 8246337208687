import { BehaviorSubject, Subject, map, filter } from 'rxjs';
import {
    getInvocationsForReport,
    InvocationsReportRequest,
    InvocationsReportResponse,
} from '../../data/report/invocations';
import { InformativeError } from '../../utils/repository';
import { monitor } from '../monitor';

export const invocationsDataForReport$ = monitor(
    'invocationsForReport$',
    new BehaviorSubject<InvocationsReportResponse>({ invocations: [] })
);

export const invocationsReportLoading$ = monitor('invocationsReportLoading$', new BehaviorSubject(false));

export const invocationsReportError$ = monitor(
    'invocationsReportError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const queryInvocationsForReportAction$ = monitor(
    'queryInvocationsForReportAction$',
    new Subject<InvocationsReportRequest>()
);
export const searchInvocationsForReportAction$ = monitor(
    'searchInvocationsReportAction$',
    new Subject<InvocationsReportRequest>()
);
export const addInvocationsForReportAction$ = monitor(
    'addInvocationsForReportAction$',
    new Subject<InvocationsReportResponse>()
);

queryInvocationsForReportAction$
    .pipe(
        filter(({ nextToken }) => !!nextToken),
        map(async (request) => {
            invocationsReportError$.next(undefined);

            try {
                const invocationsData = await getInvocationsForReport(request);
                addInvocationsForReportAction$.next(invocationsData);
            } catch (e) {
                if (e instanceof InformativeError) {
                    invocationsReportError$.next(e.message);
                } else {
                    invocationsReportError$.next(
                        'Failed to query for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            invocationsReportLoading$.next(false);
        })
    )
    .subscribe();

searchInvocationsForReportAction$
    .pipe(
        filter(({ nextToken }) => !nextToken),
        map(async (request) => {
            invocationsReportLoading$.next(true);
            invocationsReportError$.next(undefined);

            try {
                const invocations = await getInvocationsForReport(request);
                invocationsDataForReport$.next(invocations);
            } catch (e) {
                if (e instanceof InformativeError) {
                    invocationsReportError$.next(e.message);
                } else {
                    invocationsReportError$.next(
                        'Failed to search for Invocations, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying invocations', e);
                }
            }
            invocationsReportLoading$.next(false);
        })
    )
    .subscribe();

addInvocationsForReportAction$
    .pipe(
        filter(({ invocations }) => invocations.length > 0),
        map(({ invocations, nextToken }) => {
            const currentInvocationsData = invocationsDataForReport$.value;
            return {
                invocations: [...currentInvocationsData.invocations, ...invocations],
                nextToken,
            };
        })
    )
    .subscribe((invocationsData) => invocationsDataForReport$.next(invocationsData));
