import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '../common/buttons/Button';
import {
    DialogBadgeContainerOverhauled,
    DialogContentBoxOverhauled,
    DialogContentOverhauled,
    DialogContentTextOverhauled,
    DialogOverhauled,
    DialogTitleLargeOverhauled,
} from '../dialog/DialogOverhaul';
import { MondayIcon } from '../icons/MondayIcon';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { useTheme } from '@mui/material';

interface MondayUserSuccessDialogProps {
    open?: boolean;
    onViewTemplates: () => void;
}

export const MondayUserSuccessDialog: React.FC<MondayUserSuccessDialogProps> = ({ open = false, onViewTemplates }) => {
    const theme = useTheme();

    return (
        <DialogOverhauled
            className="centered"
            open={open}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onViewTemplates, escFn: onViewTemplates })}
        >
            <DialogBadgeContainerOverhauled color={theme.palette.success.main} icon={<CheckIcon />} centered />
            <DialogTitleLargeOverhauled title="Congratulations!" />
            <DialogContentTextOverhauled sx={{ mb: 3 }}>
                Your connector is set up and ready to go
            </DialogContentTextOverhauled>
            <DialogContentOverhauled className="centered">
                <DialogContentBoxOverhauled className="centered" width={300}>
                    <MondayIcon />
                    <DialogContentTextOverhauled sx={{ mb: 2 }}>
                        Discover monday.com templates
                    </DialogContentTextOverhauled>
                    <Divider sx={{ mb: 2 }} />
                    <DialogContentTextOverhauled sx={{ mb: 2 }}>
                        Find ready-made solutions or create your own templates for all your project needs
                    </DialogContentTextOverhauled>
                    <Button onClick={onViewTemplates}>Browse all Templates</Button>
                </DialogContentBoxOverhauled>
            </DialogContentOverhauled>
        </DialogOverhauled>
    );
};
