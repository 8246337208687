import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from '@mui/system/styled';
import React, { useState } from 'react';
import { Button } from '../../common/buttons/Button';
import { AzureDevopsIcon } from '../../icons/AzureDevopsIcon';
import { ConnectionModalSecretTextField, ConnectionModalTextField } from '../../textfield/ConnectionModalTextField';
import {
    AzureDevopsWizardStage,
    AzureDevopsWizardSteps,
    AzureDevopsConnection,
    InstanceType,
} from './AzureDevopsWizardSteps';
import { OpenInNewLink } from '../../common/OpenInNewLink';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { DialogAlert, DialogTitleMain } from '../../dialog';

type StageType = React.FC<
    AzureDevopsConnection & {
        stage: AzureDevopsWizardStage;
        setStage: (stage: AzureDevopsWizardStage) => void;
        onChooseInstanceType: (type: InstanceType) => void;
    }
>;

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    '&:first-of-type': {
        marginTop: theme.spacing(2),
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const FULLY_MANAGED: InstanceType = 'FULLY-MANAGED';
const SELF_MANAGED: InstanceType = 'SELF-MANAGED';

const StageContent: StageType = (props) => {
    const [tenantId, setTenantId] = useState(props.clientInfo.tenantId);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);
    const [instanceType, setInstanceType] = useState<InstanceType>(
        props.clientInfo.tenantId ? SELF_MANAGED : FULLY_MANAGED
    );

    const handleChooseInstanceType = async (): Promise<void> => {
        props.setStage(instanceType === SELF_MANAGED ? AzureDevopsWizardStage.CREATE : AzureDevopsWizardStage.CONSENT);
        if (instanceType === FULLY_MANAGED) {
            setTenantId('');
            setClientId('');
            setClientSecret('');
        }
        props.onChooseInstanceType(instanceType);
    };

    const handleSave = (): void => {
        props.onSave({
            tenantId,
            clientId,
            clientSecret,
        });
    };

    switch (props.stage) {
        case AzureDevopsWizardStage.CHOOSEINSTANCETYPE:
            return (
                <>
                    <StyledFormControl>
                        <DialogContentText>
                            Choose the instance type that you want to use to connect to Azure Devops:
                        </DialogContentText>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={instanceType}
                            onChange={(e) => setInstanceType(e.target.value as InstanceType)}
                        >
                            <StyledFormControlLabel
                                value={FULLY_MANAGED}
                                control={<Radio />}
                                label={`Fully managed Azure application by ScriptRunner Connect`}
                            />
                            <StyledFormControlLabel
                                value={SELF_MANAGED}
                                control={<Radio />}
                                label="Self-managed Azure application"
                            />
                        </RadioGroup>
                    </StyledFormControl>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleChooseInstanceType}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.CONSENT:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`You can skip this step if an administrator from your organization has already granted admin consent to ScriptRunner Connect.`}
                    />
                    <DialogContent>
                        <DialogContentText>
                            To authorize our app, an administrator from your organization needs to grant consent to
                            ScriptRunner Connect first. Copy the link below and pass it to them. Once they have granted
                            consent, proceed to the next step.
                            <ReadOnlyTextField label="Admin Consent URL" value={props.adminConsentUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => props.setStage(AzureDevopsWizardStage.CHOOSEINSTANCETYPE)}
                        >
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AzureDevopsWizardStage.AUTHORIZE)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an application registered in Azure linked to to ScriptRunner Connect, skip the steps below and click next`}
                    />
                    <DialogAlert
                        severity="info"
                        text="To setup an application you need administrator privileges in your Azure instance."
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={'https://portal.azure.com/#home'}>Azure Portal</OpenInNewLink> page.
                            </li>
                            <li>
                                In the search bar, search for <strong>App registrations</strong>.
                            </li>
                            <li>
                                Click on <strong>New registration</strong>.
                            </li>
                            <li>Fill in the information below.</li>
                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField
                                label="Supported account types"
                                value={
                                    'Accounts in any organizational directory (Any Azure AD directory - Multitenant)'
                                }
                            />
                            <ReadOnlyTextField label="Redirect URI - Select a platform" value={'Web'} />
                            <ReadOnlyTextField label="Redirect URI" value={props.callbackUrl} />
                            <li>
                                Click on <strong>Register</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => props.setStage(AzureDevopsWizardStage.CHOOSEINSTANCETYPE)}
                        >
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AzureDevopsWizardStage.CONFIGURE)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.CONFIGURE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                The application overview page should open. If it doesn't, navigate to{' '}
                                <strong>App registrations</strong> again and select the application you just created.
                            </li>
                            <li>
                                Under the <strong>Essentials</strong> section, copy the{' '}
                                <strong>Application (client) ID</strong> and the <strong>Directory (tenant) ID</strong>{' '}
                                into the form below.
                            </li>
                            <li>
                                On the left hand side, expand <strong>Manage</strong> then click on{' '}
                                <strong>Authentication</strong> and scroll down to{' '}
                                <strong>Implicit grant and hybrid flows</strong>.
                            </li>
                            <li>
                                Scroll down to <strong>Implicit grant and hybrid flows</strong> and select the{' '}
                                <strong>Access tokens (used for implicit flows)</strong> checkbox.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Application (client) ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalTextField
                            label="Directory (tenant) ID"
                            value={tenantId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setTenantId(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(AzureDevopsWizardStage.CREATE)}>
                            Back
                        </Button>
                        <Button
                            disabled={!clientId || !tenantId}
                            onClick={() => props.setStage(AzureDevopsWizardStage.SECRET)}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.SECRET:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Under the <strong>Manage</strong> section in the left hand side, click on{' '}
                                <strong>Certificates & secrets</strong>.
                            </li>
                            <li>
                                Click on <strong>New client secret</strong>.
                            </li>
                            <li>
                                Give the secret a description and choose an expiry date. Note that after this date, all
                                users on this tenant will need to re-authorize their connection.
                            </li>
                            <li>
                                Copy the secret <strong>Value</strong>. This will be obfuscated after you navigate away
                                from this section so paste it into the form below.
                            </li>
                        </DialogContentText>

                        <ConnectionModalSecretTextField
                            label="Client Secret Value"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AzureDevopsWizardStage.CONFIGURE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret || !tenantId}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AzureDevopsWizardStage.SCOPES);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.SCOPES:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Under the <strong>Manage</strong> section in the left hand side, click on{' '}
                                <strong>API permissions</strong>.
                            </li>
                            <li>
                                Click on <strong>Add a permission</strong> then select <strong>Azure Devops</strong>.
                            </li>
                            <li>
                                Click on <strong>Delegated Permissions</strong> then select all of the permissions
                                necessary for you application.
                            </li>
                            <li>
                                Once all permissions have been selected, click on <strong>Add permissions</strong>.
                            </li>
                            <li>
                                {' '}
                                Some permissions will require admin consent be sure to click on{' '}
                                <strong>Grant admin consent</strong> button just above the API/Permissions list once
                                you're done.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AzureDevopsWizardStage.SECRET);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret || !tenantId}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AzureDevopsWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Azure Devops you need to authorize our app to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(
                                    instanceType === FULLY_MANAGED
                                        ? AzureDevopsWizardStage.CONSENT
                                        : AzureDevopsWizardStage.SECRET
                                );
                            }}
                        >
                            Back
                        </Button>
                        <Button busy={props.saving} onClick={handleSave}>
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case AzureDevopsWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const AzureDevopsAppConfigureDialog: React.FC<AzureDevopsConnection> = (props) => {
    const [instanceType, setInstanceType] = useState<InstanceType>(FULLY_MANAGED);
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;

    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<AzureDevopsIcon />} />
            {props.errors && error}
            <AzureDevopsWizardSteps stage={props.currentStage} instanceType={instanceType} />
            <StageContent
                {...props}
                stage={props.currentStage}
                setStage={props.setStage}
                onChooseInstanceType={(type) => setInstanceType(type)}
            />
        </Dialog>
    );
};
