import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { Button } from '../common/buttons/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Editor from '@monaco-editor/react';
import { StyledWrapper } from './event-listener-payloads';
import { CenteredLoadingSpinner } from '../loading/CenteredLoadingSpinner';
import { Banner } from '../common/alerts/Banner';

export interface ReplayInvocationDialogProps {
    open?: boolean;
    onTrigger(payload: string): void;
    workspaceLocked?: boolean;
    content: string;
    onClose(): void;
    loading?: boolean;
    onAssumeWorkspaceEditControl(): void;
}

export const ReplayInvocationDialog: React.FC<ReplayInvocationDialogProps> = ({
    open = false,
    onTrigger,
    workspaceLocked = false,
    content,
    onClose,
    loading = false,
    onAssumeWorkspaceEditControl,
}) => {
    const [currentContent, setCurrentContent] = useState(content);
    const darkTheme = useTheme().palette.mode === 'dark';

    useEffect(() => setCurrentContent(content), [content]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            sx={{
                '& .MuiDialogContent-root': {
                    padding: 0,
                },
            }}
        >
            <DialogContent>
                <Typography variant="h6" paddingBottom={2}>
                    Invocation payload review
                </Typography>
                <StyledWrapper sx={{ height: '500px' }}>
                    {!workspaceLocked && (
                        <Box pb={2}>
                            <Banner
                                severity="info"
                                text="Review and optionally modify the event payload you are about to re-trigger"
                            />
                        </Box>
                    )}
                    {workspaceLocked && (
                        <Box pb={2}>
                            <Banner
                                severity="info"
                                alertTitle="Workspace already in use"
                                text="Looks like you have opened the same workspace from a different tab or you are sharing an account and someone else is working on this workspace right now. You can assume edit control over the workspace immediately by clicking on 'Assume Edit Control' button."
                            />
                        </Box>
                    )}
                    {content === '{}' && (
                        <Box pb={2}>
                            <Banner
                                severity="warning"
                                alertTitle="Empty payload"
                                text="There's no payload for this invocation. Please input a payload manually."
                            />
                        </Box>
                    )}
                    {loading ? (
                        <CenteredLoadingSpinner />
                    ) : (
                        <Editor
                            path="replayInvocationPayload"
                            theme={darkTheme ? 'vs-dark' : 'vs'}
                            options={{
                                minimap: { enabled: false },
                                readOnly: workspaceLocked,
                            }}
                            language="json"
                            defaultValue=""
                            value={currentContent}
                            onChange={(editorContent) => setCurrentContent(editorContent ?? '')}
                        />
                    )}
                </StyledWrapper>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                {workspaceLocked ? (
                    <Button onClick={onAssumeWorkspaceEditControl}>Assume Edit Control</Button>
                ) : (
                    <Button onClick={() => onTrigger(currentContent)}>Trigger</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
