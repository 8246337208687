import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { ColumnDropdownProps, ConsoleHeader, LogLevel } from './ConsoleHeader';
import { Log, LogSeverity } from './Log';

export interface ConsoleProps {
    logs: LogItemProps[];
    filterByEnvironment: boolean;
    onClear(): void;
    onExpand(isFullScreen: boolean): void;
    isConsoleFullScreen: boolean;
    onChangeLiveFeedbackSwitch?(checked: boolean): void;
    onChangeFilterByEnvironment?(checked: boolean): void;
    onSearch(query: string): void;
    searchValue: string;
    logLevels: LogLevel[];
    columns: ColumnDropdownProps[];
    onLogLevelChecked(logs: LogLevel[]): void;
    onColumnsChecked(columns: ColumnDropdownProps[]): void;
}

export interface LogItemProps {
    time: number;
    level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
    message?: JSX.Element;
    source?: string;
    environmentName?: string;
    invocationId?: string;
    plainText?: string;
}

const StyledFlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
});

const StyledList = styled(List)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
    alignItems: 'center',
    width: '100%',
}));

let tooFast: boolean;
let scrolling: NodeJS.Timeout;

export const Console: React.FC<ConsoleProps> = ({
    logs,
    filterByEnvironment,
    onClear,
    onExpand,
    isConsoleFullScreen,
    onChangeLiveFeedbackSwitch,
    onChangeFilterByEnvironment,
    onSearch,
    searchValue,
    logLevels = [],
    columns = [],
    onLogLevelChecked,
    onColumnsChecked,
}) => {
    const listRef = useRef<HTMLUListElement>(null);
    const lastElementRef = useRef<HTMLLIElement>(null);
    const afterLastRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        clearTimeout(scrolling);
        const isBottom =
            listRef.current &&
            lastElementRef.current &&
            listRef.current.scrollHeight -
                listRef.current.scrollTop -
                listRef.current.clientHeight -
                lastElementRef.current.clientHeight <
                (tooFast ? 300 : 100);
        if (lastElementRef.current && listRef.current && afterLastRef.current && isBottom) {
            listRef.current.scrollTo({
                top: afterLastRef.current.offsetTop,
                behavior: tooFast ? 'auto' : 'smooth',
            });
        }
        tooFast = true;
        scrolling = setTimeout(() => {
            tooFast = false;
        }, 500);
    }, [logs.length]);

    const logLevelsChecked = logLevels.filter((logLevel) => logLevel.checked === true);
    const filteredLogs = logs.filter((log) => {
        return (
            logLevelsChecked.length === 0 ||
            !!logLevelsChecked.find((level) => level.level.toLowerCase() === log.level.toLowerCase())
        );
    });

    return (
        <StyledFlexContainer>
            <ConsoleHeader
                isFullScreen={isConsoleFullScreen}
                onExpand={onExpand}
                onClearLog={onClear}
                onLiveFeedback={onChangeLiveFeedbackSwitch}
                onFilterByEnvironment={onChangeFilterByEnvironment}
                filterByEnvironment={filterByEnvironment}
                logLevels={logLevels}
                onLogLevelChecked={onLogLevelChecked}
                columns={columns}
                onColumnsChecked={onColumnsChecked}
                logs={filteredLogs.length}
                onSearch={onSearch}
                searchValue={searchValue}
            />
            <StyledList ref={listRef}>
                {filteredLogs.map((logItem, i) => (
                    <StyledListItem ref={lastElementRef} key={i}>
                        <Log
                            severity={
                                logItem.level === 'WARN' ? 'warning' : (logItem.level.toLowerCase() as LogSeverity)
                            }
                            columns={columns}
                            logTitle={logItem}
                            text={logItem?.message}
                            plainText={logItem?.plainText}
                        />
                    </StyledListItem>
                ))}
                {filteredLogs.length > 0 && <div ref={afterLastRef} />}
            </StyledList>
        </StyledFlexContainer>
    );
};
